import React from "react";
import { Spinner } from "react-bootstrap";

const CustomSpinner = (props) => {
  return (
    <Spinner
      animation="border"
      size="sm"
      className={`icon ${props.className}`}
      variant="success"
    />
  );
};

export default CustomSpinner;
