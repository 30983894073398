import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Cpt, { CptLeve1 } from "./Cpt";
import CardWrap from "./Cpt/CardWrap";
import Accordion from "react-bootstrap/Accordion";
import BatchUpload from "../BatchUpload";
import { ArrowReturnLeft } from "react-bootstrap-icons";
import CircleSlash from "assets/CircleSlash";

import { useCptsWithDlc } from "screens/Editor/hooks";

import {
  resetLayerFromCanvas,
  setCurrCpt,
  setPid,
} from "screens/Editor/uiSlice";

import emitter from "canvasMitt";
import { useParams } from "react-router-dom";
import { useRole } from "hooks/auth";

const DefalutCpts = () => {
  const { tid: dlc } = useParams("tid");
  const currPid = useSelector((state) => state.ui.editor[dlc].currPid);
  const { cpts } = useCptsWithDlc(dlc);

  return (
    <Accordion.Item eventKey={dlc} className="border-0">
      <Accordion.Body>
        <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 gy-2 row-cols-xxl-5 ">
          {currPid ? (
            <Expand cpts={cpts} pid={currPid} />
          ) : (
            <Collapse cpts={cpts} />
          )}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const Expand = ({ cpts, pid }) => {
  const { tid: dlc } = useParams("tid");
  return (
    <>
      <CollapseStyleButton />
      {cpts?.map((cpt) => cpt.id === pid && <Cpt key={cpt.id} cpt={cpt} />)}
      {cpts?.map((cpt) => cpt.pid === pid && <Cpt key={cpt.id} cpt={cpt} />)}
      <BatchUpload dlc={dlc} />
    </>
  );
};

const Collapse = ({ cpts }) => {
  const isView = useRole('viewer')
  const { tid: dlc } = useParams("tid");
  return (
    <>
      <CleanButton />
      {cpts?.map((cpt) => !cpt.pid && <CptLeve1 key={cpt.id} cpt={cpt} />)}
      {!isView && <BatchUpload dlc={dlc} />}
    </>
  );
};

const CleanButton = () => {
  const { tid } = useParams();
  const lid = useSelector((state) => state.ui.editor[tid].lid);

  const dispatch = useDispatch();

  const handleReset = () => {
    dispatch(resetLayerFromCanvas({lid,tid}));
    emitter.emit(`resetLayerFromCanvas`, lid);
  };
  return (
    <CardWrap>
      <div className="m-auto clean-layer-btn d-flex justify-content-center align-items-center">
        <CircleSlash onClick={handleReset} />
      </div>
    </CardWrap>
  );
};

const CollapseStyleButton = () => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(setPid({currPid: null, tid}));
    dispatch(setCurrCpt({currCpt: null, tid}));
  };

  return (
    <CardWrap>
      <div className="m-auto clean-layer-btn d-flex justify-content-center align-items-center">
        <ArrowReturnLeft onClick={handleClose} />
      </div>
    </CardWrap>
  );
};

export default DefalutCpts;
