import React, { useState } from "react";
import {
  useGetToningsTemplateQuery,
  useUpdateToningTemplateMutation,
  useDeleteToningTemplateMutation,
  useAddToningTemplateMutation,
} from "api/neku/tonings";
import EmptyDatas from "ui/EmptyDatas";
import Fetching from "ui/Fetching";
import { Button, Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import Spinner from "components/Spinner";
import Confirm from "components/Confirm";
import { selectToning } from "screens/Editor/uiSlice";
import ColorPicker from "components/PickerColor";
import { useParams } from "react-router-dom";

const Tonings = () => {
  const { data, isLoading, refetch } = useGetToningsTemplateQuery();
  if (!data) return <EmptyDatas />;
  if (isLoading) return <Fetching />;
  return (
    <div>
      <div className="my-3">
        <Button onClick={refetch} className="me-3">
          Refetch
        </Button>
        <CreateButton />
      </div>
      <table className="table table-hover">
        <thead className="table-secondary">
          <tr>
            <th scope="col">#</th>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Opts</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, index) => (
            <Item key={item.id} toning={item} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Item = ({ toning, index }) => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const onSelect = (e) => {
    e.stopPropagation();
    setShow(true);
    dispatch(selectToning({ toningId: toning.id, tid }));
  };
  return (
    <tr>
      <td>#{index}</td>
      <td>{toning.id}</td>
      <td>
        <UpdateName toning={toning} />
      </td>
      <td>
        <Button variant="outline-success me-3">
          <DeleteTemplate toning={toning} />
        </Button>
        <Button variant="outline-danger" onClick={onSelect}>
          Editor
        </Button>
        {show && <ShowToning setShow={setShow} toning={toning} />}
      </td>
    </tr>
  );
};

const ShowToning = ({ setShow, toning }) => {
  return (
    <Modal show={true} size="lg" onHide={setShow}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex">
            <span className="mx-3">{toning.name}</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ColorPicker />
      </Modal.Body>
    </Modal>
  );
};

const UpdateName = ({ toning }) => {
  const [show, setShow] = useState(false);
  const [state, setState] = useState(toning.name);
  const [update] = useUpdateToningTemplateMutation();
  const handleUpdateName = () => {
    if (!state || state === toning.name) {
      setShow(false);
      return;
    }
    update({
      id: toning.id,
      body: { key: "name", value: state },
    }).unwrap();
    setShow(false);
  };
  if (show) {
    return (
      <input
        type="text"
        value={state}
        defaultValue={toning.name}
        placeholder="Toning name"
        onChange={(e) => setState(e.target.value)}
        onBlur={handleUpdateName}
      />
    );
  }
  return <div onClick={() => setShow(true)}>@{state}</div>;
};

const DeleteTemplate = ({ toning }) => {
  const [deleteIt, { isLoading }] = useDeleteToningTemplateMutation();
  const handleDelete = () => {
    deleteIt({ id: toning.id }).unwrap();
  };
  return (
    <Confirm
      onConfirm={handleDelete}
      can
      title={`Delete Toning ${toning.name}`}
      body={null}
    >
      <span className="cursor-pointer" style={{ marginBottom: 10 }}>
        {isLoading ? (
          <Spinner animation="border" variant="success" size="sm" />
        ) : (
          "Delete"
        )}
      </span>
    </Confirm>
  );
};

const CreateButton = () => {
  const [create, { isLoading }] = useAddToningTemplateMutation();

  const handleCreate = () => {
    create().unwrap();
  };
  if (isLoading) return <Spinner />;
  return <Button onClick={handleCreate}>Create</Button>;
};

export default Tonings;
