import { cacher } from "api/rtkQueryCacheUtils";
import { neku } from "./index";

const tagLibsApi = neku.injectEndpoints({
  endpoints: (builder) => ({
    getTagLibs: builder.query({
      query: (params) => {
        return { url: `/tagLibs`, method: "GET", params };
      },
      keepUnusedDataFor: 15 * 60,
      providesTags: cacher.providesList(`Taglib`),
    }),
    getTagLibStickers: builder.query({
      query: (data) => {
        const { id, params } = data;
        return {
          url: `/tagLibs/${id}/stickers`,
          method: "GET",
          params: params,
        };
      },
      keepUnusedDataFor: 24 * 60 * 60,
      transformResponse: (datas) => {
        const ids = datas.map((data) => data.id);
        const after = ids[ids.length - 1];
        return { datas, after, ids };
      }
    }),
    getTagLibPacks: builder.query({
      query: (data) => {
        const { id, params } = data;
        return {
          url: `/tagLibs/${id}/packs`,
          method: "GET",
          params: params,
        };
      },
      keepUnusedDataFor: 24 * 60 * 60,
      transformResponse: (datas) => {
        const ids = datas.map((data) => data.id);
        const after = ids[ids.length - 1];
        return { datas, after, ids };
      }
    }),
    createTagLib: builder.mutation({
      query(body) {
        return {
          url: `/tagLibs`,
          method: "POST",
          body,
        };
      },
      invalidatesTags: cacher.invalidatesList("Taglib"),
    }),
    deleteTagLibs: builder.mutation({
      query: (id) => ({
        url: `/tagLibs/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: cacher.invalidatesList("Taglib"),
    }),
    updateTagLib: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return {
          url: `/tagLibs/${id}`,
          method: "PUT",
          body,
        };
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTagLibsQuery,
  useGetTagLibStickersQuery,
  useGetTagLibPacksQuery,
  useCreateTagLibMutation,
  useDeleteTagLibsMutation,
  useUpdateTagLibMutation,
} = tagLibsApi;
export default tagLibsApi;
