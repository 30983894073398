import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Modal, Button, Spinner } from "react-bootstrap";
import { useUpdateAvatarMutation, useDeleteAvatarMutation, updateAvatar } from "api/neku/avatars";
import { Trash3, PencilSquare } from "react-bootstrap-icons";
import { useUpdateTemplateMutation } from "api/neku/templates";
import { useUpdateDlcMutation, useGetDlcsQuery } from "api/neku/dlcs";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useContetLang } from "hooks/config";
import CopyedCode from "ui/CopyedCode";
import DisplayUpdateTime from "ui/DisplayUpdateTime";
import Fetching from "ui/Fetching";
import Confirm from "components/Confirm";
import { setDrawers, initEditor, setColors } from "screens/Editor/uiSlice"
import Input from "components/Form/Input"
const AvatarCard = ({ avatar }) => {
  const showInfos = useSelector((state) => state.ui.avatars.showInfos);

  if (!avatar) {
    return null;
  }

  return (
    <div className="col h-100" >
      <div className="card mt-1">
        {showInfos && (
          <div className="card-header">
            <HeaderText data={avatar} />
          </div>
        )}
        <div className="card-image">
          <a href={avatar?.thumb}>
            <Card.Img
              src={avatar?.thumb}
              className="card-img-top"
              alt="Card image"
            />
          </a>
        </div>

        {showInfos && (
          <div className="card-body">
            <AvatarState avatar={avatar} />
            <AvatarSetCover avatar={avatar} />
            <AvatarSetPreview avatar={avatar} />
            <AvatarSetAudit avatar={avatar} />
            <AvatarSetDlcCover avatar={avatar} />
            <AvatarSetSimple avatar={avatar} />
            <RenderTags avatar={avatar} />
            <DisplayUpdateTime updateTime={avatar.updateTime} />
            <Link to={"/avatars?author=" + avatar.authorId} className="my-1">
                {avatar.authorName}
            </Link>
            <UpdateAvatar avatar={avatar} />
            <h6>
              down:{avatar.dailyCount?.downloadCount}/{avatar.dCount}
            </h6>
            <h6>
              cpt/layer:{avatar.cptCount}/{avatar.layerCount}
            </h6>
            <h6>
              <Link className=" me-1 mt-1" target="_blank" to={`/avatars?tid=${avatar.tid}`}>
                recreate:{avatar.dailyCount?.reCreateCount}/
                {avatar.reCreateCount}
              </Link>
            </h6>
            <p>{avatar.desc}</p>
            <AvatarEditorInit avatar={avatar} />
            <AvatarInfo avatar={avatar} />
            <AvatarDelete avatar={avatar} />
          </div>
        )}
        <CopyedCode copyed={avatar.id}>click copy image id</CopyedCode>
        <CopyedCode copyed={avatar.url}>click copy image url</CopyedCode>
      </div>
      <Card.Footer></Card.Footer>
    </div>
  );
};

const AvatarEditorInit = ({avatar}) => {
  const dispatch = useDispatch()
  const drawData = JSON.parse(avatar.drawData)
  const handelInit = () => {
      console.log(drawData)
      dispatch(initEditor(avatar.tid))
      dispatch(setDrawers({tid: avatar.tid, drawers:drawData.drawers }))
      dispatch(setColors({tid:avatar.tid, tonings:drawData.tonings}))
  }
    return (
      <>
        <Link className=" me-1 mt-1"  target="_blank" to={`/editor/${avatar.tid}`}>
          <span onClick={handelInit}>tid:{avatar.tid}</span>
        </Link>
      </>
    )
}

const AvatarState = ({ avatar }) => {
  const [updateAvatar] = useUpdateAvatarMutation();

  const updateState = (e) => {
    const value = avatar.state === 401 ? 0 : 401;
    updateAvatar({ id: avatar.id, body: { key: "state", value } }).unwrap();
  };

  if (avatar.state === 401) {
    return (
      <Badge pill bg="success" className="me-1" onClick={updateState}>
        401(online)
      </Badge>
    );
  }
  return (
    <Badge pill bg="warning" className="me-1" onClick={updateState}>
      {avatar.state}(local)
    </Badge>
  );
};

const AvatarSetCover = ({avatar}) => {
  const [update,{ isLoading }] = useUpdateTemplateMutation();
  const setCover = ()=> {
    const value = {
      cover: avatar.url,
      initDataId: avatar.id,
    };
    const body = { key: "cover", value: JSON.stringify(value) };
    update({ id: avatar.tid, body }).unwrap();
  }
  return (
    <Badge pill bg="danger" className="me-1" onClick={isLoading ? ()=>{}:setCover}>
      {isLoading ? 'loading...': 'set cover'}
    </Badge>
  )
}

const AvatarSetPreview = ({avatar}) => {
  const [update,{ isLoading }] = useUpdateTemplateMutation();
  const setCover = ()=> {
    const body = { key: "preview", value: avatar.url };
    update({ id: avatar.tid, body }).unwrap();
  }
  return (
    <Badge pill bg="danger" className="me-1" onClick={isLoading ? ()=>{}:setCover}>
      {isLoading ? 'loading...': 'set preview'}
    </Badge>
  )
}

const AvatarSetAudit = ({avatar}) => {
  const [update,{ isLoading }] = useUpdateAvatarMutation();
  const updateAudit = ()=> {
    const body = { key: "audit", value: avatar?.audit ? 0 : 1};
    update({ id: avatar.id, body }).unwrap()
  }
  return (
    <Badge pill bg={avatar?.audit ? 'danger':'warning'} className="me-1" onClick={isLoading ? ()=>{}:updateAudit}>
      {isLoading ? 'loading...': (avatar?.audit ? 'undo audit': 'audit')}
    </Badge>
  )
}

const AvatarSetSimple = ({avatar}) => {
  const [update,{ isLoading }] = useUpdateAvatarMutation();
  const updateSimple = ()=> {
    const body = { key: "simple", value: avatar?.simple ? 0 : 1};
    update({ id: avatar.id, body }).unwrap()
  }
  return (
    <Badge pill bg={avatar?.simple ? 'danger':'warning'} className="me-1" onClick={isLoading ? ()=>{}:updateSimple}>
      {isLoading ? 'loading...': (avatar?.simple ? 'undo simple': 'simple')}
    </Badge>
  )
}

const AvatarSetDlcCover = ({avatar}) => {
  const [show, setShow] = useState(false)
  return (
    <>
      <Badge pill bg={'warning'} className="me-1" onClick={() => setShow(!show)}>
        set dlc cover
      </Badge>
      {
        show && <DlcModal show={show} setShow={setShow} avatar={avatar} />
      }
    </>
  )
}

const AvatarInfo = ({avatar}) => {
  const [show, setShow] = useState(false)
  if(!avatar?.avatarInfo) return
  return (
    <>
      <Button className="me-1" onClick={() => setShow(!show)}>
        show info
      </Button>
      {
        show && <InfoModal show={show} setShow={setShow} avatar={avatar} />
      }
    </>
  )
}

const DlcModal = ({show, setShow, avatar}) => {
 const [id, setId] = useState(null)
 const [update,{ isLoading }] = useUpdateDlcMutation();
 const {
    data:dlcs,
    isFetching,
  } = useGetDlcsQuery({tid: avatar.tid}, { skip: !{tid: avatar.tid} })
  const handleUpdate = ()=> {
    console.log(id)
     update({
      id,
      body: { tid: avatar.tid, key: "cover", value: avatar.url },
    }).unwrap();
  }
  console.log(dlcs)
  return <Modal show={show} onHide={() => setShow(false)}>
    <Modal.Header>
      <Modal.Title>Set Dlc Cover</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {isFetching && <Fetching />}
      {dlcs?.map((dlc, index) => (
        <tr>
          <td>{index + 1}</td>
          <td>
            {dlc.cover && <img width={96} src={dlc.cover} alt="..." />}
          </td>
          <td>
            <div className="fw-bold">{dlc.name}</div>
            <CopyedCode copyed={dlc.id}>{dlc.id}</CopyedCode>
          </td>
          <td>
            <input type="radio" id={dlc.id} name="dlcId" value={dlc.id} onChange={(e) => setId(e.target.value)} />
          </td>
        </tr>
      ))}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={() => setShow(false)}>
        Close
      </Button>
      <Button
        disabled={isLoading || !id}
        variant="primary"
        onClick={handleUpdate}
      >
        Set Cover {isLoading && "ing..."}
      </Button>
    </Modal.Footer>
  </Modal>
}

const InfoModal = ({show, setShow, avatar}) => {
   return <Modal show={show} onHide={() => setShow(false)}>
     <Modal.Header>
       <Modal.Title>Avatar Info</Modal.Title>
     </Modal.Header>
     <Modal.Body>
        {avatar.avatarInfo}
     </Modal.Body>
     <Modal.Footer>
       <Button variant="secondary" onClick={() => setShow(false)}>
         Close
       </Button>
     </Modal.Footer>
   </Modal>
 }

 const AvatarDelete = ({avatar}) => {
  const [deleteIt, { isLoading }] = useDeleteAvatarMutation()
  const handleDelete = () => {
    deleteIt(avatar.id).unwrap();
  };
  return (
    <Confirm onConfirm={handleDelete}>
    {isLoading ? (
      <Spinner
        as="span"
        size="sm"
        aria-hidden="true"
        animation="border"
        style={{ width: 25, height: 25 }}
      />
    ) : (
      <div className="pickerOpt">
        <Trash3 color="red" />
      </div>
    )}
  </Confirm>
  )
 }

 const RenderTags = ({ avatar }) => {
  return (
    <>
      <Confirm confirmText="Done" body={<TagsBody avatar={avatar} />}>
        <Badge className="me-1" bg="success">
          + -
        </Badge>
      </Confirm>
      { avatar?.tag && <Badge className="me-1">
          <small>{avatar?.tag}</small>
      </Badge>}
    </>
  );
};

const TagsBody = ({ avatar }) => {
  const [update] = useUpdateAvatarMutation();
  const tags = useSelector(state => state.tagLibs)

  const handleRemove = (tag) =>
    update({ id: avatar.id, body: { key: "removeTag", value: tag } });

  const handleAdd = (tag) =>
    update({ id: avatar.id, body: { key: "addTag", value: tag.name } });

  return (
    <div>
      {avatar.tag && <Badge
          bg="primary"
          className="me-2 mt-2"
          onClick={() => handleRemove(avatar.tag)}
        >
          {avatar.tag}
      </Badge>}
      <hr />
      {Object.keys(tags)?.map(
        (id) =>
          avatar?.tag !== tags[id].name && (
            <Badge
              bg="secondary"
              className="me-2 mt-2"
              key={id}
              onClick={() => handleAdd(tags[id])}
            >
              {tags[id].name}
            </Badge>
          )
      )}
    </div>
  );
};

const UpdateAvatar = ({avatar}) => {
  const [like, setLike] = useState(avatar?.likeCount || 0)
  const [update] = useUpdateAvatarMutation();

  const handleDone = () =>
    update({ id: avatar.id, body: { key: "likeCount", value: like } });
  return (
    <h6 className="mt-2 d-flex align-items-center">
      like:{avatar.likeCount}/star:{avatar.starCount}
      <Confirm onConfirm={handleDone} confirmText="Done" body={<Input value={like} onChange={setLike} />}>
        <PencilSquare color="#ff4970" fontSize={16} className="mx-2" />
      </Confirm>
    </h6>
  )
}


const HeaderText = ({ data }) => {
  const { clientVer, contentLang } = data;
  const { code, short } = useContetLang(contentLang);
  return (
    <>
      {code} - {short} - {clientVer}
    </>
  );
};

export default AvatarCard;
