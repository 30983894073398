import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ChangeDLC from "./Cpt/ChangeDLC";
import ChangeLayer from "./Cpt/ChangeLayer";
import ChangePro from "./Cpt/ChangePro";
import ChangeCover from "./Cpt/ChangeCover"
import { useDeleteComponentMutation } from "api/neku/components";
import BindStyle from "./Cpt/BindStyle";
import Spinner from "components/Spinner";
import { Trash3 } from "react-bootstrap-icons";
import { unSelectCpt } from "screens/Editor/uiSlice";
import { useCpt } from "screens/Editor/hooks";
import Confirm from "components/Confirm";
import emitter from "canvasMitt";
import { Button } from "react-bootstrap";
import { useParams } from "react-router-dom";

const Options = () => {
  const { tid } = useParams();
  const id = useSelector((state) => state.ui.editor[tid].currCpt);
  const { cpt } = useCpt(id);

  if (!cpt) return null;
  return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <div className="cpt-options">
        <ChangeCover cpt={cpt} />
        <BindStyle cpt={cpt} />
        <ChangeDLC cpt={cpt} />
        <ChangeLayer cpt={cpt} />
        <ChangePro cpt={cpt} />
        <DeleteButton cpt={cpt} />
      </div>
    </div>
  );
};

const DeleteButton = ({ cpt }) => {
  const { tid } = useParams();
  const [deleteCpt, { isLoading }] = useDeleteComponentMutation();
  const dispatch = useDispatch();

  const handleDelete = () => {
    deleteCpt(cpt.id).then(() => {
      dispatch(unSelectCpt({cpt, tid}));
      emitter.emit(`unSelectCpt`, cpt);
      emitter.emit(`renderCpt`);
    });
  };

  return (
    <Confirm onConfirm={handleDelete}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Button variant="outline-success" size="sm">
          <Trash3 />
        </Button>
      )}
    </Confirm>
  );
};

export default Options;
