import React from "react";
import { useSelector } from "react-redux";

import {
  Link,
  Routes,
  Route,
  Navigate,
  useLocation,
  Outlet,
} from "react-router-dom";

import Login from "screens/Auth/Login";
import Signup from "screens/Auth/Signup";
import Home from "screens/Home";

import Personal from "screens/Personal";

import EditorCreate from "screens/Personal/Templates/Create";
import Editor from "screens/Editor";

import FrameDetail from "screens/Detail"

import AvatarsLayout from "screens/Avatars/index";
import Avatars from "screens/Avatars/Avatars";
import WebImgs from "screens/Avatars/WebImgs"

import AdminPanel from "screens/AdminPanel";
import AdminNormal from "screens/AdminPanel/Normal";
import AdminReviews from "screens/AdminPanel/Reviews";
import AdminOnlines from "screens/AdminPanel/Onlines";
import AdminBatchNos from "screens/AdminPanel/BatchNos";
import AdminSettles from "screens/AdminPanel/Settles";
import AdminPublishers from "screens/AdminPanel/Publishers";
import AdminTonings from "screens/AdminPanel/Tonings";
import AdminDlcs from "screens/AdminPanel/Dlcs"
import AdminLayerIcons from "screens/AdminPanel/LayerIcons"
import AdminTaglibs from "screens/AdminPanel/Taglibs"

import "./App.scss";
const App = () => {
  let location = useLocation();
  let state = location.state;
  return (
    <div className="container-fluid layout">
      <Routes location={state?.backgroundLocation || location}>
        <Route index element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<Signup />} />

        <Route path="/" element={<RequireAuthLayout />}>
          <Route path="personal" element={<Personal />} />
          <Route path="editor/:tid" element={<Editor />}></Route>
          <Route path="avatars" element={<AvatarsLayout />}>
            <Route index element={<Avatars />} />
            <Route path="webimgs" element={<WebImgs />} />
          </Route>
          <Route path="detail/:tid" element={<FrameDetail />} />
          <Route path="admin" element={<AdminPanel />}>
            <Route index element={<AdminNormal />} />
            <Route path="reviews" element={<AdminReviews />} />
            <Route path="onlines" element={<AdminOnlines />} />
            <Route path="publishers" element={<AdminPublishers />} />
            <Route path="tonings" element={<AdminTonings />} />
            <Route path="batchnos" element={<AdminBatchNos />} />
            <Route path="batchnos/:id/settles" element={<AdminSettles />} />
            <Route path="dlcs" element={<AdminDlcs />} />
            <Route path="layericons" element={<AdminLayerIcons />} />
            <Route path="taglibs" element={<AdminTaglibs />} />
          </Route>
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>

      {state?.backgroundLocation && (
        <Routes>
          <Route path="create" element={<EditorCreate />} />
        </Routes>
      )}
    </div>
  );
};

const RequireAuthLayout = () => {
  const isLoggedIn = useSelector((state) => state.auth.user);
  const location = useLocation();
  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  //TODO: ROLE LIMIT
  // console.log(location);

  return <Outlet />;
};

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}

export default App;
