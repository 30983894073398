//React entry point 会自动根据endpoints生成hooks
import { cacher } from "api/rtkQueryCacheUtils";
import { neku } from "./index";

//使用base URL 和endpoints 定义服务
const layersIconsApi = neku.injectEndpoints({
  endpoints: (builder) => ({
    getLayerIcons: builder.query({
      query: ({ author }) => ({
        url: `/layericons`,
        method: "GET",
        params: { author },
      }),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("LayerIcon"),
    }),
    createLayerIcons: builder.mutation({
      query: (body) => ({
        url: `/layericons`,
        method: "POST",
        body,
      }),
      invalidatesTags: cacher.invalidatesList("LayerIcon"),
    }),
    updateLayerIcon: builder.mutation({
      query: ({id,body}) => ({
        url: `/layericons/${id}`,
        method: "PUT",
        body: body
      }),
    }),
    deleteLayerIcon: builder.mutation({
      query: (id) => ({
        url: `/layericons/${id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const { 
  useGetLayerIconsQuery, 
  useCreateLayerIconsMutation,
  useDeleteLayerIconMutation,
  useUpdateLayerIconMutation
 } = layersIconsApi;
export default layersIconsApi;
