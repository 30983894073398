import React, { useState } from "react";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
const Confirm = (props) => {
  const [show, setShow] = useState(false);

  const onHide = (event) => {
    if (event) {
      event.stopPropagation();
    }
    setShow(false);
    props.onHide && props.onHide();
  };

  const onConfirm = (event) => {
    event.stopPropagation();
    props.onConfirm && props.onConfirm();
    setShow(false);
  };

  const Btn = React.cloneElement(props.children, {
    onClick: () => setShow(true),
  });

  return (
    <>
      {Btn}
      {show && (
        <Modal size="md" show={true} onHide={onHide}>
          <Modal.Header>
            <h5 className="m-0">{props.title || "Deleting Stuff"}</h5>
          </Modal.Header>
          <Modal.Body>
            {props.body || "Are you sure you want to delete this?"}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            <Button
              variant="outline-danger"
              disabled={props.disabled}
              onClick={onConfirm}
            >
              {props.confirmText || "Delete"}
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default Confirm;
