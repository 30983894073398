import React,{useEffect, useState} from "react";
import { Button } from "react-bootstrap";
import { useGetWebImgsQuery } from "api/neku/avatars";
import Fetching from "ui/Fetching";
import Card from "./Card";
import EmptyDatas from "ui/EmptyDatas";
import Pagination from "components/Pagination1";
import Select from "ui/Select";
import Create from "./Create"
const OrderBys = [
  { code: "updateTime", name: "Update Time" },
  { code: "dailyCount.downloadCount", name: "daily top -download" },
  { code: "weeklyCount.downloadCount", name: "weekly top -download" },
  { code: "monthlyCount.downloadCount", name: "monthly top -download" },
];

const WebImgs = () => {
  const [ show, setShow ] = useState(false);
  const [ after, setAfter ] = useState(null);
  const [ order, setOrder ] = useState('updateTime');
  const [ params, setParams ] = useState({})

  const { isFetching, data, refetch, fulfilledTimeStamp } = useGetWebImgsQuery( params,
    { skip: !params });

    useEffect(() => {
      const tem = { order }
      if(after) {
        tem.after = after
      }
      setParams(tem)
    },[after, order])
  const nextAfter = data ? data[data.length -1]?.id : ""
  return (
    <>
      <div className="d-flex justify-content-between my-2">
        <div className="d-flex">
            <Select
                value={order}
                datas={OrderBys}
                onChange={(v)=>setOrder(v)}
            />
            <Pagination
              currAfter={after}
              nextAfter={nextAfter}
              setCurrAfter={setAfter}
            />
            <Button variant="outline-primary" onClick={refetch}>
              Refetch
            </Button>
        </div>
        <Button onClick={() => setShow(!show)}>Upload</Button>
      </div>
      <Create show={show} setShow={setShow} />
      {isFetching && <Fetching />}
      {data?.length <= 0 && <EmptyDatas />}
      <div className="row row-cols-1 row-cols-md-4 row-cols-xl-6 row-cols-xxl-9  g-1 g-xl-2">
        {data?.map((data, index) => (
          <Card id={data.id} key={data.id} index={index} data={data} />
        ))}
      </div>
      <Pagination
        currAfter={after}
        nextAfter={nextAfter}
        setCurrAfter={setAfter}
      />
    </>
  );
};

export default WebImgs;
