import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import { useAddAvatarMutation } from "api/neku/avatars";
import templatesApi, { useGetTemplatesQuery } from "api/neku/templates";
import { useGetBatchNosQuery } from "api/neku/batchnos";
import layersApi from "api/neku/layers";
import toningsApi from "api/neku/tonings";
import cptsApi from "api/neku/components";
import dlcsApi, { useGetDlcsQuery } from "api/neku/dlcs";
import { captureIt, setCapture, setUpload } from "./uiSlice";

export const useTemplate = () => {
  const { tid } = useParams();
  return templatesApi.endpoints.getTemplate.useQueryState(tid);
};

export const useTid = () => {
  const [searchParams] = useSearchParams();
  const r = searchParams.get("r") ?? null;
  return r === "1";
};
export const useReviewModel = () => {
  const [searchParams] = useSearchParams();
  const r = searchParams.get("r") ?? null;
  return r === "1";
};

export const useCustomParams = () => {
  const { data: template } = useTemplate();
  const reviewModel = useReviewModel();

  if (!template) return null;
  if (!reviewModel) return { tid: template.id };
  return { tid: template.id, version: template.version };
};

export const useCapture = (source) => {
  const { tid } = useParams();
  const [drawData, setDrawData] = useState();
  const [avatar, setAvatar] = useState(null);

  const dispatch = useDispatch();

  const [addAvatar, { isLoading }] = useAddAvatarMutation();

  const drawers = useSelector((state) => state.ui.editor[tid].drawers);
  const tonings = useSelector((state) => state.ui.editor[tid].tonings);

  const thisSource = useSelector(
    (state) => state.ui.editor[tid].captureSource === source
  );
  const capture = useSelector((state) => state.ui.editor[tid].capture);

  const canCapture = thisSource && capture && drawers.length > 0;

  useEffect(() => {
    setDrawData({ canvas: { w: 512, h: 512 }, drawers, tonings });
  }, [drawers, tonings]);

  useEffect(() => {
    if (canCapture) {
      setAvatar(null);
      addAvatar({ avatar: capture, tid, drawData })
        .unwrap()
        .then((res) => {
          setAvatar(res);
          dispatch(captureIt({ captureSource: null, tid }));
        });
    }
  }, [dispatch, canCapture, capture, tid, addAvatar, drawData]);

  return [avatar, isLoading];
};

export const useAddAvatar = () => {
  const { tid } = useParams();
  const [drawData, setDrawData] = useState();

  const dispatch = useDispatch();

  const [addAvatar, { isLoading }] = useAddAvatarMutation();

  const drawers = useSelector((state) => state.ui.editor[tid].drawers);
  const tonings = useSelector((state) => state.ui.editor[tid].tonings);

  const showUpload = useSelector((state) => state.ui.editor[tid].showUpload);
  const capture = useSelector((state) => state.ui.editor[tid].capture);

  const canSave = showUpload && capture && drawers.length > 0;

  useEffect(() => {
    setDrawData({ canvas: { w: 512, h: 512 }, drawers, tonings });
  }, [drawers, tonings, tid]);

  useEffect(() => {
    if (canSave) {
      addAvatar({ avatar: capture, tid, drawData })
        .unwrap()
        .then((res) => {
          dispatch(setUpload({ showUpload: false, tid }));
          dispatch(setCapture({ capture: null, tid }));
        });
    }
  }, [dispatch, canSave, capture, tid, addAvatar, drawData]);

  return [isLoading];
};

export const useTemplates = (k, v) => {
  const filterIt = useMemo(() => {
    return createSelector(
      (res) => res.data,
      (data) => {
        if (v === "draft") {
          return data?.filter((t) => t.version);
        } else if (v === "online") {
          return data?.filter((t) => t.publishTime);
        } else if (k & v) {
          return data?.filter((t) => t[k] === v);
        }
        return data;
      }
    );
  }, [k, v]);

  return useGetTemplatesQuery(null, {
    selectFromResult: (result) => ({
      ...result,
      templates: filterIt(result),
    }),
  });
};

export const useLayer = (lid) => {
  const params = useCustomParams();
  const findById = useMemo(() => {
    return createSelector(
      (res) => res.data,
      (data) => data?.find((layer) => layer.id === lid)
    );
  }, [lid]);

  return layersApi.endpoints.getLayers.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      layer: findById(result),
    }),
  });
};

export const useCptsWithDlc = (dlc) => {
  const { tid } = useParams();
  const params = useCustomParams();
  const lid = useSelector((state) => state.ui.editor[tid].lid);

  const filterByDlc = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) =>
        data?.filter(
          (cpt) => cpt.state >= 0 && cpt.dlc === dlc && cpt.lid === lid
        ) ?? emptyArray
    );
  }, [dlc, lid]);

  return cptsApi.endpoints.getComponents.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      cpts: filterByDlc(result),
    }),
  });
};
export const useCptsWithoutDlc = () => {
  const { tid } = useParams();
  const lid = useSelector((state) => state.ui.editor[tid].lid);
  const params = useCustomParams();
  const filterByDlc = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) =>
        data?.filter((cpt) => cpt.state >= 0 && !cpt.dlc && cpt.lid === lid) ??
        emptyArray
    );
  }, [lid]);

  return cptsApi.endpoints.getComponents.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      components: filterByDlc(result),
    }),
  });
};
export const useLayersWithoutGroup = (gid) => {
  const params = useCustomParams();
  const filterByDlc = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) =>
        data?.filter((layer) => layer.state >= 0 && layer.gid && layer.gid === gid) ??
        emptyArray
    );
  }, [gid]);

  return layersApi.endpoints.getLayerGroups.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      layers: filterByDlc(result),
    }),
  });
};

export const useCptsSubscription = () => {
  const params = useCustomParams();
  return cptsApi.endpoints.getComponents.useQuerySubscription(params, {
    skip: !params,
  });
};
export const useCpts = () => {
  const params = useCustomParams();
  const filterByDlc = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) => data?.filter((f) => f.state >= 0) ?? emptyArray
    );
  }, []);

  return cptsApi.endpoints.getComponents.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      cpts: filterByDlc(result),
    }),
  });
};

export const useCpt = (id) => {
  const params = useCustomParams();
  const findById = useMemo(() => {
    const emptyObj = null;
    return createSelector(
      (res) => res.data,
      (data) => data?.find((cpt) => cpt.state >= 0 && cpt.id === id) ?? emptyObj
    );
  }, [id]);

  return cptsApi.endpoints.getComponents.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      cpt: findById(result),
    }),
  });
};

export const useLayers = (sortType = "z") => {
  const params = useCustomParams();
  const sortIt = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) =>
        data ? [...data].sort((a, b) => a[sortType] - b[sortType]) : emptyArray
    );
  }, [sortType]);

  return layersApi.endpoints.getLayers.useQueryState(params, {
    selectFromResult: (result) => ({ ...result, layers: sortIt(result) }),
  });
};

export const useLayerGroups = (sortType = "playIndex") => {
  const params = useCustomParams();
  const sortIt = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) =>
        data ? [...data].sort((a, b) => a[sortType] - b[sortType]) : emptyArray
    );
  }, [sortType]);

  return layersApi.endpoints.getLayerGroups.useQueryState(params, {
    selectFromResult: (result) => ({ ...result, layers: sortIt(result) }),
  });
};

export const useDlcs = () => {
  const { tid } = useParams();
  const reviewModel = useReviewModel();
  const params = reviewModel ? { tid, r: 1 } : { tid };
  return dlcsApi.endpoints.getDlcs.useQueryState(params, { skip: !tid });
};

export const useFetchDlcs = () => {
  const { tid } = useParams();
  const reviewModel = useReviewModel();
  const params = reviewModel ? { tid, r: 1 } : { tid };
  return useGetDlcsQuery(params, { skip: !tid });
};

export const useChildCpts = (cpt) => {
  const { tid } = useParams();
  const lid = useSelector((state) => state.ui.editor[tid].lid);
  const params = useCustomParams();

  const filterIt = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) =>
        data?.filter(
          (c) =>
            cpt.state >= 0 &&
            c.lid === lid &&
            c.dlc === cpt.dlc &&
            c.pid === cpt.id
        ) ?? emptyArray
    );
  }, [lid, cpt]);

  return cptsApi.endpoints.getComponents.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      cpts: filterIt(result),
    }),
  });
};

export const useTonings = () => {
  const params = useCustomParams();
  return toningsApi.endpoints.getTonings.useQueryState(params);
};

export const useToning = (toningId) => {
  const params = useCustomParams();
  const filterIt = useMemo(() => {
    return createSelector(
      (res) => res.data,
      (data) => data?.find((t) => t.id === toningId) ?? null
    );
  }, [toningId]);
  return toningsApi.endpoints.getTonings.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      toning: filterIt(result),
    }),
  });
};

export const useToningTemplate = (toningId) => {
  const res = toningsApi.endpoints.getToningsTemplate.useQueryState();
  return res?.data?.find((t) => t.id === toningId) ?? null;
  // const filterIt = useMemo(() => {
  //   return createSelector(
  //     (res) => res.data,
  //     (data) => data?.find((t) => t.id === toningId) ?? null
  //   );
  // }, [toningId,res]);
  // return filterIt(res)
};

export const useFetchBatchNos = () => {
  const { tid } = useParams();
  return useGetBatchNosQuery(tid, {
    skip: !tid,
  });
};
