import React from "react";
import Spinner from "components/Spinner";
import {
  useCreateBatchNoMutation,
  useGetBatchNosQuery,
} from "api/neku/batchnos";
import dayjs from "dayjs";
import ListGroup from "react-bootstrap/ListGroup";
import { ArrowClockwise, PlusLg } from "react-bootstrap-icons";
import { useSearchParams } from "react-router-dom";

const Index = () => {
  const [searchParams] = useSearchParams();
  const tid = searchParams.get("tid") ?? "";
  if (!tid) {
    return <h1>no tid..</h1>;
  }
  return (
    <div>
      <div>
        <RefreshButton tid={tid} />
        <CreateButton tid={tid} />
      </div>
      <DisplayBathNos tid={tid} />
    </div>
  );
};

const RefreshButton = ({ tid }) => {
  const { refetch, isFetching } = useGetBatchNosQuery(tid, {
    skip: !tid,
  });

  if (isFetching) return <Spinner className="me-3" />;
  return <ArrowClockwise onClick={refetch} className="icon me-3" />;
};

const CreateButton = ({ tid }) => {
  const [create, { isLoading }] = useCreateBatchNoMutation();
  const handleClick = () => {
    if (!tid) return null;
    const data = {
      tid,
      name: dayjs().format("YYYYMMDD"),
    };
    create(data).unwrap();
  };

  if (isLoading) {
    return <Spinner />;
  }

  return <PlusLg onClick={handleClick} className={"icon"} />;
};

const DisplayBathNos = ({ tid }) => {
  const { data: batchNos } = useGetBatchNosQuery(tid, {
    skip: !tid,
  });
  if (!batchNos || batchNos?.length <= 0) return <h1>no batchNos..</h1>;
  return (
    <ListGroup>
      {batchNos?.map((data) => (
        <ListGroup.Item
          key={data.id}
          action
          href={`batchnos/` + data.id + "/settles?tid=" + tid}
        >
          {data.name}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default Index;
