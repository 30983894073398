import React from "react";
import classNames from "classnames";

import { useUpdateComponentMutation } from "api/neku/components";
import { useRole } from "hooks/auth";

const Items = [900, 950, 1200, 1300, 1000, 1500, 2000];

const ChangePro = ({ cpt }) => {
  const isAdmin = useRole(`admin`);

  const style = classNames("me-1");

  const [update] = useUpdateComponentMutation();

  const onChange = (value,type) => {
    const data = {
      id: cpt.id,
      body: {
        key: type,
        value: Number(value),
      },
    };
    update(data)
      .unwrap()
      .then((res) => console.log(res));
  };

  if (!isAdmin) {
    return null;
  }

  return (
    <div className="">
      <select
        className={style}
        value={cpt.pro ?? 0}
        onChange={(e) => onChange(e.target.value,'pro')}
      >
        <option value="0">pro</option>
        {Items.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
      <select
        className={style}
        value={cpt.iOSPro ?? 0}
        onChange={(e) => onChange(e.target.value,'iOSPro')}
      >
        <option value="0">iOSPro</option>
        {Items.map((v) => (
          <option key={v} value={v}>
            {v}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ChangePro;
