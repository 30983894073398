import React, { useMemo } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import cptsApi, {
  useGetComponentsQuery,
  useUpdateComponentMutation,
} from "api/neku/components";
// import UpdatePrice from "../Component/UpdatePrice";
import classNames from "classnames";
import Select from "ui/Select";
import { createSelector } from "@reduxjs/toolkit";
import { useGetLayerQuery } from "api/neku/layers";
import {
  useGetBatchNosQuery,
  useUpdateBatchNoMutation,
} from "api/neku/batchnos";

const useCptsByLid = (batchNo, lid) => {
  const filterIt = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) => data?.filter((c) => lid && c.lid === lid) ?? emptyArray
    );
  }, [lid]);
  return cptsApi.endpoints.getComponents.useQueryState(
    { batchNo },
    {
      skip: !batchNo,
      selectFromResult: (r) => ({ ...r, cpts: filterIt(r) }),
    }
  );
};

const useLayers = (batchNo) => {
  const computeLayers = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) => {
        const lids = data?.map((c) => c.lid) ?? emptyArray;
        return [...new Set(lids)];
      }
    );
  }, []);
  return useGetComponentsQuery(
    { batchNo },
    {
      skip: !batchNo,
      selectFromResult: (r) => ({ ...r, lids: computeLayers(r) }),
    }
  );
};

const Settles = () => {
  const { id: batchNoId } = useParams("id");
  const [searchParams] = useSearchParams();
  const tid = searchParams.get("tid") ?? "";

  const { data: cpts, lids, refetch } = useLayers(batchNoId, tid);
  const price = useTotalPrice(cpts);

  return (
    <div>
      <div>
        Settles x <UpdateName id={batchNoId} tid={tid} />
      </div>
      <div className="d-flex justify-content-between border-bottom">
        <div className="my-2">
          <span className="h3">Count:&nbsp;{cpts?.length}</span>
          <span className="h3 mx-3">Price:&nbsp;{price}</span>
        </div>
        <div>
          <Button className="me-1 mt-1 mb-1" onClick={refetch}>
            refetch
          </Button>
        </div>
      </div>
      <div className="row">
        {lids?.map((lid) => (
          <Item key={lid} lid={lid} tid={tid} batchNoId={batchNoId} />
        ))}
      </div>
    </div>
  );
};

const UpdateName = ({ id, tid }) => {
  const [update] = useUpdateBatchNoMutation();
  const batchNoObjSelector = useMemo(() => {
    return createSelector(
      (res) => res.data,
      (data) => {
        return data?.find((i) => i.id === id);
      }
    );
  }, [id]);
  const { batchNo } = useGetBatchNosQuery(tid, {
    skip: !tid,
    selectFromResult: (result) => ({
      ...result,
      batchNo: batchNoObjSelector(result),
    }),
  });

  const handleUpdateName = (e) => {
    e.stopPropagation();
    if (!e.target.value || e.target.value === batchNo.name) {
      return;
    }
    update({
      id: batchNo.id,
      body: { tid, key: "name", value: e.target.value },
    }).unwrap();
  };

  if (!batchNo) return null;

  return (
    <input
      type="text"
      defaultValue={batchNo?.name}
      placeholder="BatchNo name"
      onBlur={handleUpdateName}
    />
  );
};

const Prices = [
  { code: 0, name: "Batch set price" },
  { code: 1, name: 1 },
  { code: 2, name: 2 },
  { code: 3, name: 3 },
  { code: 4, name: 4 },
  { code: 5, name: 5 },
  { code: 6, name: 6 },
];

const useTotalPrice = (cpts) => {
  const computeTotalPrice = useMemo(() => {
    return createSelector(
      (res) => res,
      (data) => {
        const total = data?.reduce((acc, c) => {
          const price = Number(c.price);
          return acc + price;
        }, 0);
        return total;
      }
    );
  }, []);
  return computeTotalPrice(cpts);
};

const Item = ({ batchNoId, tid, lid }) => {
  const { data: layer } = useGetLayerQuery(
    { tid, id: lid },
    { skip: !lid || !tid }
  );
  const { cpts } = useCptsByLid(batchNoId, lid);

  const price = useTotalPrice(cpts);
  const [update] = useUpdateComponentMutation();

  const handleBatchPrice = (v) => {
    const _price = Number(v);
    if (v > 0) {
      cpts.forEach((cpt) => {
        const data = {
          id: cpt.id,
          body: {
            key: "price",
            value: _price,
          },
        };
        update(data).unwrap();
      });
    }
  };

  if (!layer) return <div>load layer</div>;
  return (
    <div className="list-group-item border-0 border-bottom col-6">
      <div className="d-flex ">
        <div className="d-flex my-2" style={{ width: 260 }}>
          <div>
            <img
              className="mt-1 ms-1 me-2"
              width={50}
              src={`https://img.zthd.io/an1/acs/${layer?.cover}`}
              alt="..."
            />
          </div>
          <div>
            {layer.id} - {layer.name}
            <div className="my-1">Count:&nbsp;{cpts.length}</div>
            <h3 className="my-1">Total:&nbsp;{price}</h3>
            <Select datas={Prices} onChange={handleBatchPrice} />
          </div>
        </div>
        <div className="d-flex flex-wrap w-100">
          {cpts?.map((cpt) => (
            <ComponentCard cpt={cpt} key={cpt.id} />
          ))}
        </div>
      </div>
    </div>
  );
};

const ComponentCard = ({ cpt }) => {
  const cardClass = classNames("h-100", { "text-bg-warning": !cpt?.price });
  const link = `https://console.firebase.google.com/project/avatar-maker-23583/firestore/data/~2Fcomponents~2F${cpt?.id}`;
  return (
    <div className="m-2">
      <Card className={cardClass}>
        <Card.Header>
          <a className="me-1" target="_blank" href={link} rel="noreferrer">
            {cpt?.id}
          </a>
        </Card.Header>
        <div className="text-center mb-1 bg-secondary">
          <img
            width={96}
            height={96}
            src={`https://img.zthd.io/an1/ats/${cpt?.id}`}
            alt=""
          />
        </div>
        <div className="mx-2 my-1">
          <UpdatePrice cpt={cpt} />
        </div>
      </Card>
    </div>
  );
};

const Items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

const UpdatePrice = ({ cpt }) => {
  const [update] = useUpdateComponentMutation();

  const onChange = (value) => {
    const data = {
      id: cpt.id,
      body: {
        key: "price",
        value: Number(value),
      },
    };
    update(data)
      .unwrap()
      .then((res) => console.log(res));
  };

  return (
    <div className="row mb-1">
      <div className="col-sm-12">
        <select
          className=" form-select"
          value={cpt?.price}
          onChange={(e) => onChange(e.target.value)}
        >
          <option value="0">-</option>
          {Items.map((v) => (
            <option key={v} value={v}>
              {v}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default Settles;
