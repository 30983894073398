import React from "react";
import { useParams } from "react-router-dom";
import { useUpdateLayerMutation } from "api/neku/layers";
import "./index.scss";

export const UpdateZindex = ({ layer }) => {
  const { tid } = useParams();
  const [update] = useUpdateLayerMutation();
  const items = Array.from({ length: 400 }, (v, i) => i - 200);

  const onChange = (value) =>
    update({
      id: layer.id,
      body: { tid, key: "zIndex", value: Number(value) },
    });

  return (
    <select
      className={`rounded-3 me-1 py-2 select`}
      value={layer.z}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">Layer zIndex</option>
      {items.map((v) => (
        <option key={v} value={v}>
          Layer zIndex: {v}
        </option>
      ))}
    </select>
  );
};

export default UpdateZindex;
