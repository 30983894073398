import React from "react";
import Tabs from "components/Tabs";
import Nav from "./Nav";
import { Outlet } from "react-router-dom";
import { useRole } from "hooks/auth";

const Index = () => {
  const isAdmin = useRole(`admin`);
  const isView = useRole(`viewer`);
  var datas = [
    { name: "Templates", to: "", end: true },
    { name: "Reviews", to: "reviews" },
    { name: "Onlines", to: "onlines" },
    { name: "Publishers", to: "publishers" },
    { name: "Tonings", to: "tonings" },
    { name: "Dlcs", to: "dlcs" },
    { name: "Layer Icons", to: "layericons" },
    { name: "Taglibs", to: "taglibs" },
  ];

  if(isView) {
    datas = [
      { name: "Templates", to: "", end: true },
      { name: "Dlcs", to: "dlcs" },
    ];
  }
  if (!isAdmin && !isView) return null;
  return (
    <>
      <Nav />
      <Tabs datas={datas} />
      <div className="bg-white m-auto" style={{ minHeight: "95vh" }}>
        <Outlet />
      </div>
    </>
  );
};

export default Index;
