import { configureStore, combineReducers } from "@reduxjs/toolkit";
import logger from "redux-logger";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import { neku } from "./api/neku";

//uiSlice
import editor from "screens/Editor/uiSlice";

import uiAvatars from "screens/Avatars/uiSlice";

//datas
import auth from "api/neku/datas/authSlice";
import taglibs from "api/neku/datas/tagLibsSlice"
// import users from "api/shield/datas/usersSlice";

//template

const version = 2;

const rootPersistConfig = {
  key: "root",
  storage,
  version: version,
  blacklist: [neku.reducerPath, "datas", "ui", "auth", "avatars", "layerIcons", "tagLibs"],
};

// const uiPersistConfig = {
//   key: "ui",
//   storage: storage,
// };

const authPersistConfig = {
  key: "auth",
  version: version,
  storage: storage,
};

const uiPersistConfig = {
  key: "ui",
  version: version,
  storage: storage,
};

const taglibsPersistConfig = {
  key: "tagLibs",
  version: version,
  storage: storage,
};

const uiReducer = combineReducers({
  editor,
  avatars: uiAvatars,
});

const rootReducer = combineReducers({
  [neku.reducerPath]: neku.reducer,
  auth: persistReducer(authPersistConfig, auth),
  ui: persistReducer(uiPersistConfig, uiReducer),
  tagLibs: persistReducer(taglibsPersistConfig, taglibs)
  // users,
});
const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const rootStore = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    });
    if (process.env.NODE_ENV === "development") {
      middleware.concat(logger);
    }
    return middleware.concat(neku.middleware);
  },
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(rootStore);
