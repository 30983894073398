import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { Table, Image, Button } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { useCreateTagLibMutation, useDeleteTagLibsMutation, useGetTagLibsQuery } from "api/neku/taglibs";
import UpdateEntity from "./UpdateEntity";
import { createSelector } from "@reduxjs/toolkit";
import Spinner from "components/Spinner";
import Confirm from "components/Confirm";
import CopyedCode from "ui/CopyedCode";
import DisplayUpdateTime from "ui/DisplayUpdateTime";

const Index = () => {
  const [ name, setName ] = useState('')
  const [ note, setNote ] = useState('')
  const { data, isFetching, refetch } = useGetTagLibsQuery();
  const [ createTag, { isLoading } ] = useCreateTagLibMutation({});



  const handleCreate = () => {
    if(!name) {
        return
    }
    createTag({name, note}).then(res => {
        console.log(res)
    })
  }

  return (
    <>
      <div className="d-flex justify-content-between mb-2">
        <Button className="me-1" variant="outline-primary" onClick={refetch}>
          Refetch{isFetching && "ing"}
        </Button>
        <Confirm
          onConfirm={handleCreate}
          can
          title={`Create`}
          confirmText={'Create'}
          body={<Create setName={setName} setNote={setNote} name={name} note={note} />}
        >
            <Button>Create</Button>
        </Confirm>
      </div>
      <Table striped bordered hover>
        <thead className="table-secondary">
          <tr>
            <th>#</th>
            <th>shortId</th>
            <th>name</th>
            <th>note</th>
            <th>online</th>
            <th>dlc</th>
            <th>updateTime</th>
            <th>Operate</th>
          </tr>
        </thead>
        <tbody>
          {data &&
            data?.map((item, index) => (
              <Item item={item} index={index} key={item.id} refetch={refetch} />
            ))}
        </tbody>
      </Table>
    </>
  );
};

const Create = ({ name, setName, note, setNote}) => {
    return (<div className="d-flex flex-column createTag">
    <input
        type="input"
        className="input p-2"
        name="name"
        placeholder="Please enter name"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
    <textarea
      name="note"
      className="note mt-2 p-2"
      placeholder="Please enter note"
      value={note}
      onChange={(e) => setNote(e.target.value)} />
    </div>)
}

const Item = ({ item, index, refetch }) => {
  const [deleteIt, {isLoading}] = useDeleteTagLibsMutation();
  const handleDelete = () => {
    deleteIt(item.id).unwrap().then((res) => {
      refetch()
    })
  }
  return (
    <tr>
      <th>{index}</th>
      <td>
        <CopyedCode copyed={item.shortId}>{item.shortId}</CopyedCode>
      </td>
      <td>{item.name}</td>
      <td>{item?.note}</td>
      <td>
        <Link to={`/admin/onlines?tag=${item.name}`}>({item?.frameCount})</Link>
      </td>
      <td>
        <Link to={`/admin/dlcs?tag=${item.name}`}>({item?.dlcCount})</Link>
      </td>
      <td>
        <DisplayUpdateTime updateTime={item?.updateTime} />
      </td>
      <td>
        <Confirm
          onConfirm={handleDelete}
          can
          title={`Delete`}
          body={null}
        >
          <span className="cursor-pointer" style={{ marginBottom: 10 }}>
            {isLoading ? (
              <Spinner animation="border" variant="success" size="sm" />
            ) : (
              <Trash color="red"/>
            )}
          </span>
        </Confirm>
      </td>
    </tr>
  );
};

export default Index;
