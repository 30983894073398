import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { unSelectColor, selectColor } from "screens/Editor/uiSlice";
import AdjustColor from "./AdjustColor";
import color_none_image from "assets/edit_color_none.svg";
import { PlusLg } from "react-bootstrap-icons";
import "./index.scss";
import { useToningTemplate } from "screens/Editor/hooks";
import LinearColor from "./LinearColor";
import { makeShortId } from "commons";
import emitter from "canvasMitt";
import { useParams } from "react-router-dom";

const PickerColor = () => {
  const { tid } = useParams();
  const toningId = useSelector((state) => state.ui.editor[tid].toningId);
  const toning = useToningTemplate(toningId);
  if (!toning) {
    return <>no toning</>;
  }

  return (
    <div>
      <div
        className="row row-cols-3 row-cols-md-5 row-cols-xl-8 row-cols-xxl-10 g-1 w-100 position-relative"
        style={{ padding: "25px 0 20px" }}
      >
        <CleanColorButton />
        {toning.colors?.map((c) => (
          <LinearColor key={c.id} color={c} />
        ))}
        <AddColorButton />
      </div>
      <AdjustColor />
    </div>
  );
};

const CleanColorButton = () => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const toningId = useSelector((state) => state.ui.editor[tid].toningId);
  const handleClick = (e) => {
    e.stopPropagation();
    dispatch(unSelectColor({ toningId, tid }));
    emitter.emit(`unSelectColor`, toningId);
    emitter.emit(`render_${toningId}`, toningId);
  };
  return (
    <div
      className="col d-flex align-items-center justify-content-center"
      onClick={handleClick}
    >
      <img src={color_none_image} alt="" className="cursor-pointer" />
    </div>
  );
};

const AddColorButton = () => {
  const { tid } = useParams();
  const toningId = useSelector((state) => state.ui.editor[tid].toningId);
  const currColor = useSelector((state) => state.ui.editor[tid].currColor);
  const dispatch = useDispatch();
  const handleClick = () => {
    const color = generateColor();
    const data = { toningId, color, needCreate: true, tid };
    dispatch(selectColor(data));
    emitter.emit(`selectColor`, data);
    emitter.emit(`render_${data.toningId}`, data.toningId);
  };
  const generateColor = () => {
    const id = makeShortId(10);
    return {
      id,
      colors: [
        { offset: 0, color: "#000000" },
        { offset: 1, color: "#b99ba8" },
        { offset: 0, color: "#000000" },
        { offset: 0, color: "#000000" },
        { offset: 0.42179, color: "#512568" },
      ],
    };
  };

  return (
    <>
      <div className="col d-flex align-items-center justify-content-center m-0">
        <div
          style={{
            border: "1px dashed #CCCCCC",
            padding: 12,
            borderRadius: 10,
            cursor: "pointer",
          }}
          onClick={handleClick}
        >
          <PlusLg width={30} height={30} style={{ color: "#CBCBCB" }} />
        </div>
      </div>
      {currColor?.needCreate && (
        <LinearColor color={currColor.color} key={currColor.id} />
      )}
    </>
  );
};

export default PickerColor;
