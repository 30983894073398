import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ParentFolder as AsFolder } from "./ParentFolder";
import { useChildCpts, useLayer } from "screens/Editor/hooks";
import { selectCpt, unSelectCpt, setCurrCpt } from "screens/Editor/uiSlice";
import { CardWrap } from "./CardWrap";
import emitter from "canvasMitt";
import classNames from "classnames";
import { useParams } from "react-router-dom";

export const CptLeve1 = ({ cpt }) => {
  const { cpts: childs } = useChildCpts(cpt);

  if (childs && childs.length > 0) {
    return <AsFolder cpt={cpt} childs={childs} />;
  }

  return <Cpt cpt={cpt} />;
};

const Cpt = ({ cpt }) => {
  const {tid} = useParams();
  const { id, cover } = cpt;
  const lid = useSelector((state) => state.ui.editor[tid].lid);
  const { layer } = useLayer(lid);
  const inDrawer = useSelector((state) =>
    state.ui.editor[tid].drawers.some((drawer) => drawer.cid === id)
  );

  const dispatch = useDispatch();
  const handleClick = () => {
    if (inDrawer) {
      dispatch(setCurrCpt({currCpt: null, tid}));
      dispatch(unSelectCpt({cpt, tid}));
      emitter.emit(`unSelectCpt`, cpt);
    } else {
      dispatch(setCurrCpt({currCpt:cpt.id, tid}));
      dispatch(selectCpt({ layer, cpt: cpt, tid }));
      emitter.emit(`selectCpt`, { layer, cpt: cpt });
    }
    emitter.emit(`renderCpt`);
  };

  const style = classNames(
    "m-auto cpt d-flex justify-content-center align-items-center cursor-pointer",
    { "cpt-active": inDrawer }
  );

  if (!layer || cpt.lid !== layer.id) {
    return null;
  }

  return (
    <CardWrap>
      <div className={style} onClick={handleClick}>
        <img src={`https://img.zthd.io/an1/ats/${cover ?? id}`} alt="" />
        {cpt.pro > 0 && <div className="pro">{cpt.pro}</div>}
        {cpt.iOSPro > 0 && <div className="iosPro">{cpt.iOSPro}</div>}
      </div>
    </CardWrap>
  );
};

export default Cpt;
