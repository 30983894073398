import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Card } from "react-bootstrap";
import {
  selectLayer,
  selectCpt,
  unSelectCpt,
  setCurrCpt,
} from "screens/Editor/uiSlice";
import { useLayer } from "screens/Editor/hooks";
import classNames from "classnames";
import emitter from "canvasMitt";
import { useParams } from "react-router-dom";

const Cpts = ({ cpts }) => {
  return (
    <div className="row row-cols-2 row-cols-md-4 row-cols-lg-5 row-cols-xl-6 g-1 gy-2 row-cols-xxl-7 mt-1">
      {cpts?.map((cpt) => (
        <Cpt cpt={cpt} key={cpt.id} />
      ))}
    </div>
  );
};

const Cpt = ({ cpt }) => {
  const { tid } = useParams()
  const { id, lid } = cpt;
  const dispatch = useDispatch();
  const selected = useSelector((state) =>
    state.ui.editor[tid].drawers.some((drawer) => drawer.cid === id)
  );

  const { layer } = useLayer(lid);

  const handleClick = () => {
    dispatch(selectLayer({lid: cpt.lid, tid}));
    if (selected) {
      dispatch(setCurrCpt({currCpt: null, tid}));
      dispatch(unSelectCpt({cpt, tid}));
      emitter.emit(`unSelectCpt`, cpt);
    } else {
      dispatch(setCurrCpt({currCpt: id, tid}));
      dispatch(selectCpt({ layer, cpt: cpt, tid }));
      emitter.emit(`selectCpt`, { layer, cpt: cpt });
    }
    emitter.emit(`renderCpt`);
  };

  const style = classNames(
    "m-auto dlc-cpt d-flex justify-content-center align-items-center cursor-pointer",
    { active: selected }
  );
  return (
    <div className={"col"} onClick={handleClick}>
      <Card className="text-center h-100 border-0 dlc-cpt-card">
        <div className={style}>
          <img src={`https://img.zthd.io/an1/ats/${id}`} alt="" />
        </div>
      </Card>
    </div>
  );
};

export default Cpts;
