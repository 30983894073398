import React from "react";
import { useParams } from "react-router-dom";
import { useUpdateDlcMutation } from "api/neku/dlcs";

const UpdateSku = ({ dlc }) => {
  const { tid } = useParams();

  const [update] = useUpdateDlcMutation();

  const handleUpdate = (value) => {
    update({
      id: dlc.id,
      body: { tid, key: "sku", value },
    }).unwrap();
  };

  return (
    <select
      className="right-panel-btn right-panel-select"
      value={dlc.sku}
      onChange={(e) => handleUpdate(e.target.value)}
      style={{ fontSize: "14px", padding: "6px 20px 6px 0" }}
    >
      <option value="">Set SKU</option>
      <option value="DLC_FREE">Set SKU:&nbsp;&nbsp;DLC_FREE</option>
      <option value="DLC_SUBSCRIBER">Set SKU:&nbsp;&nbsp;DLC_SUBSCRIBER</option>
      <option value={`DLC_${dlc.id}`}>Set SKU:&nbsp;&nbsp;DLC_{dlc.id}</option>
    </select>
  );
};

export default UpdateSku;
