import React from "react";
import { useParams } from "react-router-dom";
import { useUpdateComponentMutation } from "api/neku/components";
import { useChildCpts, useLayers } from "screens/Editor/hooks";

import { useRole } from "hooks/auth";

const ChangeLayer = ({ cpt }) => {
  const isAdmin = useRole(`admin`);

  const { tid } = useParams();
  const { layers } = useLayers();
  const [update] = useUpdateComponentMutation();

  const onChange = (value) => {
    if (value === "") {
      return;
    }
    update({
      id: cpt.id,
      body: { tid, key: "lid", value },
    }).unwrap();
  };

  const { cpts: childs } = useChildCpts(cpt);

  if (!isAdmin) {
    return null;
  }

  if (!layers) {
    return null;
  }
  if (cpt.pid) {
    return null;
  }
  if (childs.length > 0) {
    return null;
  }

  return (
    <div>
      <select
        className="me-1"
        value={cpt.lid}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">change layer</option>
        {layers.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ChangeLayer;
