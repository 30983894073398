import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GradientPicker } from "react-linear-gradient-picker";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { XLg, Trash3, Files, SendPlus, Check2 } from "react-bootstrap-icons";
import "react-linear-gradient-picker/dist/index.css";
import { ChromePicker } from "react-color";
import { hexToRgba, rgbToHex } from "hooks/color";
import { useAddColorMutation, useDeleteColorMutation } from "api/neku/tonings";
import emitter from "canvasMitt";
import "./index.scss";
import Confirm from "components/Confirm";
import { makeShortId } from "commons";
import { selectColor, unSelectColor } from "screens/Editor/uiSlice";

const WrappedSketchPicker = ({ onSelect, ...rest }) => {
  const { wrapped, setWrapped } = rest;
  const rgbToRgba = (rgb, a = 1) => {
    const ret = rgb.replace("rgb(", "rgba(").replace(")", `, ${a})`);
    return ret;
  };
  return (
    <div
      onClick={(event) => event.stopPropagation()}
      className="position-relative w-100"
      style={{ zIndex: 6 }}
    >
      {wrapped && (
        <>
          <span
            className="position-absolute cursor-pointer bg-dark bg-opacity-50 rounded-circle"
            style={{
              zIndex: 1,
              left: "50%",
              top: -295,
              width: 22,
              height: 22,
              marginLeft: 85,
            }}
            onClick={() => setWrapped(false)}
          >
            <XLg width={14} height={14} style={{ marginTop: -5 }} />
          </span>
          <ChromePicker
            {...rest}
            color={rgbToRgba(rest.color, rest.opacity)}
            onChange={(c) => {
              const { r, g, b, a } = c.rgb;
              onSelect(`rgb(${r}, ${g}, ${b})`, a);
            }}
          />
        </>
      )}
    </div>
  );
};

const colorsToPalette = (colors) =>
  colors?.map(({ color, offset }) => {
    const rgba = hexToRgba(color);
    return { color: rgba.color, offset: `${offset}` };
  });

const usePalette = (currColor) => {
  const [state, setState] = useState();

  useEffect(() => {
    const _palette = colorsToPalette(currColor ? currColor.colors : null);
    setState(_palette);
  }, [currColor]);

  return [state, setState];
};

const AdjustColor = () => {
  const {tid} = useParams();
  const currColor = useSelector((state) => state.ui.editor[tid].currColor);
  const toningId = useSelector((state) => state.ui.editor[tid].toningId);
  const [palette, setPalette] = usePalette(currColor?.color);
  const [wrapped, setWrapped] = useState(false);
  const handleChange = (v) => {
    setPalette(v);
    const colors = v.map(({ color, offset }) => {
      const rgb = rgbToHex(color);
      return { color: rgb, offset: offset };
    });
    const data = {
      toningId: currColor.toningId,
      color: { id: currColor.color.id, colors },
    };
    emitter.emit(`selectColor`, data);
    emitter.emit(`render_${currColor.toningId}`, currColor.toningId);
  };

  if (!currColor) return null;
  if (!palette) return null;
  if (currColor.toningId !== toningId) return null;

  return (
    <div
      className="d-flex justify-content-between w-100 gap-1"
      style={{
        position: "fixed",
        bottom: "75px",
        left: "0",
        height: 0,
      }}
    >
      <div style={{ minWidth: "330px" }} />
      <div className="DrawerPanel">
        <div
          className="m-auto flex-center justify-content-between ps-4"
          style={{
            width: "475px",
            background: "rgba(0,0,0,0.4)",
            borderRadius: "100px",
          }}
        >
          <div
            className="picker"
            onClick={(e) => {
              e.stopPropagation();
              const csh = document.querySelectorAll(".csh>.cs");
              if (csh && csh.length > 0) {
                if (!csh[0]?.isClick) {
                  setWrapped(true);
                  csh.forEach((item) => {
                    item.addEventListener("click", (e) => {
                      e.stopPropagation();
                      setWrapped(true);
                    });
                    item.isClick = true;
                  });
                }
              }
            }}
          >
            <GradientPicker
              {...{
                maxStops: 5,
                minStops: 2,
                width: 275,
                paletteHeight: 50,
                palette,
                onPaletteChange: (v) => handleChange(v),
              }}
            >
              <WrappedSketchPicker wrapped={wrapped} setWrapped={setWrapped} />
            </GradientPicker>
          </div>
          <div
            className="flex-center justify-content-center"
            style={{ flex: "1" }}
          >
            <Copy currColor={currColor} />
            <DeleteButton />
            <SaveOrUpdateButton
              needCreate={currColor.needCreate}
              palette={palette}
            />
          </div>
        </div>
      </div>
      <div
        className="flex-grow-1"
        style={{ minWidth: "490px", maxWidth: "900px" }}
      />
    </div>
  );
};
const Copy = ({ currColor }) => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const handleClick = () => {
    const id = makeShortId(10);
    const data = {
      ...currColor,
      needCreate: true,
      color: { ...currColor.color, id },
      tid
    };
    dispatch(selectColor(data));
    emitter.emit(`selectColor`, data);
    emitter.emit(`render_${data.toningId}`, data.toningId);
  };
  return (
    <div className="pickerOpt">
      <Files onClick={handleClick} />
    </div>
  );
};

const DeleteButton = () => {
  const { tid } = useParams();
  const currColor = useSelector((state) => state.ui.editor[tid].currColor);
  const [deleteIt, { isLoading }] = useDeleteColorMutation();
  const dispatch = useDispatch();
  const handleDelete = () => {
    if (currColor?.needCreate) {
      dispatch(unSelectColor({toningId: currColor.toningId, tid}));
      emitter.emit(`unSelectColor`, currColor.currColor);
      emitter.emit(`render_${currColor.toningId}`, currColor.toningId);
      return;
    }
    deleteIt({
      tid,
      toningId: currColor?.toningId,
      colorId: currColor?.color?.id,
    }).unwrap();
  };

  if (!currColor) {
    return null;
  }
  return (
    <div style={{ margin: "0 15px" }}>
      <Confirm onConfirm={handleDelete}>
        {isLoading ? (
          <Spinner
            as="span"
            size="sm"
            aria-hidden="true"
            animation="border"
            style={{ width: 25, height: 25 }}
          />
        ) : (
          <div className="pickerOpt">
            <Trash3 />
          </div>
        )}
      </Confirm>
    </div>
  );
};

const SaveOrUpdateButton = ({ palette }) => {
  const { tid } = useParams();
  const toningId = useSelector((state) => state.ui.editor[tid].toningId);
  const currColor = useSelector((state) => state.ui.editor[tid].currColor?.color);

  const [create, { isLoading, isSuccess, reset }] = useAddColorMutation();

  useEffect(() => {
    reset();
  }, [currColor, reset]);

  const handleCreate = () => {
    const colors = palette.map(({ color, offset }) => {
      const rgb = rgbToHex(color);
      return { color: rgb, offset: offset };
    });
    create({ id: toningId, body: { tid, colorId: currColor?.id, colors } })
      .unwrap()
      .then((res) => console.log(res));
  };

  if (isLoading)
    return (
      <Spinner
        as="span"
        size="sm"
        aria-hidden="true"
        animation="border"
        variant="success"
      />
    );

  if (isSuccess) {
    return (
      <div className="pickerOpt">
        <Check2 />
      </div>
    );
  }

  return (
    <div className="pickerOpt">
      <SendPlus onClick={handleCreate} />
    </div>
  );
};

export default AdjustColor;
