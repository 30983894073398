import React from "react";
import { Spinner } from "react-bootstrap";
const Fetching = () => {
  return (
    <div className="d-md-flex">
      <h5>
        <Spinner
          as="span"
          size="sm"
          aria-hidden="true"
          animation="border"
          variant="success"
          className="me-1"
        />
        Loading...
      </h5>
    </div>
  );
};

export default Fetching;
