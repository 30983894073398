//React entry point 会自动根据endpoints生成hooks
import { cacher } from "api/rtkQueryCacheUtils";
import { neku } from "./index";
const updateFn = (draft, body) => {
  const { key, value } = body;
  if (draft) {
    if (key === "name") {
      draft.name = value;
    }
  }
};

const optimisticUpdate = async (
  id,
  body,
  dispatch,
  queryFulfilled,
  getState
) => {
  const updates = [];

  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    getState(),
    [{ type: "Toning", id: id }]
  )) {
    if (endpointName === "getTonings") {
      updates.push(
        dispatch(
          api.util.updateQueryData(endpointName, originalArgs, (tonings) => {
            const t = tonings.find((t) => t.id === id);
            updateFn(t, body);
          })
        )
      );
    }
  }

  try {
    await queryFulfilled;
  } catch {
    for (const u of updates) {
      u.undo();
    }
  }
};

//使用base URL 和endpoints 定义服务
const api = neku.injectEndpoints({
  endpoints: (builder) => ({
    getTonings: builder.query({
      query: (params) => ({ url: `/tonings`, method: "GET", params }),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("Toning"),
    }),
    getToningsTemplate: builder.query({
      query: (params) => ({ url: `/tonings/template`, method: "GET", params }),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("Toning"),
    }),
    addToning: builder.mutation({
      query: (data) => {
        const { tid } = data;
        return {
          url: `/tonings`,
          method: "POST",
          body: { tid },
        };
      },
      invalidatesTags: cacher.invalidatesList("Toning"),
    }),
    addToningTemplate: builder.mutation({
      query: (data) => {
        return {
          url: `/tonings/template`,
          method: "POST",
        };
      },
      invalidatesTags: cacher.invalidatesList("Toning"),
    }),
    saveToningTemplate: builder.mutation({
      query: (data) => {
        return {
          url: `/tonings/template/save`,
          method: "POST",
          body: data
        };
      },
      invalidatesTags: cacher.invalidatesList("Toning"),
    }),
    updateToning: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return {
          url: `/tonings/${id}`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(
        { id, body },
        { dispatch, queryFulfilled, getState }
      ) {
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
    updateToningTemplate: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return {
          url: `/tonings/template/${id}`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(
        { id, body },
        { dispatch, queryFulfilled, getState }
      ) {
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
    deleteToning: builder.mutation({
      query: ({ id, tid }) => ({
        url: `tonings/${id}`,
        method: `DELETE`,
        body: { tid },
      }),
      invalidatesTags: cacher.invalidatesList("Toning"),
    }),
    deleteToningTemplate: builder.mutation({
      query: ({ id }) => ({
        url: `tonings/template/${id}`,
        method: `DELETE`,
      }),
      invalidatesTags: cacher.invalidatesList("Toning"),
    }),
    addColor: builder.mutation({
      query: ({ id, body }) => ({
        url: `tonings/${id}/colors`,
        method: "POST",
        body,
      }),
      invalidatesTags: cacher.invalidatesList("Toning"),
    }),
    addColorTemplate: builder.mutation({
      query: ({ id, body }) => ({
        url: `tonings/template/${id}/colors`,
        method: "POST",
        body,
      }),
      invalidatesTags: cacher.invalidatesList("Toning"),
    }),
    deleteColor: builder.mutation({
      query: ({ tid, toningId, colorId }) => ({
        url: `tonings/${toningId}/colors`,
        method: "DELETE",
        body: { tid, colorId },
      }),
      invalidatesTags: cacher.invalidatesList("Toning"),
    }),
    deleteColorTemplate: builder.mutation({
      query: ({ toningId, colorId }) => ({
        url: `tonings/template/${toningId}/colors`,
        method: "DELETE",
        body: { colorId },
      }),
      invalidatesTags: cacher.invalidatesList("Toning"),
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const {
  useGetToningsQuery,
  useAddToningMutation,
  useUpdateToningMutation,
  useDeleteToningMutation,
  useAddColorMutation,
  useDeleteColorMutation,
  useGetToningsTemplateQuery,
  useAddToningTemplateMutation,
  useUpdateToningTemplateMutation,
  useDeleteToningTemplateMutation,
  useAddColorTemplateMutation,
  useSaveToningTemplateMutation,
  useDeleteColorTemplateMutation,
} = api;
export default api;
