import React from "react";
import { useDispatch } from "react-redux";
import { Shuffle } from "react-bootstrap-icons";
import { resetCanvas, selectCpt, selectColor } from "../uiSlice";
import { random } from "./index";
import { useCpts, useLayers, useTonings } from "../hooks";
import emitter from "canvasMitt";
import { useParams } from "react-router-dom";

export const Random = ({ className = "" }) => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const { layers } = useLayers();
  const { cpts } = useCpts();
  const { data: tonings } = useTonings();

  const randomLayers = () =>
    layers.filter((layer) => {
      if (layer.id === "Rzmzz5qi") {
        console.log(layer);
      }

      if (layer.state === -1) {
        return false;
      }
      if (layer.opt) {
        const layerRandom = random(10);
        return layerRandom > 3;
      }
      return true;
    });

  const randomCpts = (layer,tid) => {
    const cs = cpts?.filter((c) => c.lid === layer.id);
    if (!cs) {
      return;
    }
    const index = random(cs?.length) - 1;
    const cpt = cs[index];
    if (!cpt) return null;
    if (cpt.state === -1) return null;
    dispatch(selectCpt({ layer, cpt, tid }));
    emitter.emit(`selectCpt`, { layer, cpt });
  };

  const randomToning = (layer, pickTonings) => {
    if (layer?.toning) {
      if (pickTonings.includes(layer?.toning)) {
        return;
      }

      const toningRandom = random(10);
      if (toningRandom > 5) {
        return;
      }
      const match = tonings?.find((t) => t.id === layer?.toning);
      if (match) {
        const i = random(match.colors.length) - 1;
        const color = match?.colors[i];
        if (color) {
          const data = { toningId: match?.id, color, tid };
          dispatch(selectColor(data));
          emitter.emit(`selectColor`, data);
          pickTonings.push(layer.toning);
        }
      }
    }
  };

  const randomAvatar = () => {
    emitter.emit(`resetCanvas`, "reset");
    dispatch(resetCanvas(tid));
    const luckLayers = randomLayers();
    luckLayers.forEach((layer) => {
      randomCpts(layer, tid);
    });
    const luckTonings = [];
    luckLayers.forEach((layer) => {
      randomToning(layer, luckTonings);
    });

    emitter.emit(`renderCpt`);
  };

  if (!layers) {
    return null;
  }

  return (
    <div className={className} onClick={randomAvatar}>
      <Shuffle />
    </div>
  );
};
