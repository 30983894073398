import React from "react";
import { useParams } from "react-router-dom";
import { ArrowClockwise, PlusLg } from "react-bootstrap-icons";
import Spinner from "components/Spinner";
import { useCreateBatchNoMutation } from "api/neku/batchnos";
import dayjs from "dayjs";
import { useFetchBatchNos } from "../../hooks";

const Menus = () => {
  return (
    <div>
      <RefreshButton />
      <CreateButton />
    </div>
  );
};

const RefreshButton = () => {
  const { refetch, isFetching } = useFetchBatchNos();

  if (isFetching) return <Spinner className="me-3" />;
  return <ArrowClockwise onClick={refetch} className="icon me-3" />;
};

const CreateButton = () => {
  const { tid } = useParams();
  const [create, { isLoading }] = useCreateBatchNoMutation();
  const handleClick = () => {
    if (!tid) return null;
    const data = {
      tid,
      name: dayjs().format("YYYYMMDD"),
    };
    create(data).unwrap();
  };

  if (isLoading) {
    return <Spinner />;
  }

  return <PlusLg onClick={handleClick} className={"icon"} />;
};

export default Menus;
