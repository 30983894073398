import React, { useEffect, useState } from "react";
import { useUpdateTemplateMutation } from "api/neku/templates";

const UpdateEntity = ({ data, entityKey }) => {
  const [editor, setEditor] = useState(false);
  const [state, setState] = useState(data[entityKey]);

  const [update, { isFetching }] = useUpdateTemplateMutation();

  const cannotSave = !state || state === "" || isFetching;
  const handleUpdate = () => {
    if (cannotSave) {
      return;
    }
    update({
      id: data.id,
      body: { key: entityKey, value: state },
    })
      .unwrap()
      .then(() => setEditor(false));
  };

  useEffect(() => {
    setState(data[entityKey] || "");
  }, [data, entityKey]);

  if (editor) {
    return (
      <td>
        <div className="col-auto input-group">
          <span onClick={() => setEditor(false)} className="input-group-text">
            x
          </span>
          <input
            type="input"
            className="form-control"
            value={state}
            onChange={(e) => setState(e.target.value)}
            placeholder={state}
          />
          <button
            disabled={cannotSave}
            onClick={handleUpdate}
            className="btn btn-outline-primary"
            type="button"
          >
            Update{isFetching && "ing..."}
          </button>
        </div>
      </td>
    );
  }
  return <td onClick={() => setEditor(true)}>{data[entityKey]}</td>;
};

export default UpdateEntity;
