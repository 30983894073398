// import { useGetUserQuery } from "api/shield/users";
import React from "react";
import userImg from "assets/user_avatar.png";
import styles from "./index.module.scss";
import { useSelector } from "react-redux";

const User = () => {
  // const { data } = useGetUserQuery();
  const { user: currentUser } = useSelector((state) => state.auth);
  return (
    <div className={styles.title}>
      <div className={styles.avatar}>
        <img
          src={userImg}
          alt="user"
        />
      </div>
      <div>
        <div className={styles.userName}>{currentUser?.username}</div>
        <div className={styles.guide}>
          <span className="pe-2">Beginner's Guide:</span>
          <a href="#1" className="text-decoration-none">
            《Editor Tutorial》
          </a>
          <a href="#2" className="text-decoration-none">
            《Rules for Works》
          </a>
          <a href="#3" className="text-decoration-none">
            《Copyright Statement》
          </a>
        </div>
      </div>
    </div>
  );
};

export default User;
