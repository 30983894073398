import React from "react";
import { CardWrap } from "./CardWrap";
import { PlusLg } from "react-bootstrap-icons";
import { Spinner } from "react-bootstrap";
import { useUpdateComponentMutation, useAddComponentCoverMutation } from "api/neku/components"


const Index = ({ cpt }) => {
  const { id, cover } = cpt;
  const [updateIt, {isLoading}] = useUpdateComponentMutation()
  const [uploadCover] = useAddComponentCoverMutation()

  const selectFile = (files) => {
    if (files === undefined) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement("img");
      img.onload = () => {
        const cpt = e.target.result;
        uploadCover({id, cover: cpt}).then(res => {
            if(res?.data?.cover) {
                updateIt({id, body: {key: 'cover', value: res?.data?.cover}}).unwrap()
            }
        })
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <CardWrap>
        {isLoading ? (
          <Spinner
            as="span"
            size="sm"
            aria-hidden="true"
            animation="border"
            variant="success"
            style={{ width: 25, height: 25, margin: '2.5px' }}
          />
        ) : (
          <div className="pickerOpt">
            <ShowCover cover={cover} />
            <input
                style={{
                width: 30,
                height: 30,
                opacity: 0,
                position: "absolute",
                left: 0,
                top: 0,
                cursor: "pointer",
                }}
                type="file"
                accept=".jpg, .png, .jpeg, .webp"
                onChange={(e) => selectFile(e.target.files)}
                value=""
            />
          </div>
        )}
    </CardWrap>
  );
};

const ShowCover = ({cover}) => {
    if(cover) {
        return (
            <img
            key={cover}
            style={{ width: 30, height: 30, margin: 0, borderRadius: 13 }}
            src={`https://img.zthd.io/an1/ats/${cover}`}
            alt=""
            />
        )
    }
    return (
        <PlusLg size={30} color="#a4a8ac" />
    )
}

export default Index;
