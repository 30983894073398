import React, { useState, useEffect } from "react";
import { ClipboardCheck } from "react-bootstrap-icons";

function CopyedCode({ children, copyed }) {
  const [copyState, setCopyState] = useState(false);
  useEffect(() => {
    if (!copyState) {
      return;
    }
    const interval = setInterval(() => {
      setCopyState(false);
    }, 2000);
    return () => clearInterval(interval);
  }, [copyState]);

  const clickToCopy = () => {
    navigator.clipboard.writeText(copyed);
    setCopyState(true);
  };

  const ClipboardState = () => {
    if (copyState)
      return (
        <span className="text-success">
          Copyed
          <ClipboardCheck />
        </span>
      );
    return null;
  };

  return (
    <code onClick={() => clickToCopy()}>
      {children} <ClipboardState />
    </code>
  );
}

export default CopyedCode;
