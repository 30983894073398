import { useSelector } from "react-redux";
export const useShort = () => {
  const short = useSelector((state) => state.auth.user?.short ?? "WHO");
  return short;
};

export const useId = () => {
  const id = useSelector((state) => state.auth.user?.id ?? "WHO");
  return id;
};

// const ROLE_ADS = "ROLE_ADS";
const ROLE_ADMIN = "ROLE_ADMIN";
// const ROLE_OPERATORS = "ROLE_OPERATORS";

export const useRole = (role) => {
  const roles = useSelector((state) => state.auth?.user?.roles || []);

  return roles.includes(ROLE_ADMIN) || roles.includes(role);
};
