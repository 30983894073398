import React from "react";

const Checkbox = (props) => {
  return (
    <div className="col-12">
      <div className="form-check">
        <input
          className="form-check-input"
          checked={props.value}
          onChange={(e) => {
            props.onChange(e.target.checked);
          }}
          type="checkbox"
        />
        <label className="form-check-label">{props.children}</label>
      </div>
    </div>
  );
};
export default Checkbox;
