import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateLayerGroupMutation, useUpdateLayerMutation } from "api/neku/layers";

const UpdateName = ({ layer, type }) => {
  const { tid } = useParams();
  const [state, setState] = useState(layer.name);
  const [update] = useUpdateLayerMutation();
  const [updateGroup] = useUpdateLayerGroupMutation();
  const handleUpdateName = () => {
    if (!state || state === layer.name) {
      return;
    }
    if(type == 'layerGroup') {
      updateGroup({
        id: layer.id,
        body: { tid, key: "name", value: state },
      }).unwrap();
      return
    }
    update({
      id: layer.id,
      body: { tid, key: "name", value: state },
    }).unwrap();
  };
  useEffect(() => {
    if(layer?.name) {
      setState(layer?.name)
    }
  },[layer?.name])
  return (
    <input
      type="text"
      value={state}
      placeholder="Layer name"
      className="name"
      onChange={(e) => setState(e.target.value)}
      onBlur={handleUpdateName}
    />
  );
};

export default UpdateName;
