//React entry point 会自动根据endpoints生成hooks
import { neku } from "./index";
import { cacher } from "api/rtkQueryCacheUtils";

//使用base URL 和endpoints 定义服务
const avatarApi = neku.injectEndpoints({
  endpoints: (builder) => ({
    getAvatar: builder.query({
      query: (id) => `/avatars/${id}`,
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("Avatar"),
    }),
    getAvatars: builder.query({
      query: (params) => ({ url: `/avatars`, method: "GET", params }),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("Avatar"),
    }),
    getWebImgs: builder.query({
      query: (params) => ({ url: `/avatars/webimgs`, method: "GET", params }),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    addAvatar: builder.mutation({
      query: (body) => ({ url: `avatars`, method: "POST", body }),
      invalidatesTags: cacher.invalidatesList("Avatar"),
    }),
    addWebImg: builder.mutation({
      query: (body) => ({ url: `avatars/webimgs`, method: "POST", body }),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    updateAvatar: builder.mutation({
      query: (params) => {
        const { id, body } = params;
        return {
          url: `avatars/${id}`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(
        { id, body },
        { dispatch, queryFulfilled, getState }
      ) {
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
    deleteAvatar: builder.mutation({
      query: (id) => ({
        url: `/avatars/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: cacher.invalidatesList("Avatar"),
    }),
    deleteWebImg: builder.mutation({
      query: (id) => ({
        url: `/avatars/webimgs/${id}`,
        method: "DELETE",
      }),
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const {
  useGetAvatarsQuery,
  useGetWebImgsQuery,
  useAddAvatarMutation,
  useAddWebImgMutation,
  useUpdateAvatarMutation,
  useDeleteAvatarMutation,
  useDeleteWebImgMutation
} = avatarApi;
export default avatarApi;

const optimisticUpdate = async (
  id,
  body,
  dispatch,
  queryFulfilled,
  getState
) => {
  const updates = [];

  updates.push(
    dispatch(
      neku.util.updateQueryData("getAvatar", id, (sticker) => {
        updateAvatar(sticker, body);
      })
    )
  );

  for (const { endpointName, originalArgs } of neku.util.selectInvalidatedBy(
    getState(),
    [{ type: "Avatar", id: id }]
  )) {
    if (endpointName === "getAvatars") {
      updates.push(
        dispatch(
          neku.util.updateQueryData(endpointName, originalArgs, (items) => {
            const item = items.find((item) => item.id === id);
            updateAvatar(item, body);
          })
        )
      );
    }
  }

  try {
    await queryFulfilled;
  } catch {
    for (const u of updates) {
      u.undo();
    }
  }
};

export const updateAvatar = (avatar, body) => {
  if (avatar) {
    const { key, value } = body;
    if (key === "state") {
      avatar.state = value;
    }else if(key === "removeTag") {
      avatar.tag = '';
    }else if(key === 'addTag') {
      avatar.tag = value;
    }else {
      avatar[key] = value;
    }
  }
};
