import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetTemplateQuery } from "api/neku/templates";
import CanvasPanel from "./CanvasPanel";
import BottomPanel from "./BottomPanel";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import Nav from "./Nav";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import { initEditor } from "./uiSlice";

const Editor = () => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const editor = useSelector((state) => state.ui.editor[tid]);
  useEffect(() => {
    if(!editor) {
      dispatch(initEditor(tid))
    }
  },[tid])
  const { data: template } = useGetTemplateQuery(tid, {
    skip: !tid,
  });

  if (!template) return null;

  return (
    <div className="d-flex flex-column editor-panel">
      <Nav />
      <div className="bg-gary d-flex flex-md-row flex-sm-column gap-1 h-100 overflow-hidden">
        <div className="left-panel bg-white d-none d-md-block">
          <LeftPanel />
        </div>
        <div className="drawer-panel bg-white text-center border-start border-end">
          <div className="d-flex flex-column h-100 overflow-hidden">
            <CanvasPanel />
            <BottomPanel />
          </div>
        </div>
        <div className="right-panel bg-white w-100 flex-grow-1 clearScroll">
          <div className="d-flex flex-column h-100 overflow-hidden">
            <RightPanel />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Editor;
