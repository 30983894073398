export const ContentLangs = [
  { code: 0, name: "All", short: "All" },
  { code: 3, name: "BR User", short: "BR" },
  { code: 4, name: "Indonesia", short: "ID" },
  { code: 5, name: "India", short: "IN" },
  { code: 6, name: "Pakistan", short: "PK" },
  { code: 7, name: "Arab", short: "EG" },
  { code: 8, name: "Africa", short: "AF" },
  { code: 9, name: "US User", short: "US" },
  { code: 10, name: "South Africa", short: "ZA" },
  { code: 11, name: "Thailand", short: "TH" },
  { code: 12, name: "Mexico", short: "MX" },
  { code: 13, name: "Nigeria", short: "NG" },
  { code: 14, name: "zh-TW", short: "TW" },
  { code: 15, name: "Singapore", short: "SG" },
  { code: 16, name: "Malaysia", short: "MY" },
  { code: 17, name: "Colombia", short: "CO" },
  { code: 18, name: "HongKong", short: "HK" },
  { code: 19, name: "Turkey", short: "TR" },
  { code: 20, name: "Japan", short: "JP" },
  { code: 21, name: "Argentina", short: "AR" },
  { code: 22, name: "Russian", short: "RU" },
  { code: 23, name: "Portugal", short: "PT" },
  { code: 24, name: "Spain", short: "ES" },
  { code: 25, name: "France", short: "FR" },
  { code: 26, name: "Germany", short: "DE" },
  { code: 27, name: "United Kingdom", short: "GB" },
  { code: 28, name: "Senegal", short: "SN" },
  { code: 29, name: "Ghana", short: "GH" },
  { code: 30, name: "Italy", short: "IT" },
  { code: 31, name: "Chile", short: "CL" },
  { code: 32, name: "Philippines", short: "PH" },
  { code: 33, name: "Canada", short: "CA" },
  { code: 34, name: "Korea", short: "KR" },
  { code: 35, name: "Australia", short: "AU" },
  { code: 36, name: "News Land", short: "NZ" },
  { code: 37, name: "Uruguay", short: "UY" },
  { code: 38, name: "Croatia", short: "HR" },
  { code: 39, name: "Poland", short: "PL" },
  { code: 40, name: "Austria", short: "AT" },
  { code: 41, name: "Netherlands", short: "NL" },
  { code: 42, name: "Belgium", short: "BE" },
  { code: 43, name: "Ireland", short: "IE" },
  { code: 44, name: "Finland", short: "FI" },
  { code: 45, name: "Isral", short: "IL" },
  { code: 46, name: "Switzerland", short: "CH" },
];

export const Covers = [
  { code: "", name: "select cover", z: 0, p: 60 },
  { code: "ak1", name: "背景底板", z: 0, p: 60 },
  { code: "IND", name: "背景装饰", z: 1, p: 59 },
  //后发
  { code: "WIz", name: "翅膀", z: 6, p: 45 },
  { code: "dEj", name: "脑后头饰", z: 2, p: 44 },
  { code: "z2b", name: "后发", z: 5, p: 15 },
  { code: "e8B", name: "后发渐变", z: 6, p: 16 },
  { code: "ppf", name: "左辫子", z: 3, p: 25 },
  { code: "ywL", name: "左辫子渐变", z: 4, p: 26 },
  { code: "Ry7", name: "左辫子装饰", z: 5, p: 27 },
  { code: "V4W", name: "右辫子", z: 3, p: 28 },
  { code: "MFj", name: "右辫子渐变", z: 4, p: 29 },
  { code: "fem", name: "右辫子装饰", z: 5, p: 30 },
  //主躯体
  { code: "UWj", name: "身体", z: 10, p: 1 },
  //衣服
  { code: "b7k", name: "颈环", z: 11, p: 23 },
  { code: "WEH", name: "内衬", z: 12, p: 17 },
  { code: "wTJ", name: "领带", z: 13, p: 19 },
  { code: "Rv4", name: "外套", z: 14, p: 20 },
  { code: "PVt", name: "套装", z: 15, p: 21 },
  { code: "W0R", name: "领结", z: 16, p: 23 },
  { code: "b9e", name: "围巾", z: 16, p: 24 },
  { code: "pgD", name: "衣服装饰", z: 17, p: 22 },
  //脸部
  { code: "EGm", name: "嘴", z: 20, p: 2 },
  { code: "rJj", name: "鼻子", z: 20, p: 3 },
  { code: "hLN", name: "眉毛", z: 20, p: 4 },
  { code: "ktI", name: "左眼", z: 20, p: 5 },
  { code: "IbL", name: "左眼眼眶", z: 21, p: 7 },
  { code: "Lwg", name: "左眼瞳孔", z: 22, p: 9 },
  { code: "QI2", name: "左眼高光", z: 23, p: 11 },
  { code: "SMD", name: "右眼", z: 20, p: 6 },
  { code: "Bw6", name: "右眼眼眶", z: 21, p: 8 },
  { code: "ODK", name: "右眼瞳孔", z: 22, p: 10 },
  { code: "OUL", name: "右眼高光", z: 23, p: 12 },
  { code: "yDQ", name: "耳朵", z: 27, p: 46 },
  { code: "aMS", name: "左耳环", z: 28, p: 47 },
  { code: "Uwt", name: "右耳环", z: 28, p: 48 },
  { code: "tV6", name: "脸部装饰", z: 28, p: 49 },
  { code: "Vy5", name: "伤", z: 28, p: 50 },
  { code: "k2v", name: "痣", z: 28, p: 51 },
  { code: "XGR", name: "血迹", z: 28, p: 52 },
  //前发
  { code: "cp9", name: "刘海", z: 32, p: 13 },
  { code: "geJ", name: "刘海渐变", z: 33, p: 14 },
  { code: "J9H", name: "左鬓", z: 30, p: 32 },
  { code: "L6Y", name: "左鬓渐变", z: 31, p: 33 },
  { code: "Ghc", name: "左鬓挑染", z: 32, p: 34 },
  { code: "t5y", name: "右鬓", z: 30, p: 35 },
  { code: "xfN", name: "右鬓渐变", z: 31, p: 36 },
  { code: "PrW", name: "右鬓挑染", z: 32, p: 37 },
  { code: "AWL", name: "两侧毛", z: 30, p: 38 },
  { code: "rge", name: "呆毛", z: 35, p: 39 },
  //发部
  { code: "Uyj", name: "角", z: 40, p: 40 },
  { code: "kaU", name: "猫耳", z: 40, p: 41 },
  { code: "vEN", name: "头饰", z: 41, p: 42 },
  { code: "pFu", name: "眼镜", z: 40, p: 43 },
  //
  { code: "HJl", name: "左手", z: 42, p: 53 },
  { code: "sWF", name: "右手", z: 42, p: 54 },
  { code: "a2h", name: "对话框", z: 50, p: 55 },
  { code: "IpH", name: "前景装饰", z: 60, p: 56 },
  { code: "FMa", name: "前景框", z: 61, p: 57 },
  { code: "sjU", name: "材质滤镜", z: 70, p: 58 },
];

export const Tags = [
  "Beautiful girl",
  "Sideways",
  "Western face",
  "Funny",
  "QQ",
  "Kid",
  "Handsome boy",
  "Abstract",
  "Pro",
  "Animal",
  "HALLOWEEN",
  "Christmas",
  "Valentine",
  "Easter Day"
];

export const DlcTags = [
  "HALLOWEEN",
  "Christmas",
  "Summer",
  "Harry Potter",
  "Easter",
  "Valentine's Day",
  "Speical IP",
  "Others"
];

export const DlcTagOptions = DlcTags.map(item => {
  return {code: item, name: item}
})

export const TagOptions = Tags.map(item => {
  return {code: item, name: item}
})
