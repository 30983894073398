import React from "react";
import { useNavigate } from "react-router-dom";
import { useCreateTemplateMutation } from "api/neku/templates";
import { Modal, Button } from "react-bootstrap";
import { Form, Formik, useField, useFormikContext } from "formik";
import { useSelector } from "react-redux";
import { useRole } from "hooks/auth";
import styles from "./index.module.scss";
import * as Yup from "yup";
import classNames from "classnames";

const CreatePage = () => {
  const face = useSelector((state) => state.auth.face);
  const isAdmin = useRole(`admin`);

  const navigate = useNavigate();
  const handleClose = () => {
    navigate(-1);
  };
  const [create] = useCreateTemplateMutation();

  const CreateSchema = Yup.object().shape({
    name: Yup.string()
      .min(1, "Too Short!")
      .max(50, "Too Long!")
      .required("Please choose a name."),
    intro: Yup.string().min(1, "Too Short!").max(50, "Too Long!"),
    ow: Yup.number()
      .typeError("with must be a number")
      .min(512, "Width must be >= 512")
      .max(3072, "Width must be <= 3072")
      .required("Required"),
  });

  return (
    <Modal show={true} onHide={handleClose}>
      <Modal.Header className="py-2" closeButton>
        <Modal.Title>Create Template</Modal.Title>
      </Modal.Header>
      <Modal.Body className="px-4">
        <Formik
          initialValues={{
            name: "",
            intro: "",
            ow: 1024,
            oh: 1024,
            publisher: face.nick_name,
            author: face.id,
          }}
          validationSchema={CreateSchema}
          onSubmit={(values, actions) => {
            create({ ...values })
              .unwrap()
              .then((res) => {
                navigate(`/editor/` + res.id, { replace: true });
                actions.setSubmitting(false);
              });
          }}
        >
          <Form>
            <MyTextField
              name="name"
              label="Name"
              placeholder="Please choose a name."
            />
            <MyTextArea
              name="intro"
              label="Description"
              placeholder="Please enter a description"
            />

            <CanvasWidth />
            {isAdmin && (
              <code>
                designer:{face?.nick_name}-{face?.id}
              </code>
            )}
            <SubmitButton />
          </Form>
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export const SubmitButton = () => {
  const { submitForm, errors } = useFormikContext();
  return (
    <div className="d-grid col-12 mt-1">
      <Button
        disabled={Object.entries(errors).length > 0}
        onClick={submitForm}
        variant={"outline-success"}
      >
        Submit
      </Button>
    </div>
  );
};

const MyTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const style = classNames(
    styles.input,
    "form-control",
    {
      "is-invalid": meta.touched && meta.error,
    },
    { "is-valid": meta.touched && !meta.error }
  );
  return (
    <div className={styles.formField}>
      <div className={styles.label + " " + styles.fieldRequire}>{label}</div>
      <input type="input" className={style} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
      {meta.touched && !meta.error ? (
        <div className="valid-feedback">Looks good!</div>
      ) : null}
    </div>
  );
};

const MyTextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const style = classNames(
    styles.textarea,
    "form-control",
    {
      "is-invalid": meta.touched && meta.error,
    }
    // { "is-valid": meta.touched && !meta.error }
  );
  return (
    <div className={styles.formField}>
      <div className={styles.label}>{label}</div>
      <textarea
        placeholder="Please enter a description"
        className={style}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

const CanvasWidth = () => {
  const [field, meta] = useField({name: 'ow'});
  const [field2, meta2] = useField({name: 'oh'});

  const owStyle = classNames(
    styles.input,
    "w-25 me-3",
    {
      "is-invalid": meta.touched && meta.error,
    },
    { "is-valid": meta.touched && !meta.error }
  );
  const osStyle = classNames(
    styles.input,
    "w-25 ms-3",
    {
      "is-invalid": meta2.touched && meta2.error,
    },
    { "is-valid": meta2.touched && !meta2.error }
  );
  return (
    <div className={styles.formSize}>
      <div className={styles.label + " " + styles.fieldRequire}>
        Canvas Size
      </div>
      <input
        type="number"
        name="ow"
        className={owStyle}
        {...field}
      />
      X
      <input type="number" className={osStyle} {...field2} />
      {meta.touched && meta.error && meta2.touched && meta2.error ? (
        <div className="invalid-feedback">{meta.error || meta2.error}</div>
      ) : null}
      {meta.touched && !meta.error && meta2.touched && !meta2.error ? (
        <div className="valid-feedback">Looks good!</div>
      ) : null}
    </div>
  );
};

export default CreatePage;
