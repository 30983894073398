import React from "react";
import { useParams } from "react-router-dom";
import { useUpdateLayerMutation } from "api/neku/layers";
import "./index.scss";

const UpdatBodyMark = ({ layer }) => {
  const { tid } = useParams();
  const [update] = useUpdateLayerMutation();

  const handleChange = (value) => {
    console.log(value);
    update({
      id: layer.id,
      body: { tid, key: "bodymark", value: value ? 1 : 0 },
    });
  };

  return (
    <span className="waterMark">
      身体:
      <input
        className={`form-check-input ms-1 rounded-circle layerCheck`}
        checked={layer.bodymark}
        onChange={(e) => {
          handleChange(e.target.checked);
        }}
        type="checkbox"
        style={{
          backgroundColor: layer.bodymark === 1 && "#FF3B63",
        }}
      />
    </span>
  );
};

export default UpdatBodyMark;
