import React, { useEffect, useRef,useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { selectLayer,setLayerScroll } from "../../uiSlice";
import classNames from "classnames";
import { useParams } from "react-router-dom";

const LayerPicker = ({ layers }) => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const scrollCur = useSelector((state) => state.ui.editor[tid].layerScroll);
  const scrollRef = useRef();
  const onPreScroll = () => {
    scrollRef.current.scrollLeft = scrollRef.current.scrollLeft - 390;
    dispatch(setLayerScroll({layerScroll:scrollRef.current.scrollLeft, tid}));
  };
  const onNextScroll = () => {
    scrollRef.current.scrollLeft = scrollRef.current.scrollLeft + 390;
    dispatch(setLayerScroll({layerScroll:scrollRef.current.scrollLeft, tid}));
  };

  useEffect(() => {
    scrollRef.current.scrollLeft = scrollCur
  },[])

  return (
    <div className="flex-center justify-content-between w-100 p-1 mb-2 shadow-sm">
      <div className="mx-2 flex-center cursor-pointer" onClick={onPreScroll}>
        <ChevronLeft width={25} height={25} />
      </div>
      <div className="d-flex overflow-auto clearScroll" ref={scrollRef}>
        {layers?.map((layer) => (
          <Layer key={layer.id} layer={layer} />
        ))}
      </div>
      <div className="mx-2 flex-center cursor-pointer" onClick={onNextScroll}>
        <ChevronRight width={25} height={25} />
      </div>
    </div>
  );
};

const Layer = ({ layer }) => {
  const {tid} = useParams();
  const dispatch = useDispatch();
  const selected = useSelector((state) => state.ui.editor[tid].lid === layer.id);
  const inDrawer = useSelector((state) =>
    state.ui.editor[tid].drawers.some((drawer) => drawer.lid === layer.id)
  );

  const style = classNames(
    "mx-1",
    "my-1",
    "layer-icon",
    {
      rounded: selected,
    },
    { active: selected }
  );

  const handleClick = () => {
    dispatch(selectLayer({lid: layer.id, tid}));
  };

  return (
    <div className="me-2 cursor-pointer layer-picker">
      <div className={style} onClick={handleClick}>
        <img
          className="w-100 h-100 rounded"
          src={`https://img.zthd.io/an1/acs/${layer.cover}`}
          alt="..."
        />
      </div>
      <div className="mt-1" style={{ height: 5 }}>
        {inDrawer && (
          <div className="w-25 h-100 bg-warning rounded m-auto"></div>
        )}
      </div>
    </div>
  );
};

export default LayerPicker;
