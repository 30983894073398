import React, { useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import { Modal, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useUpdateComponentMutation } from "api/neku/components";
import cptsAPi from "api/neku/components";
import cmptSwitchStyle from "assets/edit_cmpt_pick.svg";
import cmptRemoveStyle from "assets/edit_cmpt_remove.svg";
import { useChildCpts, useCustomParams } from "screens/Editor/hooks";

export const useParents = (cpt) => {
  const { tid } = useParams();
  const params = useCustomParams();
  const lid = useSelector((state) => state.ui.editor[tid].lid);

  const filterIt = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) =>
        data?.filter(
          (c) =>
            cpt.state >= 0 &&
            c.lid === lid &&
            c.dlc === cpt.dlc &&
            !c.pid &&
            c.id !== cpt.id
        ) ?? emptyArray
    );
  }, [lid, cpt]);

  return cptsAPi.endpoints.getComponents.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      cpts: filterIt(result),
    }),
  });
};
function BindStyle({ cpt }) {
  const [show, setShow] = useState(false);
  const { cpts: parents } = useParents(cpt);

  const { cpts: childs } = useChildCpts(cpt);

  const [update] = useUpdateComponentMutation();
  const { tid } = useParams();
  const removeStyle = () => {
    update({ id: cpt.id, body: { tid, key: "pid", value: "" } }).unwrap();
  };

  //没可选父
  if (parents.length === 0) {
    return null;
  }
  //有子
  if (childs.length > 0) {
    return null;
  }

  if (cpt.pid) {
    return <img src={cmptRemoveStyle} alt="" onClick={removeStyle} />;
  }

  return (
    <>
      <img src={cmptSwitchStyle} alt="" onClick={() => setShow(!show)} />
      {show && (
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Pick parent component</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div
              className="mb-3 d-flex align-items-center justify-content-center cursor-pointer"
              style={{
                width: 100,
                height: 100,
                background: "#F2F2F7",
                // outline: inDrawer ? "2px solid #FB85AB" : "",
                borderRadius: 16,
              }}
            >
              <img
                style={{ maxWidth: "80%", maxHeight: "80%", borderRadius: 13 }}
                src={`https://img.zthd.io/an1/ats/${cpt.id}`}
                alt=""
              />
            </div>
            <div className="card-group justify-content-between child-components-card-group pt-3 border-top">
              {parents.map((c) => (
                <Item key={c.id} curr={c} cid={cpt.id} />
              ))}
              {parents.slice(0, 4 - (parents.length % 4)).map((c) => (
                <div key={c.id} style={{ width: 100, height: 100 }} />
              ))}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

const Item = ({ curr, cid }) => {
  const childs = useChildCpts(curr);
  const [update, { isLoading }] = useUpdateComponentMutation();
  const addToParent = () => {
    const data = {
      id: cid,
      body: {
        key: "pid",
        value: curr.id,
      },
    };
    update(data).unwrap();
  };

  return (
    <div className="me-2">
      <div
        className="img-box position-relative cursor-pointer d-flex align-items-center justify-content-center"
        style={{
          width: 100,
          height: 100,
          background: "#F2F2F7",
          // outline: inDrawer ? "2px solid #FB85AB" : "",
          borderRadius: 16,
          marginBottom: 16,
        }}
        onClick={addToParent}
      >
        <img
          src={`https://img.zthd.io/an1/ats/${curr.id}`}
          alt=""
          style={{ maxWidth: "80%", maxHeight: "80%", borderRadius: 13 }}
        />
        <div>
          {childs.length > 0 && (
            <span
              className="rounded-circle bg-white border border-1"
              style={{
                position: "absolute",
                zIndex: 1,
                top: 6,
                right: 6,
                width: 24,
                height: 24,
                fontSize: 14,
                textAlign: "center",
                // lineHeight: 24,
              }}
            >
              {childs.length + 1}
            </span>
          )}
        </div>

        {isLoading && (
          <div
            className="position-absolute w-100 h-100 d-flex align-items-center justify-content-center"
            style={{
              left: 0,
              top: 0,
              background: "rgba(0,0,0,0.5)",
              borderRadius: 16,
            }}
          >
            <Spinner
              animation="border"
              role="status"
              style={{ width: 23, height: 23, color: "#FFF" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BindStyle;
