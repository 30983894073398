import React from "react";
import PickerColor from "./PickerColor";
import ToningPicker from "./ToningPicker";
import { useTonings } from "screens/Editor/hooks";

const ToningPanel = () => {
  const { data: tonings, isFetching } = useTonings();

  if (isFetching)
    return (
      <div
        className="spinner-border m-auto"
        style={{
          color: "#FF3B63",
          position: "relative",
          top: -35,
          width: 50,
          height: 50,
          borderWidth: 6,
        }}
        role="status"
      />
    );

  return (
    <>
      <ToningPicker tonings={tonings} />
      <div className="d-flex flex-column overflow-auto" style={{ flex: "1" }}>
        <div className="d-flex flex-column gap-1 position-relative">
          <PickerColor />
        </div>
      </div>
    </>
  );
};

export default ToningPanel;
