//React entry point 会自动根据endpoints生成hooks
import { cacher } from "api/rtkQueryCacheUtils";
import { neku } from "./index";

const updateFn = (draft, body) => {
  const { key, value } = body;
  if (draft) {
    if (["pro", "iOSPro", "price", "pid", "lid", "dlc"].includes(key)) {
      draft[key] = value;
    } else {
      draft[key] = value;
    }
  }
};

const optimisticUpdate = async (
  id,
  body,
  dispatch,
  queryFulfilled,
  getState
) => {
  const updates = [];

  for (const {
    endpointName,
    originalArgs,
  } of componentsApi.util.selectInvalidatedBy(getState(), [
    { type: "Component", id: id },
  ])) {
    if (endpointName === "getComponents") {
      updates.push(
        dispatch(
          componentsApi.util.updateQueryData(
            endpointName,
            originalArgs,
            (drafts) => {
              const component = drafts.find((c) => c.id === id);
              updateFn(component, body);
            }
          )
        )
      );
    }
  }

  try {
    await queryFulfilled;
  } catch {
    for (const u of updates) {
      u.undo();
    }
  }
};

//使用base URL 和endpoints 定义服务
const componentsApi = neku.injectEndpoints({
  endpoints: (builder) => ({
    getComponents: builder.query({
      query: (params) => ({
        url: `components`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("Component"),
    }),
    addComponent: builder.mutation({
      query: (body) => ({
        url: `components`,
        method: "POST",
        body,
      }),
      extraOptions: { maxRetries: 5 },
      invalidatesTags: cacher.invalidatesList("Component"),
    }),
    addComponentCover: builder.mutation({
      query: (body) => ({
        url: `components/cover`,
        method: "POST",
        body,
      }),
      extraOptions: { maxRetries: 5 },
    }),
    updateComponent: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return {
          url: `components/${id}`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(
        { id, body },
        { dispatch, queryFulfilled, getState }
      ) {
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
    deleteComponent: builder.mutation({
      query: (id) => ({
        url: `components/${id}`,
        method: "DELETE",
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        const body = { key: "state", value: -1 };
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const {
  useGetComponentsQuery,
  useAddComponentMutation,
  useAddComponentCoverMutation,
  useUpdateComponentMutation,
  useDeleteComponentMutation,
} = componentsApi;
export default componentsApi;
