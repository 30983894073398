import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateDlcMutation } from "api/neku/dlcs";

const Name = ({ dlc }) => {
  const { tid } = useParams();
  const [name, setName] = useState(dlc.name);

  const [update, { isFetching }] = useUpdateDlcMutation();

  const cannotSave = !name || name === "" || isFetching;
  const handleUpdate = () => {
    if (cannotSave) {
      setName(dlc.name);
      return;
    }
    if (name === dlc.name) {
      return;
    }
    update({
      id: dlc.id,
      body: { tid, key: "name", value: name },
    }).unwrap();
  };

  return (
    <input
      type="text"
      value={name}
      placeholder="Dlc name"
      className="dlc-name"
      onChange={(e) => setName(e.target.value)}
      onBlur={handleUpdate}
    />
  );
};

export default Name;
