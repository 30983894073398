//React entry point 会自动根据endpoints生成hooks
import { cacher } from "api/rtkQueryCacheUtils";
import { utils } from "api/rtkQueryUtils";
import { neku } from "./index";

const updateFn = (template, body) => {
  const { key, value } = body;
  if (template) {
    if (key === "cover") {
      const coverData = JSON.parse(value);
      template.cover = coverData.cover;
      if(coverData.initDataId) {
        template.initDataId = coverData.initDataId;
      }
    } else if (["ow", "oh"].includes(key)) {
      template.ow = Number(value);
      template.oh = Number(value);
    } else {
      template[key] = value;
    }
  }
};

const optimisticUpdate = async (
  id,
  body,
  dispatch,
  queryFulfilled,
  getState
) => {
  const updates = [];

  updates.push(
    dispatch(
      api.util.updateQueryData("getTemplate", id, (dlc) => {
        updateFn(dlc, body);
      })
    )
  );

  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    getState(),
    [{ type: "Template", id: id }]
  )) {
    if (endpointName === "getTemplates") {
      updates.push(
        dispatch(
          api.util.updateQueryData(endpointName, originalArgs, (ts) => {
            const t = ts.find((t) => t.id === id);
            updateFn(t, body);
          })
        )
      );
    }
  }

  try {
    await queryFulfilled;
  } catch {
    for (const u of updates) {
      u.undo();
    }
  }
};

//使用base URL 和endpoints 定义服务
const api = neku.injectEndpoints({
  endpoints: (builder) => ({
    getTemplates: builder.query({
      query: (params) => ({ url: "templates", method: "GET", params }),
      keepUnusedDataFor: 15 * 60,
      providesTags: cacher.providesList("Template"),
    }),
    clearTemplate: builder.mutation({
      query: (id) => ({ url: `templates/clear/${id}`, method: "POST" }),
      keepUnusedDataFor: 15 * 60,
      providesTags: cacher.providesList("Template"),
    }),
    getTemplate: builder.query({
      query: (id) => `/templates/${id}`,
      keepUnusedDataFor: 15 * 60, //
      providesTags: cacher.cacheByIdArg("Template"),
    }),
    createTemplate: builder.mutation({
      query: (body) => ({ url: "templates", method: "POST", body }),
      invalidatesTags: cacher.invalidatesList("Template"),
    }),
    updateTemplate: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return utils.PUT(`templates/${id}`, body);
      },
      async onQueryStarted(
        { id, body },
        { dispatch, queryFulfilled, getState }
      ) {
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
    deleteTemplate: builder.mutation({
      query: (id) => ({ url: `templates/${id}`, method: "DELETE" }),
    }),
    publish: builder.mutation({
      query: ({ id, body }) => ({
        url: `templates/${id}/publish/online`,
        method: "POST",
        body,
      }),
    }),
    publishDraft: builder.mutation({
      query: (id) => ({
        url: `templates/${id}/publish/draft`,
        method: "POST",
      }),
      invalidatesTags: cacher.cacheByIdArg("Template"),
    }),
    publishDlcDraft: builder.mutation({
      query: ({ id, body }) => ({
        url: `templates/${id}/publish/draft`,
        method: "POST",
        body,
      }),
      invalidatesTags: cacher.invalidatesList("Dlc"),
    }),
    publishDlc: builder.mutation({
      query: ({ id, body }) => ({
        url: `templates/${id}/publish/online`,
        method: "POST",
        body,
      }),
    }),
    uploadSketch: builder.mutation({
      query: (body) => ({ url: "templates/sketch/upload", method: "POST", body }),
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const {
  useGetTemplatesQuery,
  useClearTemplateMutation,
  useGetTemplateQuery,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  useDeleteTemplateMutation,
  usePublishDraftMutation,
  usePublishMutation,
  usePublishDlcDraftMutation,
  usePublishDlcMutation,
  useUploadSketchMutation
} = api;
export default api;
