import React from "react";
import { useSelector } from "react-redux";
import { Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";

const MaxTips = ({ layer }) => {
  const { tid } = useParams();
  const count = useSelector(
    (state) =>
      state.ui.editor[tid].drawers.filter((drawer) => drawer.lid === layer?.id)
        .length
  );

  if (!layer) {
    return <>no layer?</>;
  }

  if (!layer.opt && count === 0) {
    return (
      <Badge
        pill
        bg=""
        style={{
          background: "#FFD3D9",
          color: "#333333",
          position: "relative",
          padding: "5px 12px",
        }}
      >
        {count}/{layer.max}
      </Badge>
    );
  }

  // if (count > 0) {
  //   return (
  //     <Badge
  //       pill
  //       bg=""
  //       style={{
  //         background: "#FFD3D9",
  //         color: "#333333",
  //         padding: "5px 12px",
  //       }}
  //     >
  //       {count}/{layer.max}
  //     </Badge>
  //   );
  // }

  return (
    <Badge
      pill
      bg="white"
      style={{
        border: "1px solid #CCCCCC",
        color: "#333333",
        padding: "5px 12px",
      }}
    >
      {count}/{layer.max}
    </Badge>
  );
};
export default MaxTips;
