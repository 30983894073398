import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Modal, Image } from "react-bootstrap";
import edit_newlayer_add from "assets/edit_newlayer_add.svg";
import {
  useUpdateLayerMutation,
  useUpdateLayerGroupMutation,
} from "api/neku/layers";
import {
  useGetLayerIconsQuery,
  useCreateLayerIconsMutation,
} from "api/neku/layersicons";
import { makeShortId } from "commons";
import { Card } from "react-bootstrap";

const CardWrapper = ({ children }) => {
  return (
    <div className="col">
      <Card className="text-center h-100 border-0">{children}</Card>
    </div>
  );
};

const UpdateCover = ({ layer, type, refetch }) => {
  const [show, setShow] = useState(false);
  return (
    <>
      <img
        onClick={() => setShow(true)}
        width={64}
        height={64}
        src={`https://img.zthd.io/an1/acs/${layer.cover}`}
        alt="..."
      />
      {show && (
        <ShowCovers
          type={type}
          setShow={setShow}
          layer={layer}
          refetch={refetch}
        />
      )}
    </>
  );
};

const ShowCovers = ({ setShow, layer, type, refetch }) => {
  const [keyword, setKeyWord] = useState("");
  const userId = useSelector((state) => state.auth.user?.id ?? null);
  const { data } = useGetLayerIconsQuery({ author: `admin` });
  const { data: users } = useGetLayerIconsQuery(
    { author: userId },
    {
      skip: !userId,
    }
  );

  if (!data || !users) return null;

  return (
    <Modal show={true} size="lg" onHide={setShow}>
      <Modal.Header closeButton>
        <Modal.Title>
          <span>Update Cover</span>
          <input
            type="input"
            className="input p-2 mx-4"
            name="name"
            placeholder="Please enter icon name"
            value={keyword}
            onChange={(e) => setKeyWord(e.target.value)}
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PrivateIcons
          layer={layer}
          keyword={keyword}
          type={type}
          refetch={refetch}
        />
        <QuickSelection
          layer={layer}
          keyword={keyword}
          type={type}
          refetch={refetch}
        />
      </Modal.Body>
    </Modal>
  );
};

const PrivateIcons = ({ layer, keyword, refetch, type }) => {
  const userId = useSelector((state) => state.auth.user?.id ?? null);
  const { data } = useGetLayerIconsQuery(
    { author: userId },
    {
      skip: !userId,
    }
  );
  const icons = useMemo(() => {
    if (keyword === "") {
      return data;
    } else {
      return data.filter((item) => item.name.includes(keyword));
    }
  }, [data, keyword]);
  return (
    <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 gy-1 row-cols-xxl-5 ">
      {icons?.map((icon) => (
        <IconCard
          key={icon.id}
          icon={icon}
          layer={layer}
          showName={false}
          type={type}
          refetch={refetch}
        />
      ))}
      <UploadNewIcon />
    </div>
  );
};

const QuickSelection = ({ layer, keyword, type, refetch }) => {
  const { data } = useGetLayerIconsQuery({ author: `admin` });
  const icons = useMemo(() => {
    if (keyword === "") {
      return data;
    } else {
      return data.filter((item) => item.name.includes(keyword));
    }
  }, [data, keyword]);
  return (
    <div>
      <p className="h6 fw-bold">Quick Selection</p>
      <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 gy-1 row-cols-xxl-5 ">
        {icons?.map((icon, index) => (
          <IconCard
            key={index}
            icon={icon}
            layer={layer}
            type={type}
            refetch={refetch}
          />
        ))}
      </div>
    </div>
  );
};

const UploadNewIcon = () => {
  const [icon, setIcon] = useState(null);
  const [isDrag, setIsDrag] = useState(false);

  const onChange = (e) => {
    if (e.target.files[0] === undefined) {
      return null;
    }
    const reader = new FileReader();
    reader.onload = (res) => {
      var img = document.createElement("img");
      img.onload = () => {
        setIsDrag(false);
        setIcon({
          original: res.target.result,
          w: img.width,
          h: img.height,
        });
      };
      img.src = res.target.result;
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    setIsDrag(true);
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    setIsDrag(false);
  };

  if (!icon) {
    return (
      <CardWrapper>
        <div
          className="flex-center justify-content-center position-relative"
          style={{
            width: 126,
            height: 126,
            background: isDrag ? "#FFECF1" : "#F2F2F7",
            outline: isDrag ? "1px dashed #FF6190" : "",
            borderRadius: 16,
            margin: "0 0 39px 0",
          }}
        >
          {isDrag ? (
            <div style={{ color: "#FF557B" }}>Drop it here</div>
          ) : (
            <img
              style={{ width: 38, height: 38 }}
              src={edit_newlayer_add}
              alt=""
            />
          )}

          <input
            // className="form-control"
            style={{
              width: 126,
              height: 126,
              position: "absolute",
              left: "0",
              top: "0",
              opacity: 0,
              cursor: "pointer",
            }}
            accept=".jpg, .png, .jpeg, .webp, .gif, .bmp"
            type="file"
            onChange={onChange}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
          />
        </div>
      </CardWrapper>
    );
  }

  if (icon.w !== 256 || icon.h !== 256) {
    return (
      <CardWrapper>
        <div
          className="flex-center justify-content-center position-relative text-center"
          style={{
            width: 126,
            height: 126,
            border: "2px dashed #CCCCCC",
            borderRadius: 16,
            margin: "0 0 39px 0",
          }}
        >
          <div>
            reruire size
            <div>256 x 256</div>
            <Button
              className="mt-1"
              variant="outline-danger"
              onClick={() => setIcon(null)}
            >
              Remove
            </Button>
          </div>
        </div>
      </CardWrapper>
    );
  }

  return (
    <CardWrapper>
      <SelectFileResult
        fileImg={icon}
        isDrag={isDrag}
        reselect={() => setIcon(null)}
      />
    </CardWrapper>
  );
};

const SelectFileResult = ({ fileImg, reselect }) => {
  const userId = useSelector((state) => state.auth.user?.id ?? null);

  const [create, { isLoading }] = useCreateLayerIconsMutation();

  const handleCreate = () => {
    create({
      id: makeShortId(3),
      original: fileImg?.original,
      author: userId,
    })
      .unwrap()
      .then((res) => reselect());
  };

  return (
    <div
      className="flex-center justify-content-center position-relative"
      style={{
        width: 126,
        height: 126,
        borderRadius: 16,
        margin: "0 0 39px 0",
      }}
    >
      <Image
        alt=""
        src={fileImg?.original}
        style={{ width: "100%", height: "100%", borderRadius: 16 }}
      />

      <div className="card-mask flex-center justify-content-center">
        {isLoading ? (
          <span
            className="spinner-border spinner-border-lg text-white"
            role="status"
            aria-hidden="true"
          />
        ) : (
          <div>
            <Button
              className="mt-1"
              variant="outline-danger"
              onClick={handleCreate}
            >
              Upload
            </Button>
            <Button
              className="mt-1"
              variant="outline-success"
              onClick={reselect}
            >
              Reselect
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const IconCard = ({ layer, icon, showName = true, type, refetch }) => {
  const { tid } = useParams();
  const [updateCover, { isLoading: layerLoadding }] = useUpdateLayerMutation();
  const [updateGroupCover, { isLoading: layerGroupLoadding }] =
    useUpdateLayerGroupMutation();

  const handleClick = () => {
    if (layer?.cover === icon?.code) {
      return null;
    }
    if (type === "layerGroup") {
      updateGroupCover({
        id: layer.id,
        body: { tid, key: "initCover", value: icon },
      }).then((res) => {
        refetch();
      });
      return;
    }
    updateCover({ id: layer.id, body: { tid, key: "initCover", value: icon } });
  };

  return (
    <div
      className="text-center cursor-pointer"
      key={icon.code}
      onClick={handleClick}
    >
      <div
        className="flex-center justify-content-center"
        style={{
          width: 126,
          height: 126,
          position: "relative",
          background: "#F2F2F7",
          overflow: "hidden",
          outline: layer?.cover === icon?.code ? "2px solid #FB85AB" : "",
          borderRadius: 16,
          margin: "0 auto",
        }}
      >
        <img
          style={{ width: "90%", height: "90%", borderRadius: 10 }}
          alt="."
          target="_blank"
          src={`https://img.zthd.io/an1/acs/${icon.code}`}
        />
        {(layerLoadding || layerGroupLoadding) && (
          <div className="card-mask flex-center justify-content-center">
            <span
              className="spinner-border spinner-border-lg text-white"
              role="status"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {showName && (
        <div className="pt-1 pb-2">
          <h6>{icon.name}</h6>
        </div>
      )}
    </div>
  );
};

export default UpdateCover;
