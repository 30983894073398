//React entry point 会自动根据endpoints生成hooks
import { cacher } from "api/rtkQueryCacheUtils";
import { neku } from "./index";

const updateFn = (dlc, body) => {
  const { key, value } = body;
  if (dlc) {
    if (key === "maxChoose") {
      dlc.max = value;
    } else if (key === "zIndex") {
      dlc.z = value;
    } else if (key === "optional") {
      dlc.opt = value;
    } else if (key === "removeTag") {
      dlc.tags = dlc.tags.filter((tag) => tag !== value);
    } else if (key === "addTag") {
      if (!dlc.tags) {
        dlc.tags = [value];
      } else if (dlc.tags.includes(value)) {
      } else {
        dlc.tags.push(value);
      }
    } else {
      dlc[key] = value;
    }
  }
};

const optimisticUpdate = async (
  id,
  body,
  dispatch,
  queryFulfilled,
  getState
) => {
  const updates = [];
  for (const { endpointName, originalArgs } of dlcsApi.util.selectInvalidatedBy(
    getState(),
    [{ type: "Dlc", id: id }]
  )) {
    if (endpointName === "getDlcs") {
      updates.push(
        dispatch(
          dlcsApi.util.updateQueryData(endpointName, originalArgs, (dlcs) => {
            const dlc = dlcs.find((dlc) => dlc.id === id);
            updateFn(dlc, body);
          })
        )
      );
    }
  }

  try {
    await queryFulfilled;
  } catch {
    for (const u of updates) {
      u.undo();
    }
  }
};

//使用base URL 和endpoints 定义服务
const dlcsApi = neku.injectEndpoints({
  endpoints: (builder) => ({
    getDlcs: builder.query({
      query: (params) => ({ url: `/dlcs`, method: "GET", params }),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("Dlc"),
    }),
    createDlc: builder.mutation({
      query: (body) => ({ url: `/dlcs`, method: "POST", body }),
      invalidatesTags: cacher.invalidatesList("Dlc"),
    }),
    updateDlc: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return { url: `/dlcs/${id}`, method: "PUT", body };
      },
      async onQueryStarted(
        { id, body },
        { dispatch, queryFulfilled, getState }
      ) {
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
    deleteDlc: builder.mutation({
      query: ({ id, tid }) => ({
        url: `/dlcs/${id}`,
        method: "DELETE",
        body: { tid },
      }),
      invalidatesTags: cacher.invalidatesList("Dlc"),
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const {
  useGetDlcsQuery,
  useCreateDlcMutation,
  useUpdateDlcMutation,
  useDeleteDlcMutation,
} = dlcsApi;
export default dlcsApi;
