import React from "react";

const FormRow = (props) => {
  return (
    <div className="col-md-6">
      <label className="form-label">{props.children}</label>
      <input
        value={props.value}
        type="text"
        className="form-control"
        onChange={(e) => props.onChange(e.target.value)}
      />
    </div>
  );
};
export default FormRow;
