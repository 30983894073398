import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useCapture, useTemplate } from '../hooks'
// import tagAdd from "assets/edit_tag_add.svg";
import {
  useUpdateTemplateMutation,
  useUploadSketchMutation,
} from 'api/neku/templates'
import { captureIt } from '../uiSlice'
import {
  ArrowClockwise,
  CloudUpload,
  ArrowLeftRight,
} from 'react-bootstrap-icons'
import './index.scss'
import { Form } from 'react-bootstrap'
import { useRole } from 'hooks/auth'

const LeftPanel = () => {
  const { data: template } = useTemplate()
  if (!template) return null
  return (
    <Form>
      <div className="leftPanel m-2">
        <CaptureCover template={template} />
        <Name template={template} />
        <Intro template={template} />
        <OnlinePublisher template={template} />
        {/* <MyTextTags name="tags" label="Tags" /> */}
      </div>
    </Form>
  )
}

const Name = ({ template }) => {
  const isView = useRole(`viewer`)
  const [state, setState] = useState(template?.name)
  const [update] = useUpdateTemplateMutation()
  const handleBlur = () => {
    if (!state) return
    update({ id: template.id, body: { key: 'name', value: state } })
  }
  return (
    <div className="item">
      <p className="title">Name</p>
      <input
        type="input"
        className="input"
        name="name"
        disabled={isView}
        placeholder="Please enter name"
        value={state}
        onChange={(e) => setState(e.target.value)}
        onBlur={handleBlur}
      />
    </div>
  )
}

const Intro = ({ template }) => {
  const isView = useRole(`viewer`)
  const [state, setState] = useState(template?.intro)
  const [update] = useUpdateTemplateMutation()
  const handleBlur = () => {
    if (!state) return
    update({ id: template.id, body: { key: 'intro', value: state } })
  }
  return (
    <div className={'item'}>
      <div className="title">Description</div>
      <textarea
        name="intro"
        className="textarea"
        placeholder="Please enter description"
        value={state}
        disabled={isView}
        onChange={(e) => setState(e.target.value)}
        onBlur={handleBlur}
      />
    </div>
  )
}

const OnlinePublisher = ({ template }) => {
  console.log(template)
  const isView = useRole(`viewer`)
  const [state, setState] = useState(
    template?.onlinePublisher || template?.publisher,
  )
  const [update] = useUpdateTemplateMutation()
  const handleBlur = () => {
    if (!state) return
    update({ id: template.id, body: { key: 'onlinePublisher', value: state } })
  }
  return (
    <div className={'item'}>
      <div className="title">Online Publisher</div>
      <input
        type="input"
        className="input"
        name="name"
        placeholder="Please enter online publisher"
        value={state}
        disabled={isView}
        onChange={(e) => setState(e.target.value)}
        onBlur={handleBlur}
      />
    </div>
  )
}

// const MyTextTags = ({ label, ...props }) => {
//   // const [field] = useField(props);
//   console.log(props);
//   return (
//     <>
//       <div className={styles.item}>
//         <div className={styles.tagsTitle}>{label}</div>
//         <div className="clearfix" style={{ width: 260 }}>
//           {/* <div className={styles.tags}>Add</div>
//           <div className={styles.tags}>Add</div> */}
//           <div className={styles.tagsAdd}>
//             <img src={tagAdd} alt="" />
//             <span className="ms-1">Add</span>
//           </div>
//         </div>
//       </div>
//       <TagsModal show={true} />
//     </>
//   );
// };

const CaptureCover = ({ template }) => {
  const { tid } = useParams()
  const source = `template_${tid}_cover`
  const [avatar, isLoading] = useCapture(source)
  const [upload, { isLoading: uploadLoading }] = useUploadSketchMutation()
  const [update] = useUpdateTemplateMutation()
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(captureIt({ captureSource: source, tid }))
  }

  useEffect(() => {
    if (avatar && avatar.id) {
      // console.log(avatar);
      const value = {
        cover: `https://img.zthd.io/an1/avatars/${avatar.originalId}.webp`,
        initDataId: avatar.id,
      }
      const body = { key: 'cover', value: JSON.stringify(value) }
      update({ id: tid, body }).unwrap()
    }
  }, [avatar, update, tid])

  const imageToBase64 = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (e) => {
        resolve(e.target.result)
      }
      reader.readAsDataURL(file)
    })
  }

  const uploadFn = async (file, key) => {
    const result = await imageToBase64(file)
    upload({ data: result }).then((res) => {
      if (res?.data?.url) {
        const body = { key, value: res?.data?.url }
        update({ id: tid, body }).unwrap()
      }
    })
  }

  const updateCover = async () => {
    const value = {
      cover: template.sketch,
      initDataId: null,
    }
    const body = { key: 'cover', value: JSON.stringify(value) }
    update({ id: tid, body }).unwrap()
  }

  return (
    <div className={'item'}>
      <h6 className={'title'}>Front Cover</h6>
      <div className="d-flex align-items-center">
        <div className={'CaptureCover'}>
          <img width={256} src={template?.cover} alt="cover" />
          <div disabled={isLoading} className={'Refresh'} onClick={handleClick}>
            {isLoading ? (
              <ArrowClockwise width={26} height={26} className={'loading'} />
            ) : (
              <ArrowClockwise width={26} height={26} />
            )}
          </div>
        </div>
        <ArrowLeftRight className="mx-4" onClick={updateCover} />
        <div className={'CaptureCover'}>
          <img width={256} src={template?.sketch} alt="sketch" />
          <div disabled={isLoading} className={'Refresh'}>
            <input
              style={{
                width: 50,
                height: 50,
                opacity: 0,
                position: 'absolute',
                right: 0,
                bottom: 0,
                cursor: 'pointer',
              }}
              type="file"
              accept=".jpg, .png, .jpeg, .webp"
              onChange={(e) => uploadFn(e.target.files[0], 'sketch')}
              value=""
            />
            {uploadLoading ? (
              <ArrowClockwise width={26} height={26} className={'loading'} />
            ) : (
              <CloudUpload width={26} height={26} />
            )}
          </div>
        </div>
      </div>
      <div className={'CaptureCover mt-4'}>
        <img width={256} src={template?.cptCover} alt="cptCover" />
        <div disabled={isLoading} className={'Refresh'}>
          <input
            style={{
              width: 50,
              height: 50,
              opacity: 0,
              position: 'absolute',
              right: 0,
              bottom: 0,
              cursor: 'pointer',
            }}
            type="file"
            accept=".jpg, .png, .jpeg, .webp"
            onChange={(e) => uploadFn(e.target.files[0], 'cptCover')}
            value=""
          />
          {uploadLoading ? (
            <ArrowClockwise width={26} height={26} className={'loading'} />
          ) : (
            <CloudUpload width={26} height={26} />
          )}
        </div>
      </div>
    </div>
  )
}

export default LeftPanel
