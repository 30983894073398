import React from "react";
import { Link } from "react-router-dom";
import CopyedCode from "ui/CopyedCode";
import UpdateEntity from "./UpdateEntity";
import UpdateAsSelect from "./UpdateAsSelect";
import Fetching from "ui/Fetching";
import { Button } from "react-bootstrap";
import { useTemplates } from "screens/Editor/hooks";

const ReviewPanel = () => {
  return <Templates k="t" v="draft" />;
};

const Templates = ({ k, v }) => {
  const { templates, isFetching, refetch } = useTemplates(k, v);
  if (isFetching)
    return (
      <div className="p-3">
        <Fetching />
      </div>
    );
  return (
    <div className="px-3">
      <Button className="my-3" variant="outline-primary" onClick={refetch}>
        refetch
      </Button>
      <table className="table table-hover">
        <thead className="table-secondary">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Preview</th>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Publisher</th>
            <th scope="col">Gender</th>
            <th scope="col">Info</th>
            <th scope="col">Provider</th>
            <th scope="col">Remark</th>
            <th scope="col">Options</th>
          </tr>
        </thead>
        <tbody>
          {templates?.map((template, index) => (
            <tr key={template.id}>
              <Item index={index} template={template} />
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Item = ({ template, index }) => {
  return (
    <>
      <td>{index + 1}</td>
      <td>
        {template.cover && <img width={96} src={template.cover} alt="..." />}
      </td>
      <td>
        <CopyedCode copyed={template.id}>{template.id}</CopyedCode>
      </td>
      <td>{template.name}</td>
      <td>{template.publisher}</td>
      <td>
        <UpdateAsSelect
          data={template}
          entityKey="gender"
          datas={[
            { code: "", name: "" },
            { code: "1", name: "Boy" },
            { code: "0", name: "Girl" },
          ]}
        />
      </td>
      <td>
        layers:{template.layerCount}
        <br />
        components:{template.componentCount}
      </td>

      <td>{template.provider}</td>
      <UpdateEntity data={template} entityKey="remark" />
      <td>
        <Link
          className="me-1 btn btn-outline-primary"
          to={`/editor/` + template.id + "?r=1"}
        >
          Review draft
        </Link>
      </td>
    </>
  );
};

export default ReviewPanel;
