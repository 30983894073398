import { createSlice } from "@reduxjs/toolkit";
import { neku } from "api/neku";

const initialState = {
  user: null,
  token: null,
  face: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: () => initialState,
    loginAsFace: (state, action) => {
      state.face = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(neku.endpoints.login.matchFulfilled, (state, action) => {
      const { accessToken, ...user } = action.payload;
      state.token = accessToken;
      state.user = user;
      state.face = { id: user.id, nick_name: user.nick_name };
    });
  },
});

export const { logout, loginAsFace } = slice.actions;
export default slice.reducer;
