import React from "react";
import { useParams } from "react-router-dom";

import ListGroup from "react-bootstrap/ListGroup";

import { useFetchBatchNos } from "../../hooks";

import "../index.scss";

const Index = () => {
  return (
    <div className="mt-1">
      <DisplayBathNos />
    </div>
  );
};

const DisplayBathNos = () => {
  const { tid } = useParams();
  const { data: batchNos } = useFetchBatchNos();
  if (!batchNos || batchNos?.length <= 0) return <h1>no batchNos..</h1>;
  return (
    <ListGroup>
      <ListGroup.Item
        action
        href={`/admin/batchnos/` + tid + "/settles?tid=" + tid}
      >
        default
      </ListGroup.Item>
      {batchNos?.map((data) => (
        <ListGroup.Item
          key={data.id}
          action
          href={`/admin/batchnos/` + data.id + "/settles?tid=" + tid}
        >
          {data.name}
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};

export default Index;
