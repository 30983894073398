import React from "react";
import { useSelector } from "react-redux";
import LayerPicker from "./LayerPicker";
import { useLayers, useDlcs } from "screens/Editor/hooks";
import Accordion from "react-bootstrap/Accordion";
import CptsInDlc from "./CptsInDlc";
import CptsWithoutDlc from "./CptsWithoutDlc";
import DefalutCpts from "./DefalutCpts";
import Options from "./Options";
import "./index.scss";
import { useParams } from "react-router-dom";

export const LayerPanel = () => {
  const { tid } = useParams()
  const sortType = useSelector((state) => state.ui.editor[tid].sortType);
  const { layers, isFetching } = useLayers(sortType);
  if (isFetching) {
    return (
      <div
        className="spinner-border m-auto"
        style={{
          color: "#FF3B63",
          position: "relative",
          top: -35,
          width: 50,
          height: 50,
          borderWidth: 6,
        }}
        role="status"
      />
    );
  }
  return (
    <>
      <LayerPicker layers={layers} />
      <div className="h-100 overflow-hidden">
        <CptInLayer />
        <Options />
      </div>
    </>
  );
};

const CptInLayer = () => {
  const { tid: dlc } = useParams("tid");
  const lid = useSelector((state) => state.ui.editor[dlc].lid);
  if (!lid) return <h1>please select layer</h1>;
  return (
    <div className="d-flex flex-column h-100 overflow-auto">
      <Accordion defaultActiveKey={[dlc]} alwaysOpen>
        <CptsWithoutDlc />
        <DefalutCpts />
        <Dlcs />
      </Accordion>
    </div>
  );
};
const Dlcs = () => {
  const { data: dlcs } = useDlcs();
  if (!dlcs) return null;
  return (
    <>
      {dlcs.map((dlc) => (
        <CptsInDlc key={dlc.id} dlc={dlc} />
      ))}
    </>
  );
};
