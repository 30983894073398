import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Accordion, Button, Modal } from "react-bootstrap";
import { useRole } from "hooks/auth";
import { useDispatch } from "react-redux";
import { selectToning } from "screens/Editor/uiSlice";
import { 
  useAddColorMutation,
  useGetToningsTemplateQuery } from "api/neku/tonings";
import { useConvertColor2LinearGradient } from "screens/Editor/BottomPanel/ToningPanel/PickerColor/LinearColor";
import './index.scss'

const InitColor = ({ toning }) => {
  const [show, setShow] = useState(false);
  const isAdmin = useRole(`admin`);
  if (!isAdmin) {
    return null;
  }

  return (
    <>
      <Button variant="outline-danger" onClick={() => setShow(true)}>
        init color
      </Button>
      {show && <ShowTonings setShow={setShow} targetToning={toning} />}
    </>
  );
};

const ShowTonings = ({ setShow, targetToning }) => {
  const { data: tonings } = useGetToningsTemplateQuery();
  return (
    <Modal show={true} size="lg" onHide={setShow}>
      <Modal.Header closeButton>
        <Modal.Title>
          <div className="d-flex">
            <span className="mx-3">Init Color</span>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Accordion>
          {tonings?.map((toning) => (
            <ToingItem
              key={toning.id}
              toning={toning}
              targetToning={targetToning}
            />
          ))}
        </Accordion>
      </Modal.Body>
    </Modal>
  );
};

const ToingItem = ({ toning, targetToning }) => {
  const dispatch = useDispatch();
  const { tid } = useParams();
  const [addColor, { isLoading }] = useAddColorMutation()
  const handleClick = (e) => {
    e.stopPropagation();
    toning.colors.map((color) =>
      addColor({
        id: targetToning.id,
        body: { tid, colorId: color.id, colors: color.colors },
      })
    );
  };
  const onSelect = (e) => {
    e.stopPropagation();
    dispatch(selectToning({toningId:toning.id, tid}));
  };
  return (
    <Accordion.Item eventKey={toning.id}>
      <Accordion.Header onClick={onSelect}>
        <div>
        {toning.name}{" "}
        <Button
          variant="outline-danger"
          disabled={isLoading}
          onClick={handleClick}
          className="mx-3"
        >
          init {isLoading && "ing..."}
        </Button>
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="d-flex flex-wrap gap-3">
          {toning.colors?.map((c) => (
            <LinearColor key={c.id} color={c} />
          ))}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};



const LinearColor = ({ color }) => {
  const linear = useConvertColor2LinearGradient(color);
  return <div className="p-2 rounded cursor-pointer" style={linear} />;
};

export default InitColor;
