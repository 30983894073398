import React, { useEffect, useMemo } from "react";
import classNames from "classnames";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { selectColor, unSelectColor } from "../../screens/Editor/uiSlice";
import emitter from "canvasMitt";
import { useParams } from "react-router-dom";

export const useSelectedColor = (colorId,tid) => {
  const filterIt = useMemo(() => {
    return createSelector(
      (state) => state.ui.editor[tid].tonings,
      (state) => state.ui.editor[tid].toningId,
      (tonings, toningId) => tonings[toningId]?.default?.id === colorId
    );
  }, [colorId, tid]);

  return useSelector(filterIt);
};
export const useConvertColor2LinearGradient = (color) => {
  const [linear, setLinear] = useState();
  useEffect(() => {
    if (color) {
      const colors = [...color.colors];
      colors.sort((a, b) => a.offset - b.offset);
      const sortColors = colors
        .map((t) => `${t.color} ${t.offset * 100}%`)
        .join(", ");
      const linear = {
        width: 49,
        height: 49,
        backgroundImage: `linear-gradient(to right, ${sortColors})`,
      };
      setLinear(linear);
    } else {
      setLinear(null);
    }
  }, [color]);

  return linear;
};

const LinearColor = ({ color }) => {
  const { tid} = useParams();
  const linear = useConvertColor2LinearGradient(color);
  const selected = useSelectedColor(color?.id, tid);
  const selectedStyle = classNames({ selectBorder: selected });
  const toningId = useSelector((state) => state.ui.editor[tid].toningId);
  const dispatch = useDispatch();
  const handleClick = () => {
    if (selected) {
      //unselected
      dispatch(unSelectColor({toningId, tid}));
      emitter.emit(`unSelectColor`, toningId);
      emitter.emit(`render_${toningId}`, toningId);
    } else {
      const data = { toningId, color, tid };
      dispatch(selectColor(data));
      emitter.emit(`selectColor`, data);
      emitter.emit(`render_${toningId}`, toningId + "");
      //selected
    }
  };
  if (!color || !linear) {
    return null;
  }
  return (
    <div className="col colorItem my-2">
      <div className={selectedStyle} style={{ padding: 6 }}>
        <div
          className="preview-gradien rounded cursor-pointer"
          style={linear}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default LinearColor;
