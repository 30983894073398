import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { useUpdateToningMutation } from "api/neku/tonings";

const UpdateName = ({ toning }) => {
  const { tid } = useParams();
  const [state, setState] = useState(toning.name);
  const [update] = useUpdateToningMutation();
  const handleUpdateName = () => {
    if (!state || state === toning.name) {
      return;
    }
    update({
      id: toning.id,
      body: { tid: tid, key: "name", value: state },
    }).unwrap();
  };
  return (
    <input
      type="text"
      value={state}
      className="update-name"
      placeholder="Toning name"
      onChange={(e) => setState(e.target.value)}
      onBlur={handleUpdateName}
    />
  );
};

export default UpdateName;
