import { createSlice } from "@reduxjs/toolkit";
import { makeShortId } from "commons";

const initialState = {
  // version: null,
  // lid: null,
  // toningId: null,
  // currPid: null,
  // drawers: [],
  // tonings: {},
  // captureSource: null,
  // capture: null,
  // currCpt: null,
  // showUpload: false,
  // sortType: "z",
  // currTab: "layers",
  // currColor: null,
  // batchUploadTask: "default",
  // layerScroll: 0
};

const slice = createSlice({
  name: "editor",
  initialState,
  reducers: {
    resetCanvas: (state, action) => {
      const tid = action.payload;
      state[tid].currPid = null;
      state[tid].drawers = [];
      state[tid].tonings = {};
      state[tid].currCpt = null;
      state[tid].currColor = null;
      state[tid].capture = null;
      state[tid].captureSource = null;
    },
    resetLayerFromCanvas: (state, action) => {
      const {lid, tid} = action.payload; 
      state[tid].currCpt = null;
      state[tid].drawers = state[tid].drawers.filter((d) => d.lid !== lid);
    },
    setCurrTab: (state, action) => {
      const {currTab, tid} = action.payload;
      state[tid].currTab = currTab;
    },
    captureIt: (state, action) => {
      const {tid, captureSource} = action.payload;
      state[tid].captureSource = captureSource;
      state[tid].capture = null;
    },
    setCapture: (state, action) => {
      const {capture, tid} = action.payload
      state[tid].capture = capture;
    },
    // setPreview: (state, action) => {
    //   const {perview, tid} = action.payload;
    //   state[tid].preview = perview;
    // },
    selectToning: (state, action) => {
      const {tid,toningId } = action.payload;
      state[tid].toningId = toningId;
    },
    selectLayer: (state, action) => {
      const {lid, tid} = action.payload;
      state[tid].lid = lid;
      state[tid].currPid = null;
      state[tid].currCpt = null;
      state[tid].currColor = null;
    },
    setLayerScroll: (state, action) => {
      const { layerScroll,tid } = action.payload;
      state[tid].layerScroll = layerScroll;
    },
    setPid: (state, action) => {
      const { currPid, tid} = action.payload;
      state[tid].currPid = currPid;
      state[tid].currCpt = null;
    },
    selectColor: (state, action) => {
      const {tid} = action.payload;
      state[tid].currColor = action.payload;
      const { toningId, color, lid = "default" } = action.payload;
      const data = {
        id: color.id,
        toning: color.colors.map((t) => ({ c: t.color, o: t.offset })),
      };
      if (state[tid].tonings[toningId]) {
        state[tid].tonings[toningId][lid] = data;
      } else {
        state[tid].tonings[toningId] = { [lid]: data };
      }
    },
    setColors: (state, action) => {
      const {tid, tonings} = action.payload;
      state[tid].tonings = tonings
    },
    unSelectColor: (state, action) => {
      const {toningId, tid} = action.payload;
      const next = { ...state[tid].tonings };
      delete next[toningId];
      state[tid].tonings = next;
      state[tid].currColor = null;
    },
    unSelectCpt: (state, action) => {
      const {cpt, tid} = action.payload;
      state[tid].drawers = state[tid].drawers.filter((d) => d.cid !== cpt.id);
    },
    setDrawers: (state, action) => {
      const { tid, drawers } = action.payload;
      state[tid].drawers = drawers;
    },
    selectCpt: (state, action) => {
      const { layer, cpt, tid } = action.payload;
      state[tid].drawers = state[tid].drawers.filter((d) => d.lid !== layer.id);
      // console.log(layer,  cpt);
      const drawer = {
        cid: cpt.id,
        lid: layer.id,
        x: cpt.crop.x,
        y: cpt.crop.y,
        w: cpt.crop.w,
        h: cpt.crop.h,
        z: layer.z,
      };

      if (layer.toning) {
        drawer.t = layer.toning;
      }
      if(cpt.dlc !== cpt.tid){
        drawer.did = cpt.dlc
      }
      state[tid].drawers.push(drawer);
    },
    // setAfter: (state, action) => {
    //   state.query.after = action.payload;
    // },
    toggleSortType: (state, action) => {
      const tid = action.payload
      state[tid].sortType = state[tid].sortType === "z" ? "playIndex" : "z";
    },
    setCurrCpt: (state, { payload }) => {
      const { currCpt, tid } = payload;
      state[tid].currCpt = currCpt;
    },
    fireBatchUpload: (state, action) => {
      const { task, tid} = action.payload
      if (task === `reset`) {
        state[tid].batchUploadTask = { id: "default", tasks: 0, done: 0, fails: 0 };
      } else {
        const id = makeShortId(4);
        state[tid].batchUploadTask = {
          id,
          tasks: task,
          done: 0,
          fails: 0,
        };
      }
    },
    updateBatchUpload: (state, action) => {
      const { id, done, fails, tid } = action.payload;
      if (state[tid].batchUploadTask.id === id) {
        state[tid].batchUploadTask.done += done;
        state[tid].batchUploadTask.fails += fails;
      }
    },
    setUpload: (state,action) => {
      const { showUpload, tid} = action.payload;
      state[tid].showUpload =  showUpload
    },
    initEditor: (state,action) =>{
      const tid = action.payload;
      state[tid] = {
        version: null,
        lid: null,
        toningId: null,
        currPid: null,
        drawers: [],
        tonings: {},
        captureSource: null,
        capture: null,
        currCpt: null,
        showUpload: false,
        sortType: "z",
        currTab: "layers",
        currColor: null,
        batchUploadTask: "default",
        layerScroll: 0
      };
    }
  },
});

export const {
  selectLayer,
  setPid,
  // setPreview,
  setDrawers,
  selectCpt,
  unSelectCpt,
  setColors,
  selectColor,
  unSelectColor,
  captureIt,
  setCapture,
  resetCanvas,
  resetLayerFromCanvas,
  toggleSortType,
  selectToning,
  setCurrTab,
  setCurrCpt,
  fireBatchUpload,
  updateBatchUpload,
  setUpload,
  setLayerScroll,
  initEditor
} = slice.actions;
export default slice.reducer;
