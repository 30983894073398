import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import { selectToning } from "../../uiSlice";
import classNames from "classnames";
import { useParams } from "react-router-dom";

const ToningPicker = ({ tonings }) => {
  const scrollRef = useRef();

  const onPreScroll = () => {
    scrollRef.current.scrollLeft = scrollRef.current.scrollLeft - 390;
  };
  const onNextScroll = () => {
    scrollRef.current.scrollLeft = scrollRef.current.scrollLeft + 390;
  };

  return (
    <div
      className="flex-center justify-content-between w-100"
      style={{
        height: 72,
        boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.08)",
        zIndex: 1050,
      }}
    >
      <div
        className="mx-2 h-100 flex-center cursor-pointer"
        onClick={onPreScroll}
      >
        <ChevronLeft width={25} height={25} />
      </div>
      <div
        className="d-flex overflow-auto clearScroll "
        style={{ boxSizing: "border-box", padding: "7px 0 0 0" }}
        ref={scrollRef}
      >
        {tonings?.map((value) => (
          <Toning key={value.id} toning={value} />
        ))}
      </div>
      <div
        className="mx-2 flex-center flex-row-reverse cursor-pointer"
        style={{ height: 70 }}
        onClick={onNextScroll}
      >
        <ChevronRight width={25} height={25} />
      </div>
    </div>
  );
};

const Toning = ({ toning }) => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const selected = useSelector(
    (state) => state.ui.editor[tid].toningId === toning.id
  );

  const onSelect = () => {
    dispatch(selectToning({toningId:toning.id, tid}));
  };

  const style = classNames({
    rounded: selected,
  });

  return (
    <div className="mx-2 cursor-pointer">
      <div
        className={style}
        onClick={onSelect}
        style={{
          minWidth: "50px",
          padding: "0 7px",
          whiteSpace: "nowrap",
          height: 49,
          lineHeight: "49px",
          marginBottom: 1,
          outline: selected && "1px solid #FF3B63",
          background: "#f5f5f5",
          borderRadius: "10px",
        }}
      >
        {toning.name}
      </div>
      <div className="mt-1" style={{ height: 5 }}></div>
    </div>
  );
};

export default ToningPicker;
