import React from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectLayer, selectToning } from '../../../uiSlice'
import UpdateCover from './UpdateCover'
import MaxTips from './MaxTips'
import UpdateToning from './UpdateToning'
import UpdateGroup from './UpdateGroup'
import UpdatePlayIndex from './UpdatePlayIndex'
import UpdateZIndex from './UpdateZindex'
import UpdateMax from './UpdateMax'
import UpdateOpt from './UpdateOpt'
import UpdateWatermark from './UpdateWatermark'
import UpdateBodymark from './UpdateBodyMark'
import UpdateName from './UpdateName'
import { DropletFill, Trash3 } from 'react-bootstrap-icons'
import Accordion from 'react-bootstrap/Accordion'
import Confirm from 'components/Confirm'
import { useDeleteLayerMutation } from 'api/neku/layers'
import color_n_image from 'assets/edit_color_n.png'
import color_s_image from 'assets/edit_color_s.png'
import { useCptsWithDlc } from 'screens/Editor/hooks'
import { useRole } from 'hooks/auth'
import CustomSpinner from 'components/Spinner'
import classNames from 'classnames'
import './index.scss'

const Layer = ({ layer }) => {
  const { tid } = useParams()
  const selected = useSelector((state) => state.ui.editor[tid].lid === layer.id)
  const dispatch = useDispatch()
  const handleSelectLayer = () => {
    dispatch(selectLayer({ lid: layer.id, tid }))
    //sync select toning
    dispatch(selectToning({ toningId: layer.toning, tid }))
  }
  const isAdmin = useRole(`admin`)
  const { cpts } = useCptsWithDlc(tid)

  const style = classNames('right-panel-layer', {
    'right-panel-layer-active': selected,
  })

  if (!layer) return null

  return (
    <Accordion.Item eventKey={layer.id} className={style}>
      <Accordion.Header onClick={handleSelectLayer}>
        <div className="flex-shrink-0 me-3 cover">
          <UpdateCover layer={layer} />
        </div>
        <div className="d-flex flex-column w-100">
          <div className="d-flex align-items-center justify-content-between">
            <UpdateName layer={layer} />
            <div>
              ID:<code className="ms-1">{layer.id}</code>
            </div>
            <UpdateOpt layer={layer} />
            <div>
              <MaxTips layer={layer} />
            </div>
            <div style={{ width: 55, textAlign: 'right' }}>
              {isAdmin && layer.watermark === 1 && (
                <span className="me-2" style={{ color: '#FF3B63' }}>
                  <DropletFill width={17} height={17} />
                </span>
              )}
              <span className="fw-bold me-2">
                <img
                  src={layer.toning ? color_s_image : color_n_image}
                  alt=""
                  style={{ width: 22, height: 22 }}
                />
              </span>
            </div>
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body style={{ background: '#F2F3F4' }}>
        <div className="flex-center justify-content-between pb-3 ">
          <div className="flex-center" style={{ color: '#666666' }}>
            <div>Components: {cpts?.length}</div>
          </div>
        </div>
        <AdminOpt layer={layer} cpts={cpts} />
        <div className="d-flex align-items-center justify-content-between mt-2">
          <div>
            <UpdateToning layer={layer} />
            <UpdateGroup layer={layer} />
            <UpdateZIndex layer={layer} />
            <UpdatePlayIndex layer={layer} />
            <UpdateMax layer={layer} />
          </div>
          <div>
            <DeleteButton layer={layer} />
          </div>
        </div>
      </Accordion.Body>
    </Accordion.Item>
  )
}

const AdminOpt = ({ layer, cpts }) => {
  const isAdmin = useRole(`admin`)
  if (!isAdmin) return null
  return (
    <>
      <div className="d-flex-column">
        <div className="d-flex justify-content-between gap-1">
          <div className="ms-1">
            <UpdateWatermark layer={layer} />
            <span style={{ display: 'inline-block', width: 10 }}></span>
            <UpdateBodymark layer={layer} />
          </div>
        </div>
      </div>
    </>
  )
}

const DeleteButton = ({ layer }) => {
  const { tid } = useParams()
  const [deleteIt, { isLoading }] = useDeleteLayerMutation()
  const handleDelete = () => {
    deleteIt({ id: layer.id, tid }).unwrap()
  }

  return (
    <Confirm
      onConfirm={handleDelete}
      title={`Delete Layer ${layer.name}`}
      body={null}
    >
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <Trash3 style={{ color: '#CCCCCC', cursor: 'pointer' }} />
      )}
    </Confirm>
  )
}

export default Layer
