import React, { useEffect, useState, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { Form, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import DisplayAvatars from "./DisplayAvatars";
import { Formik, useField, useFormikContext } from "formik";
import { createSelector } from "@reduxjs/toolkit";
import "./index.scss";
import classNames from "classnames";
import * as Yup from "yup";
import { ContentLangs, Tags } from "config";
import EmptyDatas from "ui/EmptyDatas";
import Fetching from "ui/Fetching";
import { useGetAvatarsQuery } from "api/neku/avatars";
import { useSet } from "ahooks";
import Select from "ui/Select";
import { useSelector } from "react-redux";

const Index = () => {
  const [clientVer, setClientVer] = useState(-1)
  const [searchParams] = useSearchParams();
  const queryParams = Object.fromEntries(searchParams.entries());
  const filterIt = useMemo(() => {
    return createSelector(
      (res) => res.data,
      (data) => {
        return data
      }
    )
  }, [clientVer]);
  const { data, isFetching, refetch } = useGetAvatarsQuery(queryParams, {
    skip: !queryParams,
    selectFromResult: (result) => ({
      ...result,
      data: filterIt(result),
    }),
  });

  const after = data ? data[data.length - 1]?.id : "";

  return (
    <>
      <SearchForm after={after} />
      <div className="d-flex">
        <Button variant="outline-primary" onClick={refetch}>
          Refetch
        </Button>
      </div>
      {isFetching && <Fetching />}
      {data?.length <= 0 && <EmptyDatas />}
      <DisplayAvatars avatars={data} />
    </>
  );
};

const QuerySchema = Yup.object().shape({
  // state: Yup.number().typeError(""),
  lang: Yup.number()
    .typeError("Age must be a number")
    .min(0, "must >=0")
    .max(43, "must <=43"),
  order: Yup.string().notOneOf(["-"], "please select item").required(),
});

const SearchForm = ({ after }) => {
  const tags = useSelector(state => state.tagLibs)
  const [searchParams, setSearchParams] = useSearchParams();
  const queryParams = Object.fromEntries(searchParams.entries());
  if (queryParams.author) {
    return null;
  }
  return (
    <Formik
      enableReinitialize
      initialValues={{ lang: 0, order: "updateTime",state: 0 }}
      validationSchema={QuerySchema}
      onSubmit={(values, actions) => {
        const newObj = Object.assign({}, values);
        for (let key in newObj) {
          if (newObj[key] === "" || newObj[key] === -1) {
            delete newObj[key];
          }
        }

        if (queryParams.tid) {
          newObj.tid = queryParams.tid;
        }

        setSearchParams(newObj);
        actions.setSubmitting(false);
      }}
    >
      <Form className="needs-validation" noValidate>
        <Row className="align-items-center">
          {/* <Col xs="auto">
            <MySelect options={States} name="state" showCode label="State" />
          </Col> */}
          <Col xs="auto">
            <MySelect
              name="lang"
              options={ContentLangs}
              showCode
              label="Lang"
            />
          </Col>
          <Col xs="auto">
            <MySelect
              name="order"
              options={[
                { code: "updateTime", name: "updateTime" },
                {
                  code: "dailyCount.reCreateCount",
                  name: "reCreateCount-daily",
                },
                { code: "reCreateCount", name: "reCreateCount-total" },
                {
                  code: "dailyCount.downloadCount",
                  name: "downloadCount-daily",
                },
                { code: "dCount", name: "downloadCount-total" },
              ]}
              label="Order"
            />
          </Col>
          <Col xs="auto">
            <MySelect
              name="state"
              options={[
                { code: "0", name: "local" },
                { code: "401",name: "public"}
              ]}
              label="state"
            />
          </Col>
          <Col xs="auto">
            <MySelect
              name="tag"
              options={[...(Object.keys(tags)).map(id => {
                return {
                  code: tags[id].name,
                  name: tags[id].name
                }
              })]}
              label="tag"
            />
          </Col>
          <Col xs="auto">
            <MySelect
                name="clientVer"
                options={[
                  { code: 0, name: 'vuser' },
                ]}
                label="clientVer"              
              />
            </Col>
            <Col xs="auto">
            <MySelect
                name="simple"
                options={[
                  { code:1, name: 'simple avatar' },
                ]}
                label="simple"              
              />
            </Col>
          <Col>
            <Pagination after={after} />
          </Col>
          <Col xs="auto">
            <AutoSubmit />
          </Col>
        </Row>
        <Row></Row>
      </Form>
    </Formik>
  );
};

const MySelect = ({ label, showCode, options, ...props }) => {
  const [field, meta] = useField(props);
  const style = classNames(
    "form-select",
    {
      "is-invalid": meta.touched && meta.error,
    },
    { "is-valid": meta.touched && !meta.error }
  );
  return (
    <>
      <div>{label}</div>
      <select className={style} {...field}>
        <option value="">Select item</option>
        {options.map((o) => (
          <option key={o.code} value={o.code}>
            {showCode && `${o.code}-`}
            {o.name}
          </option>
        ))}
      </select>
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
      {meta.touched && !meta.error ? (
        <div className="valid-feedback">Looks good!</div>
      ) : null}
    </>
  );
};

const Pagination = ({ after }) => {
  const [, meta, helpers] = useField("after");

  const { value } = meta;
  const { setValue } = helpers;
  const [afters, { add, reset }] = useSet([]);

  const {
    values: { state, lang, order },
    setFieldValue,
  } = useFormikContext();

  const isSelected = (v) => (v === value ? "success" : "outline-success");

  useEffect(() => {
    setFieldValue("after", "");
    reset();
  }, [state, lang, order, setFieldValue, reset]);

  useEffect(() => {
    if (after) {
      add(after);
    }
  }, [after, add]);

  return (
    <ButtonGroup aria-label="Basic example">
      <Button variant={isSelected("")} onClick={() => setValue("")}>
        Home
      </Button>
      {Array.from(afters)
        .slice(0, -1)
        .map((a, index) => (
          <Button key={a} variant={isSelected(a)} onClick={() => setValue(a)}>
            {index + 2}
          </Button>
        ))}
      <Button variant="outline-success" onClick={() => setValue(after)}>
        Next
      </Button>
    </ButtonGroup>
  );
};

const AutoSubmit = () => {
  const { submitForm, values } = useFormikContext();
  useEffect(() => {
    submitForm();
  }, [values, submitForm]);
  return null;
};
export default Index;
