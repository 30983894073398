import React from "react";

const Select = ({
  value,
  datas,
  onChange,
  label = "",
  showLabel = false,
  showCode = false,
  showShort = false,
  className = "",
}) => {
  return (
    <div className={`col-auto ${className}`}>
      {showLabel && <label className="form-label">{label}</label>}
      <select
        className="form-select"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      >
        {datas.map(({ code, name, short }) => (
          <option key={code} value={code}>
            {showCode && code + " - "} {showShort ? short : name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
