import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Image } from "react-bootstrap";
import { useAddWebImgMutation } from "api/neku/avatars";
import { makeShortId } from "commons";

function Create({show, setShow}) {
  const navigate = useNavigate();
  const [results, setResults] = useState([]);
  const [uploadFiles, setUploadFiles] = useState([]);

  const [create, { isLoading }] = useAddWebImgMutation();

  const handleClose = () => {
    setUploadFiles([]);
    setResults([]);
    setShow(false);
  };

  const canSave = uploadFiles.length > 0;

  const handleCreate = () => {
    if (!canSave) {
      return;
    }

    uploadFiles.forEach(({ id, original }) => {
      const data = { original, id };
      create(data)
        .unwrap()
        .then((res) => setResults((r) => [...r, res]));
    });
  };

  const handleRemove = (id) => {
    setUploadFiles((items) => items.filter((item) => item.id !== id));
  };

  function setUpReader(file) {
    var reader = new FileReader();
    reader.onload = (e) => {
      var img = document.createElement("img");
      img.onload = () => {
        const id = makeShortId(24);
        setUploadFiles((items) => [
          ...items,
          { id, original: e.target.result, w: img.width, h: img.height },
        ]);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  }

  const selectFile = (files) => {
    if (files === undefined) {
      return;
    }
    setResults([]);
    setUploadFiles([]);
    for (const file of files) {
      setUpReader(file);
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Web Avatars</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form className="row g-3">
          {/* <SelectFile onChange={(v) => selectFile(v)}>Select WebImgs</SelectFile> */}
          <input
            className="form-control"
            type="file"
            multiple
            accept=".jpg, .png, .jpeg, .webp, .gif, .bmp"
            onChange={(e) => selectFile(e.target.files)}
          />
        </form>
      </Modal.Body>
      <Modal.Body>
        {uploadFiles.map((data) => (
          <div key={data.id} className="row">
            <div className="col-4">
              <Image src={data.original} width={128} />
            </div>
            <div className="col-3">
              {data.id}
            </div>
            <div className="col-2">
              <Result id={data.id} results={results} />
            </div>
            <div className="col-2">
              <Button
                variant="outline-primary"
                onClick={() => handleRemove(data.id)}
              >
                Remove
              </Button>
            </div>
          </div>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          disabled={isLoading || !canSave}
          variant="primary"
          onClick={handleCreate}
        >
          Save Changes {isLoading && "ing..."}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

const Result = ({ id, results }) => {
  const match = results?.some((r) => r.data.id === id);
  if (match) return <span className="badge bg-success">{match && "done"}</span>;
  return null;
};

export default Create;
