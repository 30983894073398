import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Link45deg, Layers, Trash3, Save } from "react-bootstrap-icons";
import { selectToning } from "screens/Editor/uiSlice";
import Confirm from "components/Confirm";
import { useDeleteToningMutation,useSaveToningTemplateMutation } from "api/neku/tonings";
import { useLayers } from "screens/Editor/hooks";
import "./index.scss";
import { Spinner } from "react-bootstrap";
import UpdateName from "./UpdateName";
import classNames from "classnames";
import InitColor from "./InitColor";

const useLink = (layers, toningId) => {
  const [state, setState] = useState(false);
  useEffect(() => {
    const link = layers?.some((layer) => layer.toning === toningId);
    setState(link);
  }, [layers, toningId]);
  return state;
};

const Toning = ({ toning }) => {
  const dispatch = useDispatch();
  const { tid } = useParams();
  const { layers } = useLayers();

  const linked = useLink(layers, toning?.id);
  const [deleteIt, { isLoading }] = useDeleteToningMutation();

  const isActive = useSelector(
    (state) => state.ui.editor[tid].toningId === toning?.id
  );

  const handleDelete = () => {
    deleteIt({ id: toning.id, tid }).unwrap();
  };

  const onSelectToning = () => {
    dispatch(selectToning({toningId:toning.id, tid}));
  };
  const style = classNames("right-toning-item", {
    "right-toning-item-active": isActive,
  });

  return (
    <div className={style} onClick={onSelectToning}>
      <div className="flex-center">
        <UpdateName toning={toning} />
        <small className="ms-4">Colors:&nbsp;{toning.colors.length}</small>
      </div>
      <div className="operation">
        <InitColor toning={toning} />
        {linked ? (
          <Link45deg
            className="mx-3"
            style={{ color: "#ff3b63", width: 21, height: 21 }}
          />
        ) : (
          <Link45deg className="mx-3" style={{ width: 21, height: 21 }} />
        )}
        <Layers className="me-3" style={{ width: 21, height: 21 }} />

        <Confirm
          onConfirm={handleDelete}
          can
          title={`Delete Toning ${toning.name}`}
          body={ null }
        >
          <span className="cursor-pointer" style={{ marginBottom: 10 }}>
            {isLoading ? (
              <Spinner animation="border" variant="success" size="sm" />
            ) : (
              <Trash3 className="me-3" style={{ width: 20, height: 20 }} />
            )}
          </span>
        </Confirm>
        <SaveAsTemplate  toning={toning} tid={tid} />
      </div>
    </div>
  );
};

const SaveAsTemplate = ({toning,tid}) => {
  const [ saveas, { isLoading } ] = useSaveToningTemplateMutation();
  const handleSave = () => {
    saveas({ id: toning.id, tid }).unwrap();
  };
  return (
    <>
        <Confirm
          onConfirm={handleSave}
          confirmText={`Save`}
          can
          title={`${toning.name}`}
          body={
            `Are you sure save this toning to toning template?`
          }
        >
          <span className="cursor-pointer" style={{ marginBottom: 10 }}>
            {isLoading ? (
              <Spinner animation="border" variant="success" size="sm" />
            ) : (
              <Save style={{ width: 20, height: 20 }} />
            )}
          </span>
        </Confirm>
    </>
  )
}

export default Toning;
