import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetCanvas, setCurrTab, selectToning } from "../uiSlice";
import { Random } from "./Random";
import colorImg from "assets/edit_color.png";
import emitter from "canvasMitt";
import { Eraser, ChevronLeft, ArrowClockwise, CloudUpload, MinecartLoaded } from "react-bootstrap-icons";
import { useCpts, useLayer, useAddAvatar } from "../hooks";
import "./index.scss";
import { setUpload } from "../uiSlice";
import { useParams } from "react-router-dom";

const Overlay = () => {
  const { tid } = useParams();
  const [isLoading] = useAddAvatar();
  const drawers = useSelector((state) => state.ui.editor[tid].drawers);
  const currTab = useSelector((state) => state.ui.editor[tid].currTab);
  const lid = useSelector((state) => state.ui.editor[tid].lid);
  const { layer } = useLayer(lid);
  const { cpts } = useCpts();
  const pro = cpts.filter(cpt => cpt.pro > 0)

  const dispatch = useDispatch();
  const handleReset = () => {
    dispatch(resetCanvas(tid));
    emitter.emit(`resetCanvas`, "reset");
    emitter.emit(`renderCpt`);
  };
  const handleUpload = () => {
    dispatch(setUpload({ showUpload: true, tid }))
  }
  return (
    <div className="canvas-box-overlay">
      <div className="position-absolute top-0 start-0 tp-info mt-2 ms-2 d-flex flex-column">
        <span>Cpts: {drawers?.length || 0}/{cpts?.length || 0}</span>
        <span>Pro: {pro.length || 0}/{cpts?.length || 0}</span>
      </div>
      <div className="position-absolute bottom-0 start-0 ms-2 mb-2">
        <div
          className={`tp-btn ${currTab === "tonings" && "btn-active"}`}
          onClick={() => {
            if (currTab === "tonings") {
              dispatch(setCurrTab({ currTab: "layers", tid }));
            } else {
              dispatch(setCurrTab({ currTab: "tonings", tid }));
              dispatch(selectToning({ toningId: layer?.toning, tid }));
            }
          }}
        >
          {currTab === "tonings" ? (
            <ChevronLeft />
          ) : (
            <img src={colorImg} alt="" />
          )}
        </div>
      </div>
      <div className="position-absolute bottom-0 end-0 tp_btn d-flex mb-2 me-2">
        <span className="tp-btn me-1" onClick={handleReset}>
          <Eraser />
        </span>
        <Random className="tp-btn me-1" />
        <span disabled={isLoading} className="tp-btn" onClick={handleUpload}>
          {isLoading ? <ArrowClockwise className={"loading"} /> : <CloudUpload />}
        </span>
      </div>
    </div>
  );
};

export default Overlay;
