import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "@reduxjs/toolkit";
import { setPid } from "../../../uiSlice";
import { CardWrap } from "./CardWrap";
import classNames from "classnames";
import { useCpt } from "screens/Editor/hooks";
import { useParams } from "react-router-dom";

const makeSelected = () =>
  createSelector(
    (state) => state.ui.editor,
    (_, id) => id,
    (_,__, tid) => tid,
    (_, __,___, childs) => childs,
    (editor, id, tid, childs) => {
      const drawers = editor[tid]?.drawers || []
      const hitChild = drawers?.find((d) => childs.some((c) => c.id === d.cid));
      if (hitChild) return true;
      const hitParent = drawers?.find((d) => d.cid === id);
      if (hitParent) return true;
      return false;
    }
  );

const makeGetCover = () =>
  createSelector(
    (state) => state.ui.editor,
    (_, id) => id,
    (_,__, tid) => tid,
    (_, __,___, childs) => childs,
    (editor, id, tid, childs) => {
      const drawers = editor[tid]?.drawers || []
      const hitChild = drawers?.find((d) => childs.some((c) => c.id === d.cid));
      if (hitChild) {
        return hitChild.cid;
      }
      return id;
    }
  );

export const ParentFolder = ({ cpt, childs }) => {
  const { tid } = useParams();
  const { id } = cpt;
  const dispatch = useDispatch();
  const coverSelector = useMemo(makeGetCover, []);
  const cover = useSelector((state) => coverSelector(state, id, tid, childs));

  const { cpt: coverCpt } = useCpt(cover);

  const selectedSelector = useMemo(makeSelected, []);
  const selected = useSelector((state) => selectedSelector(state, id, tid, childs));

  const style = classNames(
    "m-auto cpt d-flex justify-content-center align-items-center cursor-pointer",
    { "cpt-active": selected }
  );

  const handleClick = () => {
    dispatch(setPid({currPid:id, tid}));
  };

  console.log(coverCpt);

  return (
    <CardWrap>
      <div className={style} onClick={handleClick}>
        <img
          key={cover}
          style={{ maxWidth: "90%", maxHeight: "90%", borderRadius: 13 }}
          src={`https://img.zthd.io/an1/ats/${cover}`}
          alt=""
        />
        <div
          className="rounded-circle bg-white border border-1 styleCount"
          disabled={true}
        >
          {childs.length + 1}
        </div>
        {coverCpt?.pro > 0 && <div className="pro">{coverCpt.pro}</div>}
      </div>
    </CardWrap>
  );
};
