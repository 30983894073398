//React entry point 会自动根据endpoints生成hooks
import { cacher } from "api/rtkQueryCacheUtils";
import { neku } from "./index";

//使用base URL 和endpoints 定义服务
const api = neku.injectEndpoints({
  endpoints: (builder) => ({
    getBatchNos: builder.query({
      query: (tid) => ({
        url: `/batchnos`,
        method: "GET",
        params: { tid: tid },
      }),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("BatchNo"),
    }),
    updateBatchNo: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return { url: `/batchnos/${id}`, method: "PUT", body };
      },
      invalidatesTags: cacher.invalidatesList("BatchNo"),
    }),
    createBatchNo: builder.mutation({
      query: (body) => ({ url: `/batchnos`, method: "POST", body }),
      invalidatesTags: cacher.invalidatesList("BatchNo"),
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const {
  useGetBatchNosQuery,
  useCreateBatchNoMutation,
  useUpdateBatchNoMutation,
} = api;
export default api;
