import { useGetFramesQuery, useUpdateFrameMutation } from 'api/neku/frames'
import { Link, useSearchParams } from 'react-router-dom'
import React, { useMemo, useState } from 'react'
import { Button, Badge } from 'react-bootstrap'
import EmptyDatas from 'ui/EmptyDatas'
import Fetching from 'ui/Fetching'
import CopyedCode from 'ui/CopyedCode'
import UpdateAsSelect from './UpdateAsSelect'
import Confirm from 'components/Confirm'
import { useClearTemplateMutation } from 'api/neku/templates'
import DisplayUpdateTime from 'ui/DisplayUpdateTime'
import { createSelector } from '@reduxjs/toolkit'
import { useSelector } from 'react-redux'
import { Spinner } from 'react-bootstrap'
import Select from 'components/Form/Select'

const orders = [
  { code: 'updateTime', name: 'updateTime' },
  { code: 'createTime', name: 'createTime' },
  { code: 'dailyCount.reCreateCount', name: 'reCreateCount-daily' },
  { code: 'reCreateCount', name: 'reCreateCount-total' },
]

const Onlines = () => {
  const [searchParams] = useSearchParams()
  const tag = searchParams.get('tag') ?? null
  const [order, setOrder] = useState('updateTime')
  const [publisher, setPublisher] = useState('0')
  const [publishers, setPublishers] = useState([])
  const filterIt = useMemo(() => {
    const emptyArray = []
    return createSelector(
      (res) => res.data,
      (data) => {
        let obj = {}
        let publisherList = data
          ?.map((item) => {
            return { code: item.publisher, name: item.publisher }
          })
          .reduce((cur, next) => {
            if (!obj[next.code]) {
              obj[next.code] = true
              cur.push(next)
            }
            return cur
          }, [])
        setPublishers(publisherList)
        return (
          data?.filter((c) => {
            if (publisher == '0') {
              return true
            } else {
              return c.publisher == publisher
            }
          }) ?? emptyArray
        )
      },
    )
  }, [publisher])
  const { frames, isFetching, refetch } = useGetFramesQuery(
    { order, tag },
    {
      skip: !{ order, tag },
      selectFromResult: (result) => ({
        ...result,
        frames: filterIt(result),
      }),
    },
  )
  return (
    <div>
      {isFetching && <Fetching />}
      {!frames && <EmptyDatas />}
      <div className="d-flex align-items-center my-2">
        <Select
          name={'order'}
          value={order}
          datas={orders}
          onChange={(e) => setOrder(e)}
        />
        <Button className="mx-2" variant="outline-primary" onClick={refetch}>
          Refetch
        </Button>
      </div>
      {frames && (
        <Frames
          frames={frames}
          refetch={refetch}
          setPublisher={setPublisher}
          publishers={publishers}
        />
      )}
    </div>
  )
}

const Frames = ({ frames, refetch, publishers, setPublisher }) => {
  return (
    <table className="table table-hover">
      <thead className="table-secondary">
        <tr>
          <th scope="col">#</th>
          <th scope="col">Preview</th>
          <th scope="col">Cover</th>
          <th scope="col">ID</th>
          <th scope="col">Info</th>
          <th scope="col">Intro</th>
          <th scope="col">
            <Select datas={publishers} onChange={(e) => setPublisher(e)} />
          </th>
          <th scope="col">Level</th>
          <th scope="col">Tags</th>
          <th scope="col">Labels</th>
          <th scope="col">Avatars</th>
          <th scope="col">createTime</th>
          <th scope="col">Options</th>
        </tr>
      </thead>
      <tbody>
        {frames.map((frame, index) => (
          <tr key={frame.id}>
            <Item index={index} frame={frame} refetch={refetch} />
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const Item = ({ frame, index, refetch }) => {
  const [update] = useUpdateFrameMutation()

  const stickyTop = () => {
    update({ id: frame.id, body: { key: 'stickyTop' } })
      .unwrap()
      .then(() => {
        refetch()
      })
  }

  // const setasPush = () => {
  //   update({ id: frame.id, body: { key: "pushResource" } }).unwrap();
  // };

  return (
    <>
      <td>{index + 1}</td>
      <td>
        {frame.preview && <img width={96} src={frame.preview} alt="..." />}
      </td>
      <td>{frame.cover && <img width={96} src={frame.cover} alt="..." />}</td>
      <td>
        <UpdateName frame={frame} entityKey="name" />
        <CopyedCode copyed={frame.id}>{frame.id}</CopyedCode>
      </td>
      <td>
        layers:<code>{frame.layerCount}</code>
        <br />
        cpts:<code>{frame.componentCount}</code>
      </td>
      <td>
        <UpdateIntro frame={frame} entityKey="intro" />
      </td>
      <td>
        <UpdatePublisher frame={frame} />
      </td>
      <td>
        <UpdateAsSelect
          data={frame}
          entityKey="level"
          datas={[
            { code: '0', name: 'Miss Level' },
            { code: '1', name: 'SUPER' },
            { code: '2', name: 'Special' },
            { code: '4', name: 'PRO' },
            { code: '4.1', name: 'Android-PRO' },
            { code: '4.2', name: 'IOS-PRO' },
            { code: '5', name: 'NEW' },
          ]}
        />
      </td>
      <td>
        <RenderTags frame={frame} />
      </td>
      <td>
        <RenderLabels frame={frame} />
      </td>
      <td>
        <div>
          <Link to={'/avatars?tid=' + frame.id}>
            avatars:{frame.avatarCount}
          </Link>
        </div>
        <div>
          recreate:{frame.reCreateCount}/{frame?.dailyCount?.reCreateCount}
        </div>
        <div>like:{frame?.likeCount}</div>
      </td>
      <td>
        <DisplayUpdateTime updateTime={frame.createTime} />
      </td>
      <td>
        <Button className="m-1" variant="outline-success" onClick={stickyTop}>
          Sticky on top
        </Button>

        {/* {!frame.pushResource && (
          <Button className="m-1" variant="outline-success" onClick={setasPush}>
            Set as push
          </Button>
        )} */}
        <Link
          className="m-1 btn btn-outline-success"
          to={`/editor/` + frame.id + '?r=1'}
        >
          Go to Editor
        </Link>
        <ClearTemplate tid={frame.id} />
        <Link
          className="m-1 btn btn-outline-success"
          to={`/detail/` + frame.id}
        >
          Go to Detail
        </Link>
      </td>
    </>
  )
}

export const RenderLabels = ({ frame }) => {
  return (
    <>
      <Confirm confirmText="Done" body={<LabelsBody frame={frame} />}>
        <Badge className="me-1" bg="success">
          + -
        </Badge>
      </Confirm>
      {frame?.labels?.map((label) => (
        <Badge className="me-1" key={label}>
          <small>{label}</small>
        </Badge>
      ))}
    </>
  )
}

export const RenderTags = ({ frame }) => {
  return (
    <>
      <Confirm confirmText="Done" body={<TagsBody frame={frame} />}>
        <Badge className="me-1" bg="success">
          + -
        </Badge>
      </Confirm>
      {frame?.tags?.map((tag) => (
        <Badge className="me-1" key={tag}>
          <small>{tag}</small>
        </Badge>
      ))}
    </>
  )
}

const TagsBody = ({ frame }) => {
  const [update] = useUpdateFrameMutation()
  const tags = useSelector((state) => state.tagLibs)

  const handleRemove = (tag) =>
    update({ id: frame.id, body: { key: 'removeTag', value: tag } })

  const handleAdd = (tag) =>
    update({ id: frame.id, body: { key: 'addTag', value: tag.name } })

  return (
    <div>
      {frame?.tags?.map((tag) => (
        <Badge
          bg="primary"
          className="me-2 mt-2"
          key={tag}
          onClick={() => handleRemove(tag)}
        >
          {tag}
        </Badge>
      ))}

      <hr />
      {Object.keys(tags)?.map(
        (id) =>
          !frame?.tags?.includes(tags[id].name) && (
            <Badge
              bg="secondary"
              className="me-2 mt-2"
              key={id}
              onClick={() => handleAdd(tags[id])}
            >
              {tags[id].name}-{tags[id].note}
            </Badge>
          ),
      )}
    </div>
  )
}

const LabelsBody = ({ frame }) => {
  const datas = ['Non-commercial', 'Personal', 'OC', 'Custom', 'DLC']
  const [update] = useUpdateFrameMutation()
  const handleRemove = (tag) =>
    update({ id: frame.id, body: { key: 'removeLabel', value: tag } })

  const handleAdd = (tag) =>
    update({ id: frame.id, body: { key: 'addLabel', value: tag } })

  return (
    <div>
      {frame?.labels?.map((label) => (
        <Badge
          bg="primary"
          className="me-2 mt-2"
          key={label}
          onClick={() => handleRemove(label)}
        >
          {label}
        </Badge>
      ))}

      <hr />
      {datas.map(
        (label) =>
          !frame?.labels?.includes(label) && (
            <Badge
              bg="secondary"
              className="me-2 mt-2"
              key={label}
              onClick={() => handleAdd(label)}
            >
              {label}
            </Badge>
          ),
      )}
    </div>
  )
}

const UpdatePublisher = ({ frame }) => {
  const [show, setShow] = useState(false)
  const [update] = useUpdateFrameMutation()
  const handleUpdateName = (e) => {
    if (!e.target.value) {
      return
    }
    if (e.target.value === frame.publisher) {
      setShow(false)
      return
    }

    update({ id: frame.id, body: { key: 'publisher', value: e.target.value } })
      .unwrap()
      .then((res) => {
        setShow(false)
      })
  }
  if (show) {
    return (
      <input
        type="text"
        defaultValue={frame.publisher}
        onBlur={handleUpdateName}
      />
    )
  }
  return <div onClick={() => setShow(true)}>@{frame.publisher}</div>
}

const UpdateName = ({ frame, entityKey }) => {
  const [show, setShow] = useState(false)
  const [update] = useUpdateFrameMutation()
  const handleUpdate = (e) => {
    if (!e.target.value) {
      return
    }
    if (e.target.value === frame[entityKey]) {
      setShow(false)
      return
    }

    update({ id: frame.id, body: { key: entityKey, value: e.target.value } })
      .unwrap()
      .then((res) => {
        setShow(false)
      })
  }
  if (show) {
    return (
      <input
        type="text"
        defaultValue={frame[entityKey]}
        onBlur={handleUpdate}
      />
    )
  }
  return (
    <p className="fw-bold" onClick={() => setShow(true)}>
      {frame[entityKey]}
    </p>
  )
}

const UpdateIntro = ({ frame, entityKey }) => {
  const [show, setShow] = useState(false)
  const [update] = useUpdateFrameMutation()
  const handleUpdate = (e) => {
    if (!e.target.value) {
      return
    }
    if (e.target.value === frame[entityKey]) {
      setShow(false)
      return
    }

    update({ id: frame.id, body: { key: entityKey, value: e.target.value } })
      .unwrap()
      .then((res) => {
        setShow(false)
      })
  }
  if (show) {
    return (
      <textarea
        rows="5"
        defaultValue={frame[entityKey]}
        onBlur={handleUpdate}
      />
    )
  }
  return (
    <pre className="text_wrap_pre_line" onClick={() => setShow(true)}>
      {frame[entityKey]}
    </pre>
  )
}

const ClearTemplate = ({ tid }) => {
  console.log(tid)
  const [clear, { isLoading }] = useClearTemplateMutation()
  const handleClick = () => {
    clear(tid).unwrap()
  }

  return (
    <Link
      disabled={isLoading}
      variant="outline-light"
      // className={"submit"}
      className="m-1 btn btn-outline-success"
      onClick={handleClick}
    >
      <div className="flex-center">
        {isLoading ? <Spinner size="sm" /> : <span>Clear cache</span>}
      </div>
    </Link>
  )
}

export default Onlines
