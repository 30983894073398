import React from "react";
import { useParams } from "react-router-dom";
import Cpt from "./Cpt";
import Accordion from "react-bootstrap/Accordion";
import { useCptsWithoutDlc } from "screens/Editor/hooks";

const CptsWithoutDlc = () => {
  const { tid } = useParams();
  const { components } = useCptsWithoutDlc();
  if (components.length === 0) {
    return null;
  }
  return (
    <Accordion.Item eventKey={tid}>
      <Accordion.Header>
        <div className="fw-bold">
          Miss DLC, please add DLC - ({components.length})
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 g-1 row-cols-xxl-5 ">
          {components?.map((component) => (
            <Cpt
              key={component.id}
              cpt={component}
              allComponents={components}
            />
          ))}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default CptsWithoutDlc;
