import mitt from "mitt";

const emitter = mitt();
export let tonings = {};
export let drawers = [];

emitter.on(`resetCanvas`, (e) => {
  if (!e) return;
  drawers = [];
  tonings = {};
});

emitter.on(`resetLayerFromCanvas`, (e) => {
  if (!e) return;
  const lid = e;
  drawers = drawers.filter((drawer) => drawer.lid !== lid);
  emitter.emit(`renderCpt`);
});

emitter.on(`selectCpt`, (payload) => {
  const { layer, cpt } = payload;
  drawers = drawers.filter((d) => d.lid !== layer.id);
  // console.log(layer,  cpt);
  const drawer = {
    cid: cpt.id,
    lid: layer.id,
    x: cpt.crop.x,
    y: cpt.crop.y,
    w: cpt.crop.w,
    h: cpt.crop.h,
    z: layer.z,
  };

  if (layer.toning) {
    drawer.t = layer.toning;
  }
  drawers.push(drawer);
});

emitter.on(`setTonings`, (payload) => {
  const renders = payload;
  tonings = renders
  emitter.emit(`renderCpt`);
});

emitter.on(`setDrawers`, (payload) => {
  const renders = payload;
  drawers = renders
  emitter.emit(`renderCpt`);
});

emitter.on(`unSelectCpt`, (payload) => {
  const cpt = payload;
  drawers = drawers.filter((d) => d.cid !== cpt.id);
});

emitter.on(`selectColor`, (e) => {
  if (!e) return;

  let { toningId, color, lid = "default" } = e;
  const data = {
    id: color.id,
    toning: color.colors.map((t) => ({ c: t.color, o: t.offset })),
  };
  if (tonings[toningId]) {
    let toning = tonings[toningId]
    toning[lid] = data
    tonings[toningId] = toning;
  } else {
    tonings[toningId] = { [lid]: data };
  }
});

emitter.on(`unSelectColor`, (e) => {
  if (!e) return;
  const toningId = e;
  delete tonings[toningId];
});

export default emitter;
