import React from "react";
import Cpt from "./Cpt";
import Accordion from "react-bootstrap/Accordion";
import { useCptsWithDlc } from "screens/Editor/hooks";
import BatchUpload from "../BatchUpload";
import { useRole } from "hooks/auth";

const CptsInDlc = ({ dlc }) => {
  const isView = useRole('viewer')
  const { cpts } = useCptsWithDlc(dlc.id);

  return (
    <Accordion.Item
      eventKey={dlc.id}
      className="border border-start-0 border-end-0 rounded-0"
    >
      <Accordion.Header className="overflow-hidden rounded-0">
        <div className="flex-shrink-0 me-1">
          <img width={24} src={dlc.cover} alt="..." />
        </div>
        <div className="fw-bold">
          {dlc.name}({cpts.length})
        </div>
      </Accordion.Header>
      <Accordion.Body>
        <div className="row row-cols-2 row-cols-md-3 row-cols-xl-4 gy-1 row-cols-xxl-5 ">
          {cpts?.map((cpt) => (
            <Cpt key={cpt.id} cpt={cpt} />
          ))}
          {!isView && <BatchUpload dlc={dlc.id} />}
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default CptsInDlc;
