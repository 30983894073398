export const useHexToRgba = (hex) => {
  const num = parseInt(hex.slice(1), 16); // Convert to a number
  const nums = [
    (num >> 16) & 255,
    (num >> 8) & 255,
    num & 255,
    (num >> 24) & 255,
  ];

  return {
    color: `rgb(${nums[0]}, ${nums[1]}, ${nums[2]})`,
    opacity: nums[3],
  };
};

export const hexToRgba = (hex) => {
  const num = parseInt(hex.slice(1), 16); // Convert to a number
  const nums = [
    (num >> 16) & 255,
    (num >> 8) & 255,
    num & 255,
    (num >> 24) & 255,
  ];

  return {
    color: `rgb(${nums[0]}, ${nums[1]}, ${nums[2]})`,
    opacity: nums[3],
  };
};

export const useRgbToRgba = (rgb, a = 1) => {
  const rgba = rgb.replace("rgb(", "rgba(").replace(")", `, ${a})`);
  return rgba;
};

export const rgbToRgba = (rgb, a = 1) =>
  rgb.replace("rgb(", "rgba(").replace(")", `, ${a})`);

export const useRgbaToHex = (rgba) => {
  const rgb = rgba.color.match(/[\d.]{1,3}/g);
  if (!rgb) throw new Error();
  const a =
    +rgba.opacity === 1 ? "" : Math.round(rgba.opacity * 255).toString(16);
  return `#${rgb.map((c) => (+c).toString(16).padStart(2, "0")).join("")}${a}`;
};

export const rgbaToHex = (rgba) => {
  const rgb = rgba.color.match(/[\d.]{1,3}/g);
  const a =
    +rgba.opacity === 1 ? "" : Math.round(rgba.opacity * 255).toString(16);
  if (!rgb) throw new Error();
  return `#${rgb.map((c) => (+c).toString(16).padStart(2, "0")).join("")}${a}`;
};

export const rgbToHex = (rgb) => {
  const ret = rgb.match(/[\d.]{1,3}/g);
  if (!ret) throw new Error();
  return `#${ret.map((c) => (+c).toString(16).padStart(2, "0")).join("")}`;
};
