//React entry point 会自动根据endpoints生成hooks
import { cacher } from "api/rtkQueryCacheUtils";
import { neku } from "./index";

const updateFn = (layer, body) => {
  const { key, value } = body;
  if (layer) {
    if (key === "maxChoose") {
      layer.max = value;
    } else if (key === "zIndex") {
      layer.z = value;
    } else if (key === "optional") {
      layer.opt = value;
    } else if (key === "initCover") {
      if (value?.name) {
        layer.name = value.name;
      }
      if (value?.p) {
        layer.playIndex = value.p;
      }
      if (value?.z) {
        layer.z = value.z;
      }
      if (value?.code) {
        layer.cover = value?.code;
      }
    } else {
      layer[key] = value;
    }
  }
};

const optimisticUpdate = async (
  id,
  body,
  dispatch,
  queryFulfilled,
  getState
) => {
  const { tid } = body;
  const updates = [];

  updates.push(
    dispatch(
      layersApi.util.updateQueryData("getLayer", { tid, id }, (layer) => {
        updateFn(layer, body);
      })
    )
  );

  for (const {
    endpointName,
    originalArgs,
  } of layersApi.util.selectInvalidatedBy(getState(), [
    { type: "Layer", id: id },
  ])) {
    if (endpointName === "getLayers") {
      updates.push(
        dispatch(
          layersApi.util.updateQueryData(
            endpointName,
            originalArgs,
            (layers) => {
              const layer = layers.find((layer) => layer.id === id);
              updateFn(layer, body);
            }
          )
        )
      );
    }
  }

  try {
    await queryFulfilled;
  } catch {
    for (const u of updates) {
      u.undo();
    }
  }
};

//使用base URL 和endpoints 定义服务
const layersApi = neku.injectEndpoints({
  endpoints: (builder) => ({
    getLayers: builder.query({
      query: (params) => ({ url: `/layers`, method: "GET", params }),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("Layer"),
    }),
    getLayerGroups: builder.query({
      query: (params) => ({ url: `/layers/groups`, method: "GET", params }),
      keepUnusedDataFor: 24 * 60 * 60,
    }),
    getLayer: builder.query({
      query: ({ tid, id }) => ({
        url: `/layers/${id}`,
        method: "GET",
        params: { tid },
      }),
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.cacheByIdArgProperty("Layer"),
    }),
    createLayer: builder.mutation({
      query: (body) => ({ url: `layers`, method: "POST", body }),
      invalidatesTags: cacher.invalidatesList("Layer"),
    }),
    createLayerGroup: builder.mutation({
      query: (body) => ({ url: `layers/group`, method: "POST", body }),
    }),
    updateLayer: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return {
          url: `/layers/${id}`,
          method: "PUT",
          body,
        };
      },
      async onQueryStarted(
        { id, body },
        { dispatch, queryFulfilled, getState }
      ) {
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
    updateLayerGroup: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return {
          url: `/layers/group/${id}`,
          method: "PUT",
          body,
        };
      },
      // async onQueryStarted(
      //   { id, body },
      //   { dispatch, queryFulfilled, getState }
      // ) {
      //   optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      // },
    }),
    deleteLayer: builder.mutation({
      query: ({ id, tid }) => ({
        url: `/layers/${id}`,
        method: "DELETE",
        body: { tid },
      }),
      invalidatesTags: cacher.invalidatesList("Layer"),
    }),
    deleteLayerGroup: builder.mutation({
      query: ({ id, tid }) => ({
        url: `/layers/group/${id}`,
        method: "DELETE",
        body: { tid },
      })
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const {
  useGetLayersQuery,
  useGetLayerGroupsQuery,
  useGetLayerQuery,
  useCreateLayerMutation,
  useCreateLayerGroupMutation,
  useUpdateLayerMutation,
  useUpdateLayerGroupMutation,
  useDeleteLayerMutation,
  useDeleteLayerGroupMutation
} = layersApi;
export default layersApi;
