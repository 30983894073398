import React, { useState, useEffect, useRef } from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CanvasCore from "../CanvasCore";
import { setCapture } from "../uiSlice";
import Overlay from "./Overlay";
import { useParams } from "react-router-dom";

const CanvasPanel = () => {
  const stageRef = useRef();

  return (
    <div className="canvas-panel position-relative">
      <div className="d-flex justify-content-center">
        <CanvasCore stageRef={stageRef} />
      </div>
      <Overlay />
      <CaptureIt stageRef={stageRef} />
    </div>
  );
};

const CaptureIt = ({ stageRef }) => {
  const { tid } = useParams();
  const [data, setData] = useState();
  const captureSource = useSelector((state) => state.ui.editor[tid].captureSource);
  const showUpload = useSelector((state) => state.ui.editor[tid].showUpload);
  const dispatch = useDispatch();
  const drawers = useSelector((state) => state.ui.editor[tid].drawers);
  const getDataURL = useCallback(() => {
    return stageRef.current?.toDataURL({
      mimeType: "image/png",
      quality: 1,
    });
  }, [stageRef]);
  useEffect(() => {
    setData({ canvas: { w: 512, h: 512 }, drawers });
  }, [drawers]);

  useEffect(() => {
    if (captureSource || showUpload) {
      const avatar = getDataURL();
      if (avatar) {
        dispatch(setCapture({capture:avatar, tid}));
      }
    }
  }, [dispatch, captureSource,showUpload, getDataURL]);

  if (!data) {
    return null;
  }
  return null;
};

export const random = (range) => {
  return Math.floor(Math.random() * range) + 1;
};

export default CanvasPanel;
