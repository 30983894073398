import React from "react";
import { useParams } from "react-router-dom";
import { useUpdateLayerMutation } from "api/neku/layers";
import "./index.scss";

const UpdateOpt = ({ layer }) => {
  const { tid } = useParams();
  const [update] = useUpdateLayerMutation();

  const handleChange = (value) => {
    update({
      id: layer.id,
      body: { tid, key: "optional", value },
    });
  };

  return (
    <span className="ms-2 d-flex align-items-center">
      <input
        className={`form-check-input me-2 mt-0 rounded-circle layerCheck`}
        checked={!layer.opt}
        onChange={(e) => {
          handleChange(!e.target.checked);
        }}
        type="checkbox"
        style={{
          backgroundColor: !layer.opt && "#FF3B63",
        }}
      />
      <span>Required</span>
    </span>
  );
};

export default UpdateOpt;
