import React from "react";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useRole } from "hooks/auth";
import Main_welcome from "assets/Main_welcome.svg";
import bg_welcome_white from "assets/bg_welcome_white.svg";
import Star from "assets/Star.svg";
import button_apple from "assets/button_apple.svg";
import button_google from "assets/button_google.svg";
import Nav from "components/nav/Nav";
import HomeNav from "./Nav";
import styles from "./index.module.scss";

const Home = () => {
  const isAdmin = useRole(`admin`);
  if (isAdmin) return <Navigate to={"/admin"} replace />;
  return (
    <div className={styles.home}>
      {isAdmin ? <Nav /> : <HomeNav />}
      <div className={styles.homeBg} />
      <img src={bg_welcome_white} className={styles.bg_welcome_white} alt="" />
      <img src={Main_welcome} className={styles.homeBody} alt="" />
      <img src={Star} className={styles.Star} alt="" />
      <div className={styles.menu}>
        <h1 className={styles.title}>NeKu</h1>
        <h2 className={styles.Subheading}>Avatar maker</h2>
        <div className={styles.text}>Join us to expand your imagination.</div>
        <div className={styles.text}>Share your artwork with everyone.</div>
        <div className={styles.text}>
          Create OC generators with your own designs.
        </div>
        <div className={styles.btn}>
          <a href="https://apps.apple.com/cn/app/neku-oc-avatar-maker/id1630343674">
            <img src={button_apple} className="cursor-pointer" alt="" />
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.stickermobi.avatarmaker">
            <img src={button_google} className="cursor-pointer" alt="" />
          </a>
        </div>
        <Link to="personal">
          <button className={styles.getStarted}>GET STARTED</button>
        </Link>
      </div>
    </div>
  );
};

export default Home;
