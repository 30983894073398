import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Layer from "./Layer";

import { Accordion } from "react-bootstrap";

import "../index.scss";
import { useLayers } from "../../hooks";
import { selectLayer } from "../../uiSlice";
import { useEffect } from "react";
import { useParams } from "react-router-dom";

const Layers = () => {
  const { tid } = useParams();
  const sortType = useSelector((state) => state.ui.editor[tid].sortType);
  const { layers } = useLayers(sortType);
  const lid = useSelector((state) => state.ui.editor[tid].lid);
  const dispatch = useDispatch();

  //init default layer id
  useEffect(() => {
    if (!lid && layers && layers[0]) {
      dispatch(selectLayer({lid: layers[0]?.id, tid}));
    }
  }, [lid, layers, dispatch]);

  return (
    <div className="d-flex flex-column h-100 overflow-auto mt-1">
      <Accordion activeKey={lid} alwaysOpen>
        {layers.map((layer) => (
          <Layer key={layer.id} layer={layer} />
        ))}
      </Accordion>
    </div>
  );
};

export default Layers;
