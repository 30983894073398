import React from "react";
import { useUpdateFrameMutation } from "api/neku/frames";
import Select from "ui/Select";

const UpdateAsSelect = ({ data, entityKey, datas }) => {
  const [update] = useUpdateFrameMutation();

  const handleUpdate = (v) => {
    if (!v) return;
    update({
      id: data.id,
      body: { key: entityKey, value: v },
    }).unwrap();
  };

  return (
    <Select value={data[entityKey]} datas={datas} onChange={handleUpdate} />
  );
};

export default UpdateAsSelect;
