import React from "react";

import { useSelector } from "react-redux";
import { LayerPanel } from "./LayerPanel";
import ToningPanel from "./ToningPanel";
import { useParams } from "react-router-dom";
const BottomPanel = () => {
  const { tid } = useParams();
  const currTab = useSelector((state) => state.ui.editor[tid].currTab);
  if (currTab === "tonings") {
    return <ToningPanel />;
  }
  return <LayerPanel />;
};
export default BottomPanel;
