//React entry point 会自动根据endpoints生成hooks
import { cacher } from "api/rtkQueryCacheUtils";
import { utils } from "api/rtkQueryUtils";
import { neku } from "./index";

const updateFn = (draft, body) => {
  const { key, value } = body;
  if (draft) {
    if (key === "stickyTop") {
      draft.updateTime = Date.now();
    } 
    // else if (key === "pushResource") {
    //   draft.pushResource = Date.now();
    // } 
    else if (key === "removeTag") {
      draft.tags = draft.tags.filter((tag) => tag !== value);
    } else if (key === "addTag") {
      if (!draft.tags) {
        draft.tags = [value];
      } else if (draft.tags.includes(value)) {
      } else {
        draft.tags.push(value);
      }
    } else if (key === "removeLabel") {
      if (draft.labels) {
        draft.labels = draft.labels.filter((label) => label !== value);
      }
    } else if (key === "addLabel") {
      if (!draft.labels) {
        draft.labels = [value];
      } else if (draft.labels.includes(value)) {
      } else {
        draft.labels.push(value);
      }
    } else {
      draft[key] = value;
    }
  }
};

const optimisticUpdate = async (
  id,
  body,
  dispatch,
  queryFulfilled,
  getState
) => {
  const updates = [];
  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    getState(),
    [{ type: "Frame", id: id }]
  )) {
    console.log(id);
    if (endpointName === "getFrames") {
      updates.push(
        dispatch(
          api.util.updateQueryData(endpointName, originalArgs, (drafts) => {
            const draft = drafts.find((draft) => draft.id === id);
            updateFn(draft, body);
          })
        )
      );
    }
  }

  try {
    await queryFulfilled;
  } catch {
    for (const u of updates) {
      u.undo();
    }
  }
};

//使用base URL 和endpoints 定义服务
const api = neku.injectEndpoints({
  endpoints: (builder) => ({
    getFrames: builder.query({
      query: (params) => ({ url: "frames", method: "GET", params }),
      keepUnusedDataFor: 15 * 60,
      providesTags: cacher.providesList("Frame"),
    }),
    getFrame: builder.query({
      query: (id) => ({ url: `frames/${id}`, method: "GET" }),
      keepUnusedDataFor: 15 * 60,
    }),
    getFrameDaily: builder.query({
      query: (params) => ({ url: "frames/daily", method: "GET", params }),
      keepUnusedDataFor: 15 * 60,
    }),
    getDailyCount: builder.query({
      query: ({tid, params}) => {
        return { url: `frames/${tid}/daily`, method: "GET", params }
      },
      keepUnusedDataFor: 15 * 60,
    }),
    updateFrame: builder.mutation({
      query: (data) => {
        const { id, body } = data;
        return utils.PUT(`frames/${id}`, body);
      },
      async onQueryStarted(
        { id, body },
        { dispatch, queryFulfilled, getState }
      ) {
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const { useGetFramesQuery, useUpdateFrameMutation, useGetFrameQuery, useGetFrameDailyQuery, useGetDailyCountQuery } = api;
export default api;
