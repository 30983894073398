import { ContentLangs } from "config/index";
// import prettyMilliseconds from "pretty-ms";

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export const useContetLang = (contentLang) => {
  return (
    ContentLangs.find((lang) => lang.code === contentLang) || {
      code: contentLang,
      name: "unknow",
      short: "unknow",
    }
  );
};

export const useBucketMap = (lang) => {
  if (lang === 3) {
    return [3, 17, 21];
  } else if (lang === 4) {
    return [4];
  } else if (lang === 17) {
    return [3, 17, 21];
  } else if (lang === 21) {
    return [3, 17, 21];
  } else if (lang === 24) {
    return [24];
  } else {
    return [lang];
  }
};
export const usePrettyLastMs = (timeStamp) => {
  const offset = dayjs(timeStamp).toNow();
  // const now = dayjs().valueOf();
  // const offset = now - timeStamp;
  // console.log(now, offset, timeStamp);
  return offset;
  // return prettyMilliseconds(offset);
};
