import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import logoImg from "assets/logo.png";
import userImg from "assets/user_avatar.png";
import { logout } from "api/neku/datas/authSlice";
import styles from "./index.module.scss";

const Nav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.auth?.user);

  const onLogout = () => {
    dispatch(logout());
    navigate(`/login`);
  };

  const onPersonal = () => {
    navigate(`/personal`);
  };

  if (!isLoggedIn) {
    return null;
  }

  return (
    <header className={`navbar ${styles.nav}`} style={{ zIndex: 13 }}>
      <Link
        to="/"
        className="d-flex align-items-center"
        style={{ textDecoration: "none" }}
      >
        <img src={logoImg} alt="" className={styles.logo} />
        <div className={styles.logo_text + " me-4"}>NeKu</div>
      </Link>
      {/* <div className={`pe-4 ${styles.user}`}> */}
      <Dropdown>
        <Dropdown.Toggle className={`p-0 ${styles.user}`}>
          <img src={userImg} className="w-100 h-100" alt="" />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item onClick={onPersonal}>Personal center</Dropdown.Item>
          <Dropdown.Item onClick={onLogout}>Log out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      {/* <Link
          className="btn btn-bd-download d-lg-inline-block my-2 my-md-0 ms-md-3"
          to="/login"
          onClick={onLogout}
        >
          {currentUser.username} Log Out
        </Link> */}
      {/* </div> */}
    </header>
  );
};

export default Nav;
