import { createSelector, createSlice } from "@reduxjs/toolkit";
import tagLibs from "api/neku/taglibs";

const initialState = {};

const slice = createSlice({
  name: "tagLibs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(
        tagLibs.endpoints.getTagLibs.matchFulfilled,
        (state, action) => {
          const datas = action.payload;
          datas?.forEach((item) => {
            state[item.id] = item;
          });
        }
      )
      .addMatcher(
        tagLibs.endpoints.deleteTagLibs.matchFulfilled,
        (state, action) => {
          const { id } = action.payload;
          delete state[id];
        }
      )
  },
});

export default slice.reducer;

export const keywordSelector = createSelector(
  (state) => state.taglibs,
  (_, keyword) => keyword.toLowerCase(),
  (taglibs, keyword) => {
    const tags = Object.values(taglibs);
    return tags.filter((tag) => {
      const tagName = tag.name?.toLowerCase() ?? "";
      return tagName.includes(keyword);
    });
  }
);