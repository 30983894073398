import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAddToningMutation, useGetToningsQuery } from "api/neku/tonings";

import { ArrowClockwise, PlusLg } from "react-bootstrap-icons";
import Toning from "./Toning";
import { Accordion } from "react-bootstrap";
import Spinner from "components/Spinner";
import { useTonings, useCustomParams } from "screens/Editor/hooks";
import { selectToning } from "screens/Editor/uiSlice";

import "../index.scss";

const Tonings = () => {
  return (
    <div className="mt-1">
      <DisplayDatas />
    </div>
  );
};

export const ToningsActions = () => {
  return (
    <div>
      <RefreshButton />
      <CreateButton />
    </div>
  );
};

const RefreshButton = () => {
  const params = useCustomParams();
  const { refetch, isFetching } = useGetToningsQuery(params, { skip: !params });

  if (isFetching) return <Spinner className="me-3" />;
  return <ArrowClockwise onClick={refetch} className="icon me-3" />;
};

const CreateButton = () => {
  const { tid } = useParams();
  const [create, { isLoading }] = useAddToningMutation();

  const handleCreate = () => {
    create({ tid }).unwrap();
  };
  if (isLoading) return <Spinner />;
  return <PlusLg onClick={handleCreate} className="icon" />;
};

const DisplayDatas = () => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const toningId = useSelector((state) => state.ui.editor[tid].toningId);
  const { data: tonings } = useTonings();

  //init default toning id
  useEffect(() => {
    if (!toningId && tonings && tonings[0]) {
      dispatch(selectToning({ toningId: tonings[0]?.id, tid }));
    }
  }, [toningId, tonings, dispatch]);

  if (!tonings || tonings.length <= 0) return <h1>no data..</h1>;

  return (
    <Accordion activeKey={toningId}>
      {tonings.map((toning) => (
        <Toning key={toning.id} toning={toning} />
      ))}
    </Accordion>
  );
};

export default Tonings;
