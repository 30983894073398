import React, { useState } from "react";
import {
  useGetPublishersQuery,
  useUpdatePubliserMutation,
} from "api/neku/publishers";
import EmptyDatas from "ui/EmptyDatas";
import Fetching from "ui/Fetching";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loginAsFace } from "api/neku/datas/authSlice";

const Publishers = () => {
  const { data, isLoading, refetch } = useGetPublishersQuery();
  if (!data) return <EmptyDatas />;
  if (isLoading) return <Fetching />;
  return (
    <div>
      <Button onClick={refetch}>Refetch</Button>
      <table className="table table-hover">
        <thead className="table-secondary">
          <tr>
            <th scope="col">#</th>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Templates</th>
            <th scope="col">Avatars</th>
            <th scope="col">Opts</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((publisher, index) => (
            <Item key={publisher.id} publisher={publisher} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const Item = ({ publisher, index }) => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(loginAsFace(publisher));
  };
  return (
    <tr>
      <td>#{index}</td>
      <td>{publisher.id}</td>
      <td>
        <UpdatePublisher publisher={publisher} />
      </td>
      <td>
        <Link to={`/admin?author=` + publisher.id}>Templates</Link>
      </td>
      <td>
        <Link to={`/avatars?author=` + publisher.id}>Avatars</Link>
      </td>
      <td>
        <Button variant="outline-success" onClick={handleClick}>
          Login As
        </Button>
      </td>
    </tr>
  );
};

const UpdatePublisher = ({ publisher }) => {
  const [show, setShow] = useState(false);
  const [update] = useUpdatePubliserMutation();
  const handleUpdateName = (e) => {
    if (!e.target.value) {
      setShow(false);
      return;
    }

    if (e.target.value === publisher.nick_name) {
      setShow(false);
      return;
    }

    update({
      id: publisher.id,
      body: { key: "nick_name", value: e.target.value },
    })
      .unwrap()
      .then((res) => {
        setShow(false);
      });
  };
  if (show) {
    return (
      <input
        type="text"
        defaultValue={publisher.nick_name}
        onBlur={handleUpdateName}
      />
    );
  }
  return <div onClick={() => setShow(true)}>@{publisher.nick_name}</div>;
};

export default Publishers;
