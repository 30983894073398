import React from "react";
import { NavLink } from "react-router-dom";
import "./index.scss";

const TabItem = ({ to, name, end }) => (
  <li className="c-tab mx-2">
    <NavLink to={to} end={end} className="c-tab-link">
      {({ isActive }) =>
        isActive ? (
          <>
            <span className="title-active">{name}</span>
            <div className="w-100 mt-1">
              <div className="line-active" />
            </div>
          </>
        ) : (
          <>
            <span>{name}</span>
            <div className="w-100 mt-1"></div>
          </>
        )
      }
    </NavLink>
  </li>
);

const Tabs = ({ datas }) => {
  return (
    <ul className="nav nav-pills shadow-sm">
      {datas.map(({ name, to, end }) => (
        <TabItem key={to} to={to} name={name} end={end} />
      ))}
    </ul>
  );
};

export default Tabs;
