import React from "react";
import { useParams } from "react-router-dom";
import { useUpdateLayerGroupMutation, useUpdateLayerMutation } from "api/neku/layers";
import "./index.scss";

const UpdatePlayIndex = ({ layer, type, refetch }) => {
  const { tid } = useParams();
  const [update] = useUpdateLayerMutation();
  const [updateGroup] = useUpdateLayerGroupMutation();
  const items = Array.from({ length: 200 }, (v, i) => i);

  const onChange = (value) =>{
    if(type == 'layerGroup') {
      updateGroup({
        id: layer.id,
        body: { tid, key: "playIndex", value: Number(value) },
      }).then(res => {
        refetch()
      })
      return 
    }
    update({
      id: layer.id,
      body: { tid, key: "playIndex", value: Number(value) },
    });
  }

  return (
    <select
      className={`rounded-3 me-1 py-2 select`}
      value={layer.playIndex}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">Play order</option>
      {items.map((v) => (
        <option key={v} value={v}>
          Play order: {v}
        </option>
      ))}
    </select>
  );
};

export default UpdatePlayIndex;
