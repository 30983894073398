import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useCreateLayerMutation, useGetLayersQuery } from "api/neku/layers";

import Spinner from "components/Spinner";

import { toggleSortType, selectLayer } from "../../uiSlice";
import { useCustomParams } from "../../hooks";

import {
  ArrowClockwise,
  PlusLg,
  SortNumericUp,
  SortNumericDown,
} from "react-bootstrap-icons";

const Menus = () => {
  return (
    <div>
      <SortButton />
      <RefreshButton />
      <CreateButton />
    </div>
  );
};

export const SortButton = () => {
  const {tid} = useParams()
  const dispatch = useDispatch();
  const sortType = useSelector((state) => state.ui.editor[tid].sortType);
  if (sortType === `z`) {
    return (
      <SortNumericUp
        className="icon me-3"
        onClick={() => dispatch(toggleSortType(tid))}
      />
    );
  }
  return (
    <SortNumericDown
      className="icon me-3"
      onClick={() => dispatch(toggleSortType(tid))}
    />
  );
};

export const RefreshButton = () => {
  const params = useCustomParams();
  const { refetch, isFetching } = useGetLayersQuery(params, { skip: !params });

  if (isFetching) return <Spinner className="me-3" />;
  return <ArrowClockwise onClick={refetch} className="icon me-3" />;
};

export const CreateButton = () => {
  const { tid } = useParams();

  const dispatch = useDispatch();

  const [create, { isLoading }] = useCreateLayerMutation();
  const handleClick = () => {
    if (!tid) return null;
    const data = {
      tid,
      name: "new layer",
      cover: "UWj",
    };
    create(data)
      .unwrap()
      .then((res) => {
        dispatch(selectLayer({lid: res.lid, tid}));
      });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return <PlusLg onClick={handleClick} className={"icon"} />;
};

export default Menus;
