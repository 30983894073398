import { createApi, fetchBaseQuery, retry } from "@reduxjs/toolkit/query/react";
import { cacher } from "../rtkQueryCacheUtils";
import { logout } from "api/neku/datas/authSlice";

const baseUrl =
  process.env.NODE_ENV === "production"
    ? "https://api.designer.neku.cc"
    : process.env.REACT_APP_NEKU_API;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().auth?.token;
    if (token) {
      headers.set("Authorization", `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithRetry = retry(
  async (args, api, extraOptions) => {
    const result = await baseQuery(args, api, extraOptions);

    // bail out of re-tries immediately if unauthorized,
    // because we know successive re-retries would be redundant
    if (result.error) {
      const { status } = result.error;
      if (status === 401) {
        api.dispatch(logout());
        retry.fail(result.error);
      } else if (status === 403) {
        retry.fail(result.error);
      } else if (status === 400) {
        retry.fail(result.error);
      }
    }
    return result;
  },
  { maxRetries: 5 }
);

export const neku = createApi({
  reducerPath: "neku",
  baseQuery: baseQueryWithRetry,
  keepUnusedDataFor: 60 * 15, //15 min
  refetchOnMountOrArgChange: 30 * 60, // 30 min
  tagTypes: [
    ...cacher.defaultTags,
    "Template",
    "Layer",
    "Component",
    "Dlc",
    "LayerIcon",
    "Toning",
    "Frame",
    "Publisher",
    "Taglib"
  ],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (data) => ({
        url: "login",
        method: "POST",
        body: data,
      }),
      keepUnusedDataFor: 60 * 60 * 24 * 2,
      invalidatesTags: cacher.invalidatesUnauthorized(),
    }),
  }),
});

export const { useLoginMutation } = neku;

export default neku;
