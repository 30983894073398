import React from "react";
import Card from "./Card";
import { Button } from "react-bootstrap";
import { toggleShowInfos } from "../uiSlice";
import { useDispatch } from "react-redux";

function DisplayAvatars({ avatars }) {
  const dispatch = useDispatch();

  return (
    <div className="data-area">
      <div className="d-flex gap-1 my-3">
        <Button
          onClick={() => dispatch(toggleShowInfos())}
          variant="outline-primary"
        >
          Show infos
        </Button>
      </div>

      <div className="row row-cols-1 row-cols-md-4 row-cols-xl-6 row-cols-xxl-9  g-1 g-xl-2">
        {avatars?.map((avatar, index) => (
          <Card avatar={avatar} key={avatar.id} index={index} />
        ))}
      </div>
    </div>
  );
}

export default DisplayAvatars;
