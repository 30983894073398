import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  showInfos: false,
};

const slice = createSlice({
  name: "avatars",
  initialState,
  reducers: {
    toggleShowInfos: (state) => {
      state.showInfos = !state.showInfos;
    },
  },
});

export const { toggleShowInfos } = slice.actions;
export default slice.reducer;
