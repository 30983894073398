import React, { useState } from "react";
// import { useLocation } from "react-router-dom";
import { Button } from "react-bootstrap";
import Confirm from "components/Confirm";
// import { useContetLang } from "hooks/config";
import DisplayUpdateTime from "ui/DisplayUpdateTime";
import { useDeleteWebImgMutation } from "api/neku/avatars";


const Card = ({ data, id, index }) => {
    // const data = useSelector((state) => state.webAvatars[id]);
    // let location = useLocation();
    // const [isState, setIsState] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [deleteWebAvatar] = useDeleteWebImgMutation();


    if (!data) {
        return null;
    }

    const onDelete = () => deleteWebAvatar(data.id).unwrap();

    const downloadURI = (uri) => {
        setIsDownload(true);
        const img = new Image();
        img.crossOrigin = "Anonymous";
        img.src = uri;
        img.onload = () => {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = img.width;
            canvas.height = img.height;
            ctx.drawImage(img, 0, 0);
            const a = document.createElement("a");
            a.download = `${data?.shortId}.png`;
            a.href = canvas.toDataURL("image/png");
            a.click();
            setIsDownload(false);
        };
    };

    return (
        <div className="col">
            <div className="card mt-1">
                <div className="img-wrapper">
                    <img
                        src={data.url}
                        className="card-img-top img-responsive bg-secondary"
                        alt="..."
                    />
                    <div className="img-overlay-default">
                        <div className="mt-2">
                            <Button
                                onClick={() => downloadURI(data?.url)}
                                variant="outline-success"
                                style={{ minWidth: 100 }}
                            >
                                {isDownload && (
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                <span className={isDownload ? "ms-2" : ""}>
                                    Download
                                </span>
                            </Button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <p className="card-text">
                        <FirebaseLink id={data.id} name={data.authorName} />
                        <br />
                        like:{data.likeCount}/star:{data.starCount}
                        <br />
                        down:{data?.dailyCount?.downloadCount}/{data.dCount}
                    </p>
                    <DisplayUpdateTime updateTime={data.updateTime} />
                    <p>
                        <code>{data.desc}</code>
                    </p>
                </div>
                <div className="card-body">
                    <Confirm onConfirm={onDelete}>
                        <Button className="me-1 mt-1" variant="outline-danger">
                            Delete
                        </Button>
                    </Confirm>
                </div>
            </div>
        </div>
    );
};

const FirebaseLink = ({ id, name }) => {
    const link = `https://console.firebase.google.com/project/avatar-maker-23583/firestore/data/~2FwebAvatars~2F${id}`;
    return (
        <a className="me-1" target="_blank" href={link} rel="noreferrer">
            {id}
        </a>
    );
};

// const HeaderText = ({ data }) => {
//     const { clientVer, contentLang } = data;
//     const { code, short } = useContetLang(contentLang);
//     return (
//         <>
//             {code} - {short} - {clientVer}
//         </>
//     );
// };

export default Card;
