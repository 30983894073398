import React from 'react'
import { useParams } from 'react-router-dom'
import { useUpdateLayerMutation } from 'api/neku/layers'
import { useLayerGroups } from 'screens/Editor/hooks'
import './index.scss'

const UpdateGroup = ({ layer }) => {
  const { tid } = useParams()
  const { data } = useLayerGroups()
  const [update] = useUpdateLayerMutation()

  const onChange = (value) =>
    update({
      id: layer.id,
      body: { tid, key: 'gid', value },
    })

  if (!data) {
    return <>please load groups...</>
  }

  return (
    <select
      className={`rounded-3 me-1 py-2 select`}
      value={layer.gid}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">Bind Group</option>
      {data.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </select>
  )
}

export default UpdateGroup
