//React entry point 会自动根据endpoints生成hooks
import { cacher } from "api/rtkQueryCacheUtils";
import { neku } from "./index";

//使用base URL 和endpoints 定义服务
const api = neku.injectEndpoints({
  endpoints: (builder) => ({
    getPublishers: builder.query({
      query: () => `publishers`,
      keepUnusedDataFor: 24 * 60 * 60,
      providesTags: cacher.providesList("Publisher"),
    }),
    updatePubliser: builder.mutation({
      query: (params) => {
        const { id, body } = params;
        return { url: `publishers/${id}`, method: "PUT", body };
      },
      async onQueryStarted(
        { id, body },
        { dispatch, queryFulfilled, getState }
      ) {
        optimisticUpdate(id, body, dispatch, queryFulfilled, getState);
      },
    }),
  }),
  overrideExisting: false,
});
//导出可在函数式组件使用的hooks,它是基于定义的endpoints自动生成的
export const { useGetPublishersQuery, useUpdatePubliserMutation } = api;
export default api;

const optimisticUpdate = async (
  id,
  body,
  dispatch,
  queryFulfilled,
  getState
) => {
  const updates = [];

  console.log(id, body);

  // updates.push(
  //   dispatch(
  //     api.util.updateQueryData("getPublisher", id, (obj) => {
  //       updatePublisher(obj, body);
  //     })
  //   )
  // );

  for (const { endpointName, originalArgs } of api.util.selectInvalidatedBy(
    getState(),
    [{ type: "Publisher", id: id }]
  )) {
    if (endpointName === "getPublishers") {
      console.log(endpointName);
      updates.push(
        dispatch(
          api.util.updateQueryData(endpointName, originalArgs, (items) => {
            const item = items.find((item) => item.id === id);
            updatePublisher(item, body);
          })
        )
      );
    }
  }

  try {
    await queryFulfilled;
  } catch {
    for (const u of updates) {
      u.undo();
    }
  }
};

export const updatePublisher = (obj, body) => {
  if (obj) {
    const { key, value } = body;
    console.log(obj, key, value);
    if (key === "state") {
      obj.state = value;
    } else {
      obj[key] = value;
    }
  }
};
