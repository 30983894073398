import React from "react";
import Tabs from "components/Tabs";
import Nav from "../AdminPanel/Nav";
import { Outlet } from "react-router-dom";
import { useRole } from "hooks/auth";

const Index = () => {
  const isAdmin = useRole(`admin`);
  const isView = useRole(`viewer`);
  var datas = [
    { name: "Avatars", to: "", end: true },
    { name: "web images", to: "webimgs" },
  ];
  if(isView) {
    datas = [
      { name: "Avatars", to: "", end: true },
    ];
  }
  if (!isAdmin && !isView) return null;
  return (
    <>
      <Nav />
      <Tabs datas={datas} />
      <div className="bg-white m-auto" style={{ minHeight: "95vh" }}>
        <Outlet />
      </div>
    </>
  );
};

export default Index;
