import React from "react";
import { useParams } from "react-router-dom";
import Accordion from "react-bootstrap/Accordion";
import Item from "./Item";
import { useCreateDlcMutation, useGetDlcsQuery } from "api/neku/dlcs";
import { ArrowClockwise, PlusLg } from "react-bootstrap-icons";

import { useDlcs, useCustomParams } from "screens/Editor/hooks";
import Spinner from "components/Spinner";
import "./index.scss";

export const DlcsActions = () => {
  return (
    <div>
      <RefreshButton />
      <CreateButton />
    </div>
  );
};

const RefreshButton = () => {
  const params = useCustomParams();
  const { refetch, isFetching } = useGetDlcsQuery(params, { skip: !params });

  if (isFetching) return <Spinner className="me-3" />;
  return <ArrowClockwise onClick={refetch} className="icon me-3" />;
};

const CreateButton = () => {
  const { tid } = useParams();
  const [create, { isLoading }] = useCreateDlcMutation();
  const handleClick = () => {
    if (!tid) return null;
    const data = {
      tid,
      name: "new dlc",
    };
    create(data).unwrap();
  };
  if (isLoading) {
    return <Spinner />;
  }
  return <PlusLg onClick={handleClick} className={"icon"} />;
};

const DisplayDLCs = () => {
  const { data: dlcs } = useDlcs();
  if (!dlcs || dlcs?.length <= 0) return <h1>no dlcs..</h1>;
  return (
    <Accordion className="mt-1">
      {dlcs.map((dlc) => (
        <Item key={dlc.id} dlc={dlc} />
      ))}
    </Accordion>
  );
};

export default DisplayDLCs;
