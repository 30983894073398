import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { logout } from "api/neku/datas/authSlice";

const NavItem = ({ href, name, end }) => {
  return (
    <li className="nav-item col-6 col-md-auto">
      <NavLink
        to={href}
        end={end}
        className={({ isActive }) =>
          isActive ? "nav-link p-2 active" : "nav-link p-2"
        }
      >
        {name}
      </NavLink>
    </li>
  );
};

const Nav = () => {
  const { user, face } = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  if (!user || !face) {
    return null;
  }

  return (
    <header className="navbar navbar-expand navbar-dark bd-navbar w-100">
      <nav className="container-fluid flex-wrap flex-md-nowrap">
        <Link className="navbar-brand p-0 me-2" to="/">
          <title>Neku</title>
        </Link>

        <div className="collapse navbar-collapse">
          <ul className="navbar-nav flex-row flex-wrap bd-navbar-nav pt-2 py-md-0">
            <NavItem href="/" name="Admin" />
            <NavItem href="/avatars" name="Avatars" />
          </ul>
        </div>
        <div>
          <h4 className="text-white">
            {face?.nick_name}:{face?.id}
          </h4>
        </div>
        <hr className="d-md-none text-white-50" />
        <Link
          className="btn btn-bd-download d-lg-inline-block my-2 my-md-0 ms-md-3"
          to="/login"
          onClick={() => dispatch(logout())}
        >
          {user.username} Log Out
        </Link>
      </nav>
    </header>
  );
};

export default Nav;
