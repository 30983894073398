import { Line } from "@nivo/line";
import { Spinner } from "react-bootstrap";
const commonProperties = {
  width: 700,
  height: 400,
  margin: { top: 20, right: 50, bottom: 60, left: 80 },
  animate: true,
};

const LinePanel = ({ loading, datas, id }) => {
  if (loading) {
    return <Loading />;
  }
  datas.forEach((item) => {
    if (typeof item.y === "undefined") {
      item.y = 0;
    }
  });
  if (datas.length === 0) {
    return <p>empty download count</p>;
  }

  return (
    <div>
    <span>{id}</span>
    <Line
      {...commonProperties}
      curve="monotoneX"
      data={[
        {
          id: id,
          data: datas,
        },
      ]}
      xScale={{
        type: "time",
        format: "%Y-%m-%d",
        useUTC: false,
        precision: "day",
      }}
      xFormat="time:%Y-%m-%d"
      yScale={{
        type: "linear",
        stacked: Boolean("stacked", false),
      }}
      axisLeft={{
        legend: "linear scale",
        legendOffset: 12,
      }}
      axisBottom={{
        format: "%b %d",
        tickValues: "every 2 days",
        legend: "time scale",
        legendOffset: -12,
      }}
      enablePointLabel={true}
      labelYOffset={-12}
      pointLabelYOffset={-12}
      pointSize={10}
      pointBorderColor={{ theme: "background" }}
      pointBorderWidth={2}
      useMesh={true}
      enableSlices={false}
    />
    </div>
  );
};

export default LinePanel;
function Loading() {
  return (
    <div>
      <Spinner
        as="span"
        size="sm"
        aria-hidden="true"
        animation="border"
        variant="success"
      />
      Loading...
    </div>
  );
}
