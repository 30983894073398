import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { logout } from "api/neku/datas/authSlice";
import logoImg from "assets/logo.png";
import classNames from "classnames";

const NavItem = ({ href, name, end }) => {
  return (
    <NavLink
      to={href}
      end={end}
      className={({ isActive }) => (isActive ? "nav-link active" : "nav-link")}
    >
      {name}
    </NavLink>
  );
};

const Nav = () => {
  const { user, face } = useSelector((state) => state.auth);
  const [show, setShow] = useState(false);
  const collapseStyle = classNames("collapse navbar-collapse", { show: show });
  const dispatch = useDispatch();

  if (!user || !face) {
    return null;
  }

  return (
    <nav className="navbar navbar-expand-lg bg-white border-bottom mb-1">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src={logoImg}
            alt="Logo"
            width="30"
            height="30"
            className="d-inline-block align-text-top"
          />
          Neku admin
        </a>
        <button
          className="navbar-toggler"
          type="button"
          onClick={() => setShow(!show)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={collapseStyle}>
          <div className="navbar-nav me-auto mb-2 mb-lg-0">
            <NavItem href="/admin" name="Admin" />
            <NavItem href="/avatars?lang=0&order=UpdateTime" name="Avatars" />
          </div>
          <form className="d-flex">
            <span>
              {face?.nick_name}:{face?.id}
            </span>
            <Link
              className="btn btn-outline-danger d-lg-inline-block my-2 my-md-0 ms-md-3"
              to="/login"
              onClick={() => dispatch(logout())}
            >
              {user.username} Log Out
            </Link>
          </form>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
