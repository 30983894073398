import React, { useState, useMemo } from "react";
import { Table, Image, Button } from "react-bootstrap";
import { Trash } from "react-bootstrap-icons";
import { useGetLayerIconsQuery, useDeleteLayerIconMutation } from "api/neku/layersicons";
import UpdateEntity from "./UpdateEntity";
import { createSelector } from "@reduxjs/toolkit";
import Spinner from "components/Spinner";
import Confirm from "components/Confirm";
import Create from "./Create"

const LayerIcons = () => {
  const [sortKey, setSorkKey] = useState("z");
  const [order, setOrder] = useState({ z: `asc`, p: "asc" });
  const [show, setShow] = useState(false)
  const sortIt = useMemo(() => {
    return createSelector(
      (res) => res.data,
      (data) => {
        const sortIcons = JSON.parse(JSON.stringify(data || []))
        return sortIcons?.sort((a,b) => {
          if (sortKey === "z") {
            return order.z === "asc" ? b.z - a.z : a.z - b.z;
          }
          return order.p === "asc" ? b.p - a.p : a.p - b.p;
        })
      }
    )
  }, [sortKey, order]);
  const { icons, isFetching, refetch } = useGetLayerIconsQuery({}, {
    selectFromResult: (result) => ({
      ...result,
      icons: sortIt(result),
    }),
  });

  const handleSort = (key) => {
    setSorkKey(key);
    setOrder((order) => {
      return { ...order, [key]: order[key] === "asc" ? "desc" : "asc" };
    });
  };

  return (
    <>
      <div className="mb-1 me-1">
        <Button className="me-1" variant="outline-primary" onClick={refetch}>
          Refetch{isFetching && "ing"}
        </Button>
        <Button onClick={() => setShow(!show)}>Upload icons</Button>
        <Create show={show} setShow={setShow} />
      </div>
      <Table striped bordered hover>
        <thead className="table-secondary">
          <tr>
            <th>#</th>
            <th>Icon</th>
            <th>Code</th>
            <th>name</th>
            <th>author</th>
            <th onClick={() => handleSort("z")}>zIndex</th>
            <th onClick={() => handleSort("p")}>PlayIndex</th>
            <th>Operate</th>
          </tr>
        </thead>
        <tbody>
          {icons &&
            icons?.map((item, index) => (
              <Item item={item} index={index} key={item.code} refetch={refetch} />
            ))}
        </tbody>
      </Table>
    </>
  );
};

const Item = ({ item, index, refetch }) => {
  const [deleteIt, {isLoading}] = useDeleteLayerIconMutation();
  const handleDelete = () => {
    deleteIt(item.id).unwrap().then((res) => {
      refetch()
    })
  }
  return (
    <tr>
      <th>{index}</th>
      <td>
        <Image src={"https://img.zthd.io/an1/acs/" + item?.code} width={96} />
      </td>
      <td>{item?.code}</td>
      <UpdateEntity data={item} entityKey={"name"} />
      <UpdateEntity data={item} entityKey={"author"} />
      <UpdateEntity data={item} entityKey={"z"} />
      <UpdateEntity data={item} entityKey={"p"} />
      <td>
        <Confirm
          onConfirm={handleDelete}
          can
          title={`Delete`}
          body={null}
        >
          <span className="cursor-pointer" style={{ marginBottom: 10 }}>
            {isLoading ? (
              <Spinner animation="border" variant="success" size="sm" />
            ) : (
              <Trash color="red"/>
            )}
          </span>
        </Confirm>
      </td>
    </tr>
  );
};

export default LayerIcons;
