import React from "react";
import { useParams } from "react-router-dom";
import { useUpdateLayerMutation } from "api/neku/layers";
import "./index.scss";

const UpdateMax = ({ layer }) => {
  const { tid } = useParams();
  const [update] = useUpdateLayerMutation();
  const items = Array.from({ length: 4 }, (v, i) => i + 1);

  const onChange = (value) =>
    update({
      id: layer.id,
      body: { tid, key: "maxChoose", value: Number(value) },
    });
  return (
    <select
      className={`rounded-3 me-1 py-2 select`}
      value={layer.max}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">Max Optional</option>
      {items.map((v) => (
        <option key={v} value={v}>
          Max Optional: {v}
        </option>
      ))}
    </select>
  );
};

export default UpdateMax;
