import React from "react";
import { useParams } from "react-router-dom";
import { useUpdateComponentMutation } from "api/neku/components";
import { useDlcs, useChildCpts } from "screens/Editor/hooks";

import { useRole } from "hooks/auth";

const ChangeDLC = ({ cpt }) => {
  const isAdmin = useRole(`admin`);
  const { tid } = useParams();
  const { data: dlcs } = useDlcs();
  const [update] = useUpdateComponentMutation();

  const onChange = (value) => {
    if (value === "") {
      return;
    }
    console.log(cpt);
    update({
      id: cpt.id,
      body: { tid, key: "dlc", value },
    }).unwrap();
  };

  const { cpts: childs } = useChildCpts(cpt);

  if (!isAdmin) {
    return null;
  }

  if (!dlcs || cpt.pid || childs.length > 0) {
    return null;
  }

  return (
    <div className="">
      <select
        className="me-1"
        value={cpt.dlc}
        onChange={(e) => onChange(e.target.value)}
      >
        <option value="">关联DLC</option>
        <option value={tid}>Default</option>
        {dlcs.map(({ id, name }) => (
          <option key={id} value={id}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default ChangeDLC;
