import { useDeleteDlcMutation } from "api/neku/dlcs";
import React, { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { createSelector } from "@reduxjs/toolkit";
import Accordion from "react-bootstrap/Accordion";
import { useDispatch } from "react-redux";
import Confirm from "components/Confirm";
import { ArrowClockwise, Trash3 } from "react-bootstrap-icons";
import Intro from "./Intro";
import Cpts from "./Cpts";
import UpdateSku from "./UpdateSku";
import Publish from "./Publish";
import { useCapture, useCustomParams } from "screens/Editor/hooks";
import { captureIt } from "../../uiSlice";
import { useUpdateDlcMutation } from "api/neku/dlcs";
import cptsApi from "api/neku/components";
import { Random } from "./Random"
import { useRole } from "hooks/auth";
import Name from "./Name";
import CustomSpinner from "components/Spinner";

const useCptsWithDlc = (dlc) => {
  const params = useCustomParams();
  const filterByDlc = useMemo(() => {
    const emptyArray = [];
    return createSelector(
      (res) => res.data,
      (data) =>
        data?.filter((cpt) => cpt.state >= 0 && cpt.dlc === dlc) ?? emptyArray
    );
  }, [dlc]);

  return cptsApi.endpoints.getComponents.useQueryState(params, {
    selectFromResult: (result) => ({
      ...result,
      cpts: filterByDlc(result),
    }),
  });
};

const Item = ({ dlc }) => {
  const { cpts } = useCptsWithDlc(dlc.id);

  if (!dlc) return null;

  return (
    <Accordion.Item
      eventKey={dlc.id}
      className="border-0 border-bottom dlc-item"
    >
      <Accordion.Header>
        <CaptureCover dlc={dlc} />
        <div className="flex-center">
          <Name dlc={dlc} />
          <div style={{ flex: "1", textAlign: "center" }}>
            Cpts: {cpts?.length}
          </div>
        </div>
      </Accordion.Header>
      <Accordion.Body style={{ background: "#F2F3F4" }}>
        <AdminPanel dlc={dlc} cpts={cpts} />
        <Intro dlc={dlc} />
        <Cpts dlc={dlc} cpts={cpts} />
        <div className="flex-center justify-content-between mt-2">
          <div>
            <Publish dlc={dlc} />
          </div>
          <DeleteButton cpts={cpts} dlc={dlc} />
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

const DeleteButton = ({ dlc, cpts }) => {
  const { tid } = useParams();
  const [deleteIt, { isLoading }] = useDeleteDlcMutation();
  const handleDelete = () => {
    deleteIt({ id: dlc.id, tid }).unwrap();
  };
  return (
    <Confirm
      onConfirm={handleDelete}
      disabled={cpts.length > 0}
      title={cpts.length > 0 ? `CAN'T DELETE IT!` : `Delete DLC ${dlc.name}`}
      body={
        cpts.length > 0
          ? `This DLC contains components, please remove them first!`
          : null
      }
    >
      {isLoading ? <CustomSpinner /> : <Trash3 className="cursor-pointer" />}
    </Confirm>
  );
};

const AdminPanel = ({ dlc, cpts }) => {
  const isAdmin = useRole(`admin`);
  if (!isAdmin) {
    return null;
  }
  return (
    <div className="d-flex align-items-center">
      <UpdateSku dlc={dlc} />
      <Random className="mx-4" dlc={dlc} cpts={cpts} />
      <div>ID: {dlc.id}</div>
    </div>
  );
};

const CaptureCover = ({ dlc }) => {
  // const [addAvatar] = useAddAvatarMutation();
  const source = `dlc_${dlc.id}`;
  const [result, isLoading] = useCapture(source);
  const dispatch = useDispatch();
  const { tid } = useParams();

  const [update, { isFetching }] = useUpdateDlcMutation();

  useEffect(() => {
    if (result && result.id) {
      update({
        id: dlc.id,
        body: {
          tid,
          key: "cover",
          value: `https://img.zthd.io/an1/avatars/${result.originalId}.webp`,
        },
      }).unwrap();
    }
  }, [result, dlc, update, tid]);

  return (
    <div className="dlc-cover" onClick={() => dispatch(captureIt({captureSource:source, tid}))}>
      <img src={dlc.cover} alt="cover" />;
      <div
        className={"Refresh"}
        // onClick={handleClick}
      >
        {isLoading || isFetching ? (
          <ArrowClockwise width={18} height={18} className={"loading"} />
        ) : (
          <ArrowClockwise width={18} height={18} />
        )}
      </div>
    </div>
  );
};

export default Item;
