import React, { useState } from "react";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoImg from "assets/logo.png";
import { QuestionCircle, SendFill, SendCheckFill } from "react-bootstrap-icons";
import Confirm from "components/Confirm";
import {
  usePublishDraftMutation,
  usePublishMutation,
} from "api/neku/templates";
import { Spinner } from "react-bootstrap";
import { useRole } from "hooks/auth";
import { useTemplate, useReviewModel } from "../hooks";
import "./index.scss";

const Nav = () => {
  return (
    <nav className="navbar bg-white nav border-bottom">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/personal">
          <img
            src={logoImg}
            alt="Logo"
            width="48"
            height="48"
            className="d-inline-block align-text-center me-1"
          />
          <span className="logo_text me-1">Creator Hub</span>
        </Link>
        <EnableReviewModel />
        <div className="d-flex align-items-center">
          <div className="remind me-1 d-none d-md-block">
            <QuestionCircle />
          </div>
          <PublishButton />
          <SubmitReview />
          {/* <ClearTemplate /> */}
        </div> 
      </div>
    </nav>
  );
};

const EnableReviewModel = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const r = searchParams.get("r") ?? null;
  const { data: template } = useTemplate();
  const isAdmin = useRole(`admin`);

  const handleTunOff = () => {
    setSearchParams({});
  };

  const handleTunOn = () => {
    setSearchParams({ r: 1 });
  };

  if (!template?.version) return null;
  console.log(isAdmin)
  if (!isAdmin) return null;
  if (r === "1") {
    return (
      <h4>
        Review model : <code>{template.version}</code>
        <Button className="ms-1" onClick={handleTunOff} variant={"success"}>
          Turn-Off
        </Button>
      </h4>
    );
  }
  return (
    <h4>
      <Button onClick={handleTunOn} variant={"outline-success"}>
        Turn-On review model
      </Button>
    </h4>
  );
};

const PublishButton = () => {
  const isAdmin = useRole(`admin`);
  const reviewModel = useReviewModel();
  if (!isAdmin) return null;
  if (!reviewModel) return null;
  return <PublishOnline />;
};

const PublishOnline = () => {
  const { tid } = useParams();
  const [ reason, setReason ] = useState('')
  const [onlineTime, setOnlineTime] = useState(null);
  const { data: template, isFetching } = useTemplate();
  const navigate = useNavigate();
  const [publish, { isLoading }] = usePublishMutation();
  const onConfirm = () => {
    var data = { id: tid, body: { version: template?.version } }
    if(onlineTime) {
      data.body.onlineTime = onlineTime
    }
    if(reason) {
      data.body.reason = reason
    }
    publish(data).then(() =>
      navigate(`/admin/onlines`)
    );
  };

  if (isFetching) {
    return <>isFetching</>;
  }

  return (
    <Confirm onConfirm={onConfirm} confirmText="Done" body={<PublishOption reason={reason} setReason={setReason} onlineTime={onlineTime} setOnlineTime={setOnlineTime} />}>
    <Button
      disabled={isLoading}
      variant="outline-light"
      className={"submit"}
      // onClick={handleClick}
    >
      <div className="flex-center" style={{ height: 20, paddingTop: 2 }}>
        {isLoading ? (
          <Spinner size="sm" className="me-1" />
        ) : (
          <SendCheckFill className="me-1" />
        )}
      </div>
      <span>
        Publish <strong>{template?.version}</strong>
      </span>
    </Button>
    </Confirm>

  );
};

const PublishOption = ({ reason, setReason, setOnlineTime, onlineTime }) => {
  return (
      <>
        <input
          className="p-2 mb-2"
          type="datetime-local"
          id="onlineTime"
          name="onlineTime"
          onChange={(e) => {
            const date = parseInt(new Date(e.target.value).getTime() / 1000) * 1000;
            setOnlineTime(date);
          }}
          min={new Date()}
          max={new Date().getTime() + 60 * 60 * 24 * 1000 * 30} />
        <textarea
          name="reason"
          className="reason"
          placeholder="Please enter reason"
          value={reason}
          onChange={(e) => setReason(e.target.value)}
        />
      </>
  )
}

const SubmitReview = () => {
  const isView = useRole(`viewer`);
  const { tid } = useParams();
  const reviewModel = useReviewModel();
  const [publish, { isLoading }] = usePublishDraftMutation();
  const handleClick = () => {
    publish(tid).unwrap();
  };

  if (reviewModel) {
    return null;
  }
  if (isView) return null

  return (
    <Button
      disabled={isLoading}
      variant="outline-light"
      className={"submit"}
      style={{margin: '0 20px'}}
      onClick={handleClick}
    >
      <div className="flex-center me-2">
        {isLoading ? <Spinner size="sm" /> : <SendFill />}
      </div>
      <span>Submit review</span>
    </Button>
  );
};


export default Nav;
