import React, { useMemo } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import Fetching from "ui/Fetching";
import { Button } from "react-bootstrap";
import CopyedCode from "ui/CopyedCode";
import DisplayUpdateTime from "ui/DisplayUpdateTime";
import UpdateEntity from "./UpdateEntity";
import { useGetTemplatesQuery, useDeleteTemplateMutation } from "api/neku/templates";
import EmptyDatas from "ui/EmptyDatas";
import { useGetFrameQuery } from "api/neku/frames";
import CustomSpinner from "components/Spinner";
import { Trash3 } from "react-bootstrap-icons";
import Confirm from "components/Confirm";

const Normal = () => {
  const [searchParams] = useSearchParams();
  const author = searchParams.get("author") ?? null;
  if (author) {
    return <DisplayTemplates params={{ author }} />;
  }
  return <DisplayTemplates params={{}} />;
};

const DisplayTemplates = ({ params }) => {
  const location = useLocation();
  const {
    data: templates,
    isFetching,
    refetch,
  } = useGetTemplatesQuery(params, { skip: !params });

  if (isFetching) return <Fetching />;
  if (!templates) return <EmptyDatas />;

  return (
    <div>
      <div>
        <Button className="me-3" variant="outline-primary" onClick={refetch}>
          refetch
        </Button>
        <Link
          state={{ backgroundLocation: location }}
          className="btn btn-outline-success m-1"
          to={`/create`}
        >
          Create Template
        </Link>
      </div>
      <table className="table table-hover">
        <Thead />
        <tbody>
          {templates?.map((template, index) => (
            <tr key={template.id}>
              <Item index={index} template={template} />
              <td>
                <Link
                  className="me-1 btn btn-outline-primary"
                  to={`/editor/` + template.id}
                >
                  Editor
                </Link>
                <DeleteButton template={template} refetch={refetch} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const DeleteButton = ({template, refetch}) => {
  const [deleteIt, { isLoading }] = useDeleteTemplateMutation();
  const handleDelete = () => {
    deleteIt(template.id).then((res) => {
      console.log(res)
      if(res.data.code == 201) {
        refetch()
      }else {
        alert(res.data.message)
      }
    })
  };
  if(!template) return
  return (
    <Confirm
      onConfirm={handleDelete}
      title={`Delete template ${template.name}`}
      body={null}
    >
      {isLoading ? (
        <CustomSpinner />
      ) : (
        <Button variant="outline-danger">
          Delete
        </Button>
      )}
    </Confirm>
  );
};

const Thead = () => {
  return (
    <thead className="table-secondary">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Preview</th>
        <th scope="col">ID</th>
        <th scope="col">Publisher</th>
        <th scope="col">Online Publisher</th>
        <th scope="col">Publish Time</th>
        <th scope="col">Canvas Size</th>
        <th scope="col">Provider</th>
        <th scope="col">Remark</th>
        <th scope="col">SimpleLimit</th>
        <th scope="col">Batchs</th>
        <th scope="col">Avatars</th>
        <th scope="col">Options</th>
      </tr>
    </thead>
  );
};

const Item = ({ template, index }) => {
  return (
    <>
      <td>{index + 1}</td>
      <td>
        {template.cover && <img width={96} src={template.cover} alt="..." />}
      </td>
      <td>
        <div className="fw-bold">{template.name}</div>
        <CopyedCode copyed={template.id}>{template.id}</CopyedCode>
      </td>
      <td>
        <Link to={"?author=" + template.author}>{template.publisher}</Link>
      </td>
      <td>
        {template.onlinePublisher}
      </td>
      <td>
        <DisplayUpdateTime updateTime={template.publishTime} />
      </td>

      <td>
        {template.ow}x{template.oh}
      </td>

      <td>{template.provider}</td>
      <UpdateEntity data={template} entityKey="remark" />
      <UpdateEntity data={template} entityKey="simpleLimit" />
      <td>
        <Link to={`batchnos?tid=${template.id}`}>Batchs</Link>
      </td>
      <td>
        <Link to={`/avatars?tid=` + template.id}>Avatars</Link>
      </td>
    </>
  );
};

export default Normal;
