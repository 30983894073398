import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import CardWrap from "screens/Editor/BottomPanel/LayerPanel/Cpt/CardWrap";
import { Image, Button, Modal, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useAddComponentMutation } from "api/neku/components";
import { Check2Circle, Trash3 } from "react-bootstrap-icons";
import { PlusLg } from "react-bootstrap-icons";
import Checkbox from "components/Form/Switch";
import { makeShortId } from "commons";
import { useRole } from "hooks/auth";
import {
  useFetchBatchNos,
  useTemplate,
  useCptsSubscription,
} from "../../hooks";
import { fireBatchUpload, updateBatchUpload } from "../../uiSlice";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const BatchUpload = ({ dlc }) => {
  const { tid } = useParams()
  const [uploadFiles, setUploadFiles] = useState([]);
  const { data: template } = useTemplate();
  const dispatch = useDispatch();

  const selectFile = (files) => {
    if (files === undefined) {
      return;
    }

    dispatch(fireBatchUpload({task:`reset`, tid}));
    setUploadFiles([]);
    for (const file of files) {
      setUpReader(file);
    }
  };

  const removeThis = useCallback((id) => {
    setUploadFiles((files) => files.filter((f) => f.id !== id));
  }, []);

  const setUpReader = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement("img");
      img.onload = () => {
        const cpt = e.target.result;
        const id = makeShortId(6);
        setUploadFiles((state) => [
          ...state,
          { id, name: file.name, original: cpt, w: img.width, h: img.height },
        ]);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };

  if (!template) {
    return null;
  }

  return (
    <>
      <CardWrap>
        <div className="m-auto upload-cpt-btn flex-center justify-content-center position-relative overflow-hidden clearScroll">
          <PlusLg />

          <input
            style={{
              width: 113,
              height: 113,
              opacity: 0,
              position: "absolute",
              left: 0,
              top: -30,
              cursor: "pointer",
            }}
            type="file"
            multiple
            accept=".jpg, .png, .jpeg, .webp"
            onChange={(e) => selectFile(e.target.files)}
            value=""
          />
        </div>
      </CardWrap>
      {uploadFiles.length > 0 && (
        <UploadModel
          uploadFiles={uploadFiles}
          onHide={() => setUploadFiles([])}
          removeThis={removeThis}
          dlc={dlc}
        />
      )}
    </>
  );
};

const useCanSave = (uploadFiles, template, tid, lid, dlc, batchNo) => {
  const hasError = uploadFiles.some(
    (i) => i.w !== template?.ow || i.h !== template?.oh
  );
  return uploadFiles.length > 0 && !hasError && tid && lid && dlc && batchNo;
};

const UploadModel = ({ uploadFiles, dlc, removeThis, ...props }) => {
  const { data: template } = useTemplate();

  const { tid } = useParams();
  const lid = useSelector((state) => state.ui.editor[tid].lid);
  const [batchNo, setBatchNo] = useState(template.id);

  const { data: batchNos } = useFetchBatchNos();
  const isAdmin = useRole(`admin`);

  const canSave = useCanSave(uploadFiles, template, tid, lid, dlc, batchNo);

  return (
    <Modal {...props} show={true} size="lg" centered style={{width: '70%'}}>
      <Modal.Header closeButton>
        <Modal.Title>Upload components</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isAdmin && (
          <div className="col-5">
            <label className="form-label">Select Batch</label>
            <select
              className="form-select"
              value={batchNo}
              onChange={(e) => setBatchNo(e.target.value)}
            >
              <option value={tid}>default</option>

              {batchNos?.map(({ id, name }) => (
                <option key={id} value={id}>
                  {id} - {name}
                </option>
              ))}
            </select>
          </div>
        )}
        <table className="table table-borderless">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Cover</th>
              <th scope="col">Preview</th>
              <th scope="col">Name</th>
              <th scope="col">Trim</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {uploadFiles?.map((cpt, index) => (
              <UploadItem
                key={cpt.id}
                cpt={cpt}
                index={index}
                template={template}
                removeThis={removeThis}
                dlc={dlc}
                batchNo={batchNo}
              />
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outline-secondary" onClick={props.onHide}>
          Close
        </Button>
        <FireButton uploadFiles={uploadFiles} canSave={canSave} />
      </Modal.Footer>
    </Modal>
  );
};

const FireButton = ({ uploadFiles, canSave }) => {
  const { tid } = useParams();
  const dispatch = useDispatch();
  const { refetch: refetchCpts } = useCptsSubscription();
  const task = useSelector((state) => state.ui.editor[tid].batchUploadTask);

  useEffect(() => {
    if (task.done === task.tasks) {
      const makeRequest = async () => {
        await delay(500);
        refetchCpts().unwrap();
      };
      makeRequest();
    }
  }, [task, refetchCpts]);

  const handleUpload = () => {
    dispatch(fireBatchUpload({task: uploadFiles.length, tid}));
  };

  if (task.id === "default") {
    return (
      <Button
        variant="outline-success"
        disabled={!canSave}
        onClick={handleUpload}
      >
        Batch upload
      </Button>
    );
  }

  if (task.done < task.tasks) {
    return (
      <Button
        variant="outline-success"
        disabled={!canSave}
        onClick={handleUpload}
      >
        Batch upload({task.done}/{task.tasks})
      </Button>
    );
  }

  return null;
};

const UploadItem = ({ cpt, index, removeThis, template, dlc, batchNo }) => {
  const { id: tid } = template;
  const { original, id: fileName } = cpt;
  const [create, { isLoading, isSuccess, isError }] = useAddComponentMutation();
  const lid = useSelector((state) => state.ui.editor[tid].lid);
  const valid = template.ow === cpt.w && template.oh === cpt.h;
  const [trim, setTrim] = useState(true);
  const [uploadFiles, setUploadFiles] = useState([]);

  const { id: taskId } = useSelector(
    (state) => state.ui.editor[tid].batchUploadTask
  );
  const dispatch = useDispatch();

  const handleUpload = useCallback(() => {
    const uploadData = { tid, lid, original, trim, dlc, batchNo, fileName };
    if(uploadFiles.length !== 0) {
      uploadData.cover = uploadFiles[0].original
    }
    create(uploadData)
      .unwrap()
      .then(() => {
        dispatch(updateBatchUpload({ id: taskId, done: 1, fails: 0 , tid}));
      });
  }, [
    tid,
    lid,
    original,
    fileName,
    trim,
    dlc,
    batchNo,
    create,
    dispatch,
    taskId,
  ]);

  useEffect(() => {
    if (!taskId || taskId === "default") return;
    handleUpload();
  }, [handleUpload, taskId]);

  return (
    <tr>
      <td className="align-middle">{index + 1}</td>
      <td className="align-middle">
        <UploadCover uploadFiles={uploadFiles} setUploadFiles={setUploadFiles} />
      </td>
      <td>
        <Image src={cpt.original} width={84} />
      </td>
      <td className="align-middle">
        {cpt.name}
        <br />
        {!valid && (
          <span className="badge bg-danger">
            require: {template?.ow} x {template?.oh}
          </span>
        )}
      </td>
      <td className="align-middle">
        <Checkbox value={trim} onChange={setTrim}>
          Trim
        </Checkbox>
      </td>
      <td className="align-middle">
        {isLoading && (
          <Spinner animation="border" size="sm" variant="success" />
        )}
        {isError && <Button>retry</Button>}
        {isSuccess && (
          <span className="text-success">
            <Check2Circle />
          </span>
        )}
        {!isLoading && !isError && !isSuccess && (
          <Button
            className="me-1"
            variant="outline-danger"
            onClick={() => removeThis(cpt.id)}
          >
            <Trash3 />
          </Button>
        )}
      </td>
    </tr>
  );
};

const UploadCover = ({setUploadFiles, uploadFiles}) => {
  const selectFile = (files) => {
    if (files === undefined) {
      return;
    }

    // dispatch(fireBatchUpload({task:`reset`, tid}));
    setUploadFiles([]);
    for (const file of files) {
      setUpReader(file);
    }
  };

  const setUpReader = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = document.createElement("img");
      img.onload = () => {
        const cpt = e.target.result;
        const id = makeShortId(6);
        setUploadFiles((state) => [
          ...state,
          { id, name: file.name, original: cpt, w: img.width, h: img.height },
        ]);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(file);
  };
  if(uploadFiles.length !== 0) {
    return (
      <Image src={uploadFiles[0].original} width={84} />
    )
  }
  return (
    <CardWrap>
    <div className="m-auto upload-cpt-btn flex-center justify-content-center position-relative overflow-hidden clearScroll">
      <PlusLg />
      <input
        style={{
          width: 113,
          height: 113,
          opacity: 0,
          position: "absolute",
          left: 0,
          top: -30,
          cursor: "pointer",
        }}
        type="file"
        accept=".jpg, .png, .jpeg, .webp"
        onChange={(e) => selectFile(e.target.files)}
        value=""
      />
    </div>
  </CardWrap>
  )
}

export default BatchUpload;
