import React, { useState } from "react";
import { useUpdateDlcMutation } from "api/neku/dlcs";
import { useParams } from "react-router-dom";

const UpdateProvider = ({ dlc }) => {
  const { tid } = useParams();
  const [state, setState] = useState(dlc.intro);
  const [update, { isFetching }] = useUpdateDlcMutation();

  const cannotSave = !state || state === "" || isFetching;
  const handleUpdate = () => {
    if (cannotSave) {
      setState(dlc.intro);
      return;
    }

    if (state === dlc.intro) {
      return;
    }
    update({
      id: dlc.id,
      body: { tid, key: "intro", value: state },
    }).unwrap();
  };

  return (
    <div className="d-flex flex-column">
      <h5 className="text-secondary">Description:</h5>
      <textarea
        className="dlc-intro mb-2"
        value={state}
        onChange={(e) => setState(e.target.value)}
        onBlur={handleUpdate}
      />
    </div>
  );
};

export default UpdateProvider;
