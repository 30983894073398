import React, { useState } from "react";
import classNames from "classnames";

function PaginationComponent({ currAfter, setCurrAfter, nextAfter }) {
  const [afters, setAfters] = useState([]);

  const handleAfter = (after) => {
    if (after && !afters.includes(after)) {
      setAfters((afters) => [...afters, after]);
    }
    setCurrAfter(after);
  };

  const goHome = () => {
    handleAfter(null);
  };

  const goPrevious = () => {
    const index = afters.indexOf(currAfter);
    if (index > 0) {
      handleAfter(afters[index - 1]);
    }
  };

  const goPage = (v) => handleAfter(v);

  const goNext = () => handleAfter(nextAfter);

  const homeStyle = classNames(`page-item`, { disabled: !currAfter });
  const previousStyle = classNames(`page-item`, {
    disabled: afters.length <= 1,
  });

  return (
    <nav className="d-flex">
      <ul className="pagination" style={{marginBottom: 0}}>
        <li className={homeStyle} onClick={goHome}>
          <span className="page-link">Home</span>
        </li>
        <li className={previousStyle} onClick={goPrevious}>
          <span className="page-link">Previous</span>
        </li>
        {nextAfter && (
          <li className="page-item" onClick={goNext}>
            <span className="page-link">Next</span>
          </li>
        )}
        {afters.map((v, index) => (
          <li
            className={v === currAfter ? `page-item active` : `page-item`}
            key={v}
            onClick={() => goPage(v)}
          >
            <span className="page-link">{index + 1}</span>
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default PaginationComponent;
