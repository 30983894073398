import React from "react";
import { useParams } from "react-router-dom";
import {
  usePublishDlcMutation,
  usePublishDlcDraftMutation,
} from "api/neku/templates";
import { Button } from "react-bootstrap";
import { useRole } from "hooks/auth";
import Spinner from "components/Spinner";
import { SendFill } from "react-bootstrap-icons";
import { useReviewModel } from "screens/Editor/hooks";

const Publish = ({ dlc }) => {
  const isAdmin = useRole(`admin`);
  const reviewModel = useReviewModel();
  if (isAdmin && reviewModel && dlc.version) {
    return <PublishOnline dlc={dlc} />;
  }
  return <PublishDraft dlc={dlc} />;
};

const PublishDraft = ({ dlc }) => {
  const { tid } = useParams();
  const [publish, { isLoading }] = usePublishDlcDraftMutation();

  const handleClick = () =>
    publish({ id: tid, body: { dlc: dlc.id } }).unwrap();

  return (
    <Button
      disabled={isLoading}
      onClick={handleClick}
      variant="outline-success"
      className="flex-center"
    >
      {isLoading ? <Spinner className="me-1" /> : <SendFill className="me-1" />}
      Submit review
    </Button>
  );
};

const PublishOnline = ({ dlc }) => {
  const { tid } = useParams();
  const [publish, { isLoading }] = usePublishDlcMutation();
  const handleClick = () => {
    publish({ id: tid, body: { dlc: dlc.id, version: dlc.version } }).unwrap();
  };
  return (
    <Button disabled={isLoading} onClick={handleClick} variant="outline-danger">
      {isLoading ? <Spinner className="me-1" /> : <SendFill className="me-1" />}
      Publish {dlc.version}
    </Button>
  );
};

export default Publish;
