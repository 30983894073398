import React, { useState } from "react";
import { Navigate, useNavigate, useLocation, Link } from "react-router-dom";
import { Formik, Form, Field, useField } from "formik";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLoginMutation } from "api/neku";
import logoImg from "assets/logo.png";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import styles from "./Login.module.scss";
import * as Yup from "yup";
import classNames from "classnames";

const Login = () => {
  let navigate = useNavigate();
  let location = useLocation();
  let from = location.state?.from?.pathname || "/";

  const [login, { isLoading }] = useLoginMutation();
  const isLoggedIn = useSelector((state) => state.auth.user);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().email().required(),
    password: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .required(),
  });

  const handleSubmit = async (values) => {
    login(values)
      .unwrap()
      .then((res) => {
        navigate(from, { replace: true });
      })
      .catch((err) => {});
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="col-12" style={{ height: "100vh", background: "#FCFBFF" }}>
      <div className={styles.login}>
        <div className="w-100 d-flex justify-content-center">
          <img src={logoImg} alt="" style={{ width: 68, height: 68 }} />
        </div>
        <div className={styles.title}>
          <span>NeKu for designer</span>
        </div>
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form>
            <MyTextField name="username" placeholder="Email" />
            <MyPassword name="password" />
            <div className="row mt-5">
              <button
                type="submit"
                className={styles.submit}
                disabled={isLoading}
              >
                {isLoading && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                Sign in
              </button>
            </div>
            <div className="mt-5">
              New to Neku? <Link to="/signup">Create an account</Link>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const MyTextField = (props) => {
  const [field, meta] = useField(props);

  const style = classNames(
    "form-control",
    styles.input,
    {
      "is-invalid": meta.touched && meta.error,
    },
    { "is-valid": meta.touched && !meta.error }
  );
  return (
    <div className="row">
      <input type="input" className={style} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

const MyPassword = (props) => {
  const [field, meta] = useField(props);
  const [show, setShow] = useState(false);
  const style = classNames(
    "form-control",
    styles.input,
    {
      "is-invalid": meta.touched && meta.error,
    },
    { "is-valid": meta.touched && !meta.error }
  );
  return (
    <div className="row position-relative mt-5">
      <Field
        type={show ? "text" : "password"}
        className={style}
        {...field}
        {...props}
        name="password"
        autoComplete="on"
        placeholder="Password"
      />
      <div onClick={() => setShow(!show)}>
        {show ? (
          <Eye className={styles.EyeSlash + " text-body"} />
        ) : (
          <EyeSlash className={styles.EyeSlash} />
        )}
      </div>
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default Login;
