import React, { useEffect, useState, useMemo } from 'react'
import { Link, useLocation, useParams, useSearchParams } from 'react-router-dom'
import Fetching from 'ui/Fetching'
import { Button, Badge } from 'react-bootstrap'
import CopyedCode from 'ui/CopyedCode'
import { useGetDlcsQuery, useUpdateDlcMutation } from 'api/neku/dlcs'
import EmptyDatas from 'ui/EmptyDatas'
import Select from 'components/Form/Select'
import { createSelector } from '@reduxjs/toolkit'
import { DlcTagOptions, DlcTags } from 'config'
import Confirm from 'components/Confirm'
import { useSelector } from 'react-redux'

const Dlc = () => {
  const [searchParams] = useSearchParams()
  const queryTag = searchParams.get('tag') ?? null
  const [template, setTemplate] = useState('0')
  const [tag, setTag] = useState('0')
  const [templates, setTemplates] = useState([])
  const filterIt = useMemo(() => {
    const emptyArray = []
    return createSelector(
      (res) => res.data,
      (data) => {
        let obj = {}
        let list = data
          ?.map((item) => {
            return { code: item.tid, name: item.tid }
          })
          .reduce((cur, next) => {
            if (!obj[next.code]) {
              obj[next.code] = true
              cur.push(next)
            }
            return cur
          }, [])
        setTemplates(list)
        return (
          data?.filter((c) => {
            if (template == '0' && tag == '0') {
              return true
            } else if (template == '0' && tag !== '0') {
              return c?.tags?.includes(tag)
            } else if (template !== '0' && tag == '0') {
              return c.tid == template
            } else {
              return c.tid == template && c?.tags?.includes(tag)
            }
          }) ?? emptyArray
        )
      },
    )
  }, [template, tag])
  const params = useMemo(() => {
    const data = {}
    if (queryTag) {
      data.tag = queryTag
    }
    return data
  }, [queryTag])
  const { dlcs, isFetching, refetch } = useGetDlcsQuery(params, {
    skip: !params,
    selectFromResult: (result) => ({
      ...result,
      dlcs: filterIt(result),
    }),
  })
  console.log(dlcs)

  if (isFetching) return <Fetching />
  if (!dlcs) return <EmptyDatas />

  return (
    <div>
      <div>
        <Button className="me-3" variant="outline-primary" onClick={refetch}>
          refetch
        </Button>
      </div>
      <table className="table table-hover">
        <Thead
          templates={templates}
          setTemplate={setTemplate}
          setTag={setTag}
        />
        <tbody>
          {dlcs?.map((dlc, index) => (
            <tr key={dlc.id}>
              <Item index={index} dlc={dlc} />
              <td>
                <Link
                  className="me-1 btn btn-outline-primary"
                  to={`/editor/` + dlc.tid}
                >
                  Editor
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

const Thead = ({ templates, setTemplate, setTag }) => {
  const tags = useSelector((state) => state.tagLibs) ?? {}
  return (
    <thead className="table-secondary">
      <tr>
        <th scope="col">#</th>
        <th scope="col">Preview</th>
        <th scope="col">ID</th>
        <th scope="col">
          <Select datas={templates} onChange={(e) => setTemplate(e)} />
        </th>
        <th scope="col">Version</th>
        <th scope="col">State</th>
        <th scope="col">Sku</th>
        <th scope="col">
          <Select
            datas={Object.keys(tags).map((id) => {
              return { code: tags[id].name, name: tags[id].name }
            })}
            onChange={(e) => setTag(e)}
          />
        </th>
        <th scope="col">Avatars</th>
        <th scope="col">Options</th>
      </tr>
    </thead>
  )
}

const Item = ({ dlc, index }) => {
  return (
    <>
      <td>{index + 1}</td>
      <td>{dlc.cover && <img width={96} src={dlc.cover} alt="..." />}</td>
      <td>
        <div className="fw-bold">{dlc.name}</div>
        <CopyedCode copyed={dlc.id}>{dlc.id}</CopyedCode>
      </td>
      <td>
        <CopyedCode copyed={dlc.tid}>{dlc.tid}</CopyedCode>
      </td>
      <td>
        <CopyedCode copyed={dlc.version}>{dlc.version}</CopyedCode>
      </td>
      <td>
        <div className="fw-bold">{dlc.state}</div>
      </td>
      <td>
        <div className="fw-bold">{dlc.sku}</div>
      </td>
      <td>
        <RenderTags dlc={dlc} />
      </td>
      <td>
        <Link to={'/avatars?tid=' + dlc.tid}>avatars:{dlc.tid}</Link>
      </td>
    </>
  )
}

const RenderTags = ({ dlc }) => {
  return (
    <>
      <Confirm confirmText="Done" body={<TagsBody dlc={dlc} />}>
        <Badge className="me-1" bg="success">
          + -
        </Badge>
      </Confirm>
      {dlc?.tags?.map((tag) => (
        <Badge className="me-1" key={tag}>
          <small>{tag}</small>
        </Badge>
      ))}
    </>
  )
}

const TagsBody = ({ dlc }) => {
  const tags = useSelector((state) => state.tagLibs)
  const [update] = useUpdateDlcMutation()

  const handleRemove = (tag) =>
    update({ id: dlc.id, body: { key: 'removeTag', value: tag } })

  const handleAdd = (tag) =>
    update({ id: dlc.id, body: { key: 'addTag', value: tag.name } })

  return (
    <div>
      {dlc?.tags?.map((tag) => (
        <Badge
          bg="primary"
          className="me-2 mt-2"
          key={tag}
          onClick={() => handleRemove(tag)}
        >
          {tag}
        </Badge>
      ))}
      <hr />
      {Object.keys(tags)?.map(
        (id) =>
          !dlc?.tags?.includes(tags[id].name) && (
            <Badge
              bg="secondary"
              className="me-2 mt-2"
              key={id}
              onClick={() => handleAdd(tags[id])}
            >
              {tags[id].name}-{tags[id].note}
            </Badge>
          ),
      )}
    </div>
  )
}

export default Dlc
