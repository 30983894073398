import React, { useState, useEffect, useRef } from "react";
import useImage from "use-image";
import { Stage, Layer, Image } from "react-konva";
import emitter, { tonings, drawers } from "canvasMitt";
import { useSize } from "ahooks";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTemplate } from "./hooks";

function CanvasCore({ stageRef }) {
  const [sortDrawers, setSortDrawers] = useState();
  const { tid } = useParams();
  const { data: template } = useTemplate();
  const drawersData = useSelector((state) => state.ui.editor[tid].drawers);
  const toningsData = useSelector((state) => state.ui.editor[tid].tonings);
  const ref = useRef(null);
  const size = useSize(ref);
  const canvasWidth = 512 * ((template?.ow/template?.oh) || 1);
  const canvasHeight = 512;
  console.log(canvasHeight,canvasWidth)

  const handleRender = () => {
    const _drawers = [...drawers];
    const ret = _drawers.sort((a, b) => a.z - b.z);
    setSortDrawers(ret);
  };
  useEffect(() => {
    emitter.emit(`setTonings`, toningsData);
    emitter.emit(`setDrawers`, drawersData);
  },[])
  emitter.on(`renderCpt`, handleRender);
  useEffect(() => {
    return () => emitter.off(`renderCpt`, handleRender);
  }, []);

  return (
    <div ref={ref} className="canvas-box">
      <Stage width={canvasWidth} height={canvasHeight} ref={stageRef}>
        <Layer>
          {sortDrawers?.map((drawer) => (
            <Drawer
              key={drawer.cid}
              drawer={drawer}
              canvasWidth={canvasWidth}
              canvasHeight={canvasHeight}
            />
          ))}
        </Layer>
      </Stage>
    </div>
  );
}

const GRADIENT_LEVELS = 256;
const _getGradient = (t) => {
  const levels = GRADIENT_LEVELS;
  const canvas = document.createElement("canvas");
  canvas.width = 1;
  canvas.height = levels;
  const ctx = canvas.getContext("2d");
  const gradient = ctx.createLinearGradient(0, 0, 0, levels);
  const len = t.length;
  for (let i = 0; i < len; ++i) {
    gradient.addColorStop(t[i].o, t[i].c);
  }
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, 1, levels);
  const _gradientPixels = ctx.getImageData(0, 0, 1, levels).data;
  return _gradientPixels;
};

const Drawer = ({ drawer, canvasWidth, canvasHeight }) => {
  const { cid, t } = drawer;
  const x = canvasWidth === 512 ? drawer.x : (canvasWidth / 512) * drawer.x;
  const y = canvasHeight === 512 ? drawer.y : (canvasHeight / 512) * drawer.y;
  const w = canvasWidth === 512 ? drawer.w : (canvasWidth / 512) * drawer.w;
  const h = canvasHeight === 512 ? drawer.h : (canvasHeight / 512) * drawer.h;
  const [image] = useImage(`https://img.zthd.io/an1/ats/${cid}`, "Anonymous");
  const imageRef = React.useRef();

  const filter = (imageData) => {
    const toning = tonings[t]?.default;
    if (!toning || !toning.toning) return;

    const gradient = _getGradient(toning.toning);
    if (!gradient) return;
    const grayscaled_imagedata = getGrayscaledImageData(imageData);
    getGradientMappedImageData(gradient, grayscaled_imagedata);
  };

  const handleUpdate = (e) => {
    if (!e) return;
    if (e !== t) return;
    if (!imageRef?.current) return;

    imageRef.current.filters([filter]);
  };

  if (t) {
    emitter.on(`render_${t}`, handleUpdate);
  }

  useEffect(() => {
    return () => emitter.off(`render_${t}`, handleUpdate);
  }, []);

  useEffect(() => {
    if (image) {
      // you many need to reapply cache on some props changes like shadow, stroke, etc.
      imageRef.current.cache();
    }
  }, [image]);

  return (
    <Image
      x={x}
      y={y}
      width={w}
      height={h}
      image={image}
      ref={imageRef}
      filters={[filter]}
    />
  );
};

/**
 * Composite imageData(pixeldata) with gradient
 * @param {imageData} imagedata pixel datas to composite with gradient
 * @returns imageData
 */
function getGradientMappedImageData(gradient, imagedata) {
  for (let i = 0; i < imagedata.data.length; i += 4) {
    let v =
      (Math.max(
        imagedata.data[i],
        imagedata.data[i + 1],
        imagedata.data[i + 2]
      ) +
        Math.min(
          imagedata.data[i],
          imagedata.data[i + 1],
          imagedata.data[i + 2]
        )) /
      2;
    // let new_r = Math.floor(gradient[v * 4] * 1.025);
    // let new_g = Math.floor(gradient[v * 4 + 1] * 1.035);
    // let new_b = Math.floor(gradient[v * 4 + 2] * 1.035);

    let new_r = Math.floor(gradient[v * 4] * 1);
    let new_g = Math.floor(gradient[v * 4 + 1] * 1);
    let new_b = Math.floor(gradient[v * 4 + 2] * 1);

    imagedata.data[i] = new_r;
    imagedata.data[i + 1] = new_g;
    imagedata.data[i + 2] = new_b;
  }

  return imagedata;
}

function getGrayscaledImageData(imagedata) {
  // NOTE: imageDataのピクセルは[R_1,G_1,B_1,A_1,R_2,...]という形の一次元配列
  // なので、for文のindex変数は毎回+4(Alphaを無視)している
  for (let i = 0; i < imagedata.data.length; i += 4) {
    let r = imagedata.data[i];
    let g = imagedata.data[i + 1];
    let b = imagedata.data[i + 2];
    // let grayscale = r * 0.299 + g * 0.587 + b * 0.114;
    // let grayscale = r * 0.2126 + g * 0.7152 + b * 0.0722;
    let grayscale = r * 0.34 + g * 0.5 + b * 0.16;
    imagedata.data[i] = grayscale;
    imagedata.data[i + 1] = grayscale;
    imagedata.data[i + 2] = grayscale;
  }

  return imagedata;
}

export default CanvasCore;
