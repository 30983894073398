// import { useGetUserQuery } from "api/shield/users";
import React from "react";
import User from "./User";
import Templates from "./Templates";
import PersonalNav from "./Nav";

const Personal = () => {
  return (
    <>
      <PersonalNav />
      <div
        className="col-8 px-0 bg-white m-auto"
        style={{ minHeight: "100vh", maxWidth: "1200px", paddingTop: 70 }}
      >
        <User />
        <hr style={{ margin: "30px 0", opacity: "0.1" }} />
        {/* <div>rules </div> */}
        <Templates />
      </div>
    </>
  );
};

export default Personal;
