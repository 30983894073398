import React from "react";

const Select = (props) => {
  const { datas, value } = props;
  return (
    <div className="w80">
      {props.children && <label className="form-label">{props.children}</label>}
      <select
        className="form-select"
        value={value}
        onChange={(e) => props.onChange(e.target.value)}
      >
        <option value={0}>Choose...</option>
        {datas?.map(({ code, name }) => (
          <option key={code} value={code}>
            {name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default Select;
