import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetFrameDailyQuery, useGetDailyCountQuery } from 'api/neku/frames'
import LinePanel from 'ui/viz/LinePanel'
import { Link } from 'react-router-dom'
import logoImg from 'assets/logo.png'
import dayjs from 'dayjs'
import { useLayer } from 'screens/Editor/hooks'
import { useCustomParams } from 'screens/Editor/hooks'
import { useGetTemplateQuery } from 'api/neku/templates'
import { useGetLayersQuery } from 'api/neku/layers'
import { useGetComponentsQuery } from 'api/neku/components'
import CopyedCode from 'ui/CopyedCode'

const useXY = (data, xName, yName) => {
  if (!data) {
    return []
  }
  const results = data.map((item) => {
    return { x: item[xName], y: item[yName] }
  })
  return results.slice(0, 9)
}

const Index = () => {
  const { tid } = useParams()
  const { data, isFetching } = useGetFrameDailyQuery({ tid })
  const { data: frame } = useGetTemplateQuery(tid)
  const datasForNivo = useXY(data?.reCreateCount, 'date', 'reCreateCount')
  const datasForNivo2 = useXY(data?.avatarCount, 'date', 'avatars')
  const datasForNivo3 = useXY(data?.avatarCount, 'date', 'reCreateAvatars')
  const datasForNivo4 = useXY(data?.avatarCount, 'date', 'originateAvatars')
  return (
    <>
      <Nav />
      <div className="d-flex p-4">
        <FrameDetail frame={frame} />
        <div className='d-flex flex-wrap'>
        <LinePanel
          id={'reCreateCount'}
          loading={isFetching}
          datas={datasForNivo}
        />
        <LinePanel
          id={'avatarCount'}
          loading={isFetching}
          datas={datasForNivo2}
        />
        <LinePanel
          id={'originateAvatars'}
          loading={isFetching}
          datas={datasForNivo4}
        />
        <LinePanel
          id={'reCreateAvatars'}
          loading={isFetching}
          datas={datasForNivo3}
        />
        </div>
      </div>
      <div className="p-4">
        <FrameDetailCount tid={tid} />
      </div>
    </>
  )
}

const FrameDetail = ({ frame }) => {
  if (!frame) return
  return (
    <div className="mx-4">
      <div className="position-relative" style={{ width: 300, height: 300 }}>
        <img src={frame?.cover} width={300} alt="cover" className="" />
        <img
          src={frame?.preview}
          width={100}
          alt="preview"
          className="position-absolute bottom-0 end-0"
        />
      </div>
      <div className="d-flex flex-column mt-2">
        <CopyedCode copyed={frame?.id}>{frame?.id}</CopyedCode>
        <span className="mt-2">{frame?.name}</span>
      </div>
    </div>
  )
}

const Nav = () => {
  return (
    <nav className="navbar bg-white nav border-bottom">
      <div className="container-fluid">
        <Link className="navbar-brand" to="/personal">
          <img
            src={logoImg}
            alt="Logo"
            width="48"
            height="48"
            className="d-inline-block align-text-center me-1"
          />
          <span className="logo_text me-1">Neku Daily</span>
        </Link>
      </div>
    </nav>
  )
}

const FrameDetailCount = ({ tid }) => {
  const [date, setDate] = useState(
    dayjs().subtract(2, 'day').format('YYYY-MM-DD'),
  )
  const { data } = useGetDailyCountQuery({
    tid,
    params: { date: date.replace(/-/g, '') },
  })

  if (!tid || !data) return
  return (
    <>
      <div>
        <input
          className="p-2 mb-2"
          type="date"
          id="date"
          name="date"
          value={date}
          onChange={(e) => {
            setDate(e.target.value)
          }}
        />
      </div>
      <TemplateData data={data?.templates} />
      <LayerData data={data?.layers} />
      <CptData data={data?.cpts} />
    </>
  )
}

const TemplateData = ({ data }) => {
  if (!data) return
  return (
    <table className="table table-hover">
      <thead className="table-secondary">
        <tr>
          <th scope="col">#</th>
          <th scope="col">tid</th>
          <th scope="col">date</th>
          <th scope="col">originateAvatars</th>
          <th scope="col">reCreateAvatars</th>
          <th scope="col">avatars</th>
          <th scope="col">proAvatars</th>
          <th scope="col">dlcAvatars</th>
          <th scope="col">payout</th>
          <th scope="col">cpts</th>
          <th scope="col">layers</th>
          <th scope="col">pro rate</th>
          <th scope="col">dlc rate</th>
          <th scope="col">avg pay</th>
          <th scope="col">avg cpts</th>
          <th scope="col">avg layers</th>
          <th scope="col">avg avatars</th>
        </tr>
      </thead>
      <tbody>
        {data?.map((frame, index) => (
          <tr key={frame.id}>
            <td>{index + 1}</td>
            <td>
              <CopyedCode copyed={frame.tid}>{frame.tid}</CopyedCode>
            </td>
            <td>{frame.date}</td>
            <td>{frame.avatars - frame.reCreateAvatars}</td>
            <td>{frame.reCreateAvatars}</td>
            <td>{frame.avatars}</td>
            <td>{frame.proAvatars}</td>
            <td>{frame.dlcAvatars}</td>
            <td>{frame.payout}</td>
            <td>{frame.cpts}</td>
            <td>{frame.layers}</td>
            <td>
              {Math.round((frame.proAvatars / frame.avatars) * 10000) / 100} %
            </td>
            <td>
              {Math.round((frame.dlcAvatars / frame.avatars) * 10000) / 100} %
            </td>
            <td>{Math.round(frame.payout / frame.avatars)}</td>
            <td>{Math.round(frame.cpts / frame.avatars)}</td>
            <td>{Math.round(frame.layers / frame.avatars)}</td>
            <td>{Math.round(frame.payout / frame.proAvatars)}</td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

const LayerData = ({ data }) => {
  const params = useCustomParams()
  const { data: layers } = useGetLayersQuery(params, { skip: !params })
  if (!data) return
  return (
    <>
      <table className="table table-hover">
        <thead className="table-secondary">
          <tr>
            <th scope="col">#</th>
            <th scope="col">cover</th>
            <th scope="col">date</th>
            <th scope="col">tid</th>
            <th scope="col">lid</th>
            <th scope="col">name</th>
            <th scope="col">count</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((frame, index) => (
            <tr key={frame.id}>
              <td>{index + 1}</td>
              <td>
                <LayerCover frame={frame} />
              </td>
              <td>{frame.date}</td>
              <td>
                <CopyedCode copyed={frame.tid}>{frame.tid}</CopyedCode>
              </td>
              <td>
                <CopyedCode copyed={frame.lid}>{frame.lid}</CopyedCode>
              </td>
              <td>
                <LayerName frame={frame} />
              </td>
              <td>{frame.count}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

const LayerName = ({ frame }) => {
  const { layer } = useLayer(frame.lid)
  return <>{layer?.name}</>
}

const LayerCover = ({ frame }) => {
  const { layer } = useLayer(frame.lid)
  return (
    <>
      <img
        src={`https://img.zthd.io/an1/acs/${layer?.cover}`}
        alt={layer?.name}
        width={100}
      />
    </>
  )
}

const CptData = ({ data }) => {
  const params = useCustomParams()
  const { data: cpts } = useGetComponentsQuery(params, { skip: !params })
  if (!data) return
  return (
    <>
      <div>
        <table className="table table-hover">
          <thead className="table-secondary">
            <tr>
              <th scope="col">#</th>
              <th scope="col">cover</th>
              <th scope="col">date</th>
              <th scope="col">tid</th>
              <th scope="col">cid</th>
              <th scope="col">count</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((frame, index) => (
              <tr key={frame.id}>
                <td>{index + 1}</td>
                <td>
                  <CptCover frame={frame} />
                </td>
                <td>{frame.date}</td>
                <td>
                  <CopyedCode copyed={frame.tid}>{frame.tid}</CopyedCode>
                </td>
                <td>
                  <CopyedCode copyed={frame.cid}>{frame.cid}</CopyedCode>
                </td>
                <td>{frame.count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  )
}

const CptCover = ({ frame }) => {
  return (
    <>
      <div style={{ width: 100, height: 100 }}>
        <img
          src={`https://img.zthd.io/an1/ats/${frame.cid}`}
          alt={frame.cid}
          style={{ maxHeight: 100, maxWidth: 100 }}
        />
      </div>
    </>
  )
}

export default Index
