import React from "react";
import * as dayjs from "dayjs";
import * as updateLocale from "dayjs/plugin/updateLocale";
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  relativeTime: {
    future: "%s",
    past: "%s ago",
    s: "<1s",
    m: "<1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1M",
    MM: "%dM",
    y: "1Y",
    yy: "%dY",
  },
});

const useTime = (updateTime) => {
  const showTime = updateTime ? dayjs(updateTime).format("MM-DD HH:mm") : "";
  const ago = updateTime ? dayjs(updateTime).toNow() : "";
  return [showTime, ago];
};

const DisplayUpdateTime = ({ updateTime }) => {
  const [showTime, ago] = useTime(updateTime);
  if (!showTime) {
    return <></>;
  }
  return (
    <div>
      {showTime} ({ago})
    </div>
  );
};

export default DisplayUpdateTime;
