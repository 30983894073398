import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, useField } from "formik";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useLoginMutation } from "api/neku";
import logoImg from "assets/logo.png";
import { Eye, EyeSlash } from "react-bootstrap-icons";
import styles from "./Login.module.scss";
import * as Yup from "yup";
import classNames from "classnames";

const Login = () => {
  const [, { isLoading }] = useLoginMutation();
  const isLoggedIn = useSelector((state) => state.auth.user);
  const [isHidePassword, setIsHidePassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    username: Yup.string().email().required(),
    password: Yup.string().min(
      8,
      "Password is too short - should be 8 chars minimum."
    ),
    // .minLowercase(1, "password must contain at least 1 lower case letter")
    // .minUppercase(1, "password must contain at least 1 upper case letter")
    // .minNumbers(1, "password must contain at least 1 number"),
  });

  const handleSubmit = async (values) => {
    console.log(values);
    // login(values)
    //   .unwrap()
    //   .then((res) => {
    //     console.log(res);
    //     navigate(from, { replace: true });
    //   });
  };

  if (isLoggedIn) {
    return <Navigate to="/" />;
  }

  return (
    <div className="col-12" style={{ height: "100vh", background: "#FCFBFF" }}>
      <div className={styles.login}>
        <div className="w-100 d-flex justify-content-center">
          <img src={logoImg} alt="" style={{ width: 68, height: 68 }} />
        </div>
        <div className={styles.title}>
          <span>NeKu for designer</span>
        </div>
        <Formik
          initialValues={{ username: "", password: "" }}
          validationSchema={LoginSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          <Form>
            <MyTextField name="username" placeholder="Email" />
            <div className="row">
              <Field
                type="email"
                className={`form-control ${styles.input}`}
                name="username"
                placeholder="Email"
              />
              <div style={{ color: "#FF4973", margin: "15px 0" }}>
                <ErrorMessage name="username" component="div" />
              </div>
            </div>
            <div className="row position-relative">
              <Field
                type={isHidePassword ? "text" : "password"}
                className={`form-control ${styles.input}`}
                name="password"
                autoComplete="on"
                placeholder="Password"
              />
              <div onClick={() => setIsHidePassword(!isHidePassword)}>
                {isHidePassword ? (
                  <Eye className={styles.EyeSlash} style={{ color: "#000" }} />
                ) : (
                  <EyeSlash className={styles.EyeSlash} />
                )}
              </div>
              <div style={{ color: "#FF4974", margin: "15px 0" }}>
                <ErrorMessage name="password" component="div" />
              </div>
            </div>
            <div className="row">
              <button
                type="submit"
                className={styles.submit}
                disabled={isLoading}
              >
                {isLoading && (
                  <Spinner animation="border" size="sm" className="me-2" />
                )}
                Log in
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

const MyTextField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  const style = classNames(
    styles.input,
    "form-control",
    {
      "is-invalid": meta.touched && meta.error,
    },
    { "is-valid": meta.touched && !meta.error }
  );
  return (
    <div className={styles.formField}>
      <div className={styles.label + " " + styles.fieldRequire}>{label}</div>
      <input type="input" className={style} {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="invalid-feedback">{meta.error}</div>
      ) : null}
      {/* {meta.touched && !meta.error ? (
        <div className="valid-feedback">Looks good!</div>
      ) : null} */}
    </div>
  );
};

export default Login;
