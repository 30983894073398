import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import TabsSwitch from 'components/TabsSwitch'
import Layers from './Layers'
import Tonings from './Tonings'
import DLCs from './DLCs'
import BatchNos from './BatchNos'

import { setCurrTab } from '../uiSlice'
import { useGetLayersQuery, useGetLayerGroupsQuery } from 'api/neku/layers'
import { useGetToningsQuery } from 'api/neku/tonings'

import { useRole } from 'hooks/auth'
import { useCustomParams, useFetchDlcs } from '../hooks'
import { useGetComponentsQuery } from 'api/neku/components'

import LayersMenus from './Layers/Menus'
import { ToningsActions } from './Tonings'
import { DlcsActions } from './DLCs'
import BatchNosMenus from './BatchNos/Menus'

import './index.scss'
import { useParams } from 'react-router-dom'

const useTabDatas = (layers, tonings, dlcs) => {
  const isAdmin = useRole(`admin`)
  if (isAdmin) {
    return [
      { name: `Layers(${layers})`, to: 'layers' },
      { name: `Tonings(${tonings})`, to: 'tonings' },
      { name: `DLCs(${dlcs})`, to: 'dlcs' },
      { name: `BatchNo`, to: 'batchnos' },
    ]
  }
  return [
    { name: `Layers(${layers})`, to: 'layers' },
    { name: `Tonings(${tonings})`, to: 'tonings' },
    { name: `DLCs(${dlcs})`, to: 'dlcs' },
  ]
}

const RightPanel = () => {
  const isView = useRole(`viewer`)
  const { tid } = useParams()
  const dispatch = useDispatch()
  const params = useCustomParams()
  const currTab = useSelector(
    (state) => state.ui.editor[tid].currTab ?? 'layers',
  )
  const { data: layers } = useGetLayersQuery(params, { skip: !params })
  const { data: tonings } = useGetToningsQuery(params, { skip: !params })
  const { data: layerGroups } = useGetLayerGroupsQuery(params, {
    skip: !params,
  })

  const { data: dlcs } = useFetchDlcs()
  useGetComponentsQuery(params, { skip: !params })

  const datas = useTabDatas(
    layers?.length ?? 0,
    tonings?.length ?? 0,
    dlcs?.length ?? 0,
  )

  const onSwitch = (v) => {
    dispatch(setCurrTab({ currTab: v, tid }))
  }
  if (isView) return
  return (
    <>
      <div className="right-panel-tab d-flex align-items-center justify-content-between flex-lg-row-reverse border-bottom z-1 flex-md-column">
        <div className="right-panel-actions">
          {currTab === 'layers' && <LayersMenus />}
          {currTab === 'tonings' && <ToningsActions />}
          {currTab === 'dlcs' && <DlcsActions />}
          {currTab === 'batchnos' && <BatchNosMenus />}
        </div>
        <TabsSwitch datas={datas} defaultValue={currTab} onSwitch={onSwitch} />
      </div>
      <div
        className="right-panel-contant h-100 overflow-auto"
        id="rightContant"
      >
        {currTab === 'layers' && <Layers />}
        {currTab === 'tonings' && <Tonings />}
        {currTab === 'dlcs' && <DLCs />}
        {currTab === 'batchnos' && <BatchNos />}
      </div>
    </>
  )
}

export default RightPanel
