import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import styles from "./index.module.scss";
import { useGetTemplatesQuery } from "api/neku/templates";
import {
  PlusCircleFill,
  PencilFill,
  Trash3,
  ThreeDotsVertical,
} from "react-bootstrap-icons";

const Templates = () => {
  const { data } = useGetTemplatesQuery(null, {
    refetchOnMountOrArgChange: true,
  });
  const itemArr = [1, 2, 3, 4, 5];
  const location = useLocation();

  return (
    <div style={{ padding: "0 40px" }}>
      <h2 className={styles.title}>My Avatars</h2>
      <div className="flex-center flex-wrap justify-content-between">
        <div className={styles.cardWrap}>
          <Link
            state={{ backgroundLocation: location }}
            to={`/create`}
            className={`text-dark border h-100 ${styles.card}`}
          >
            <div className="d-flex w-100 flex-column" style={{ color: "#999" }}>
              <PlusCircleFill className={styles.addCard} />
              <div className="text-decoration-none">Create New</div>
            </div>
          </Link>
        </div>
        {data?.map((template) => (
          <Template template={template} key={template.id} />
        ))}
        {itemArr?.map((item) => (
          <div key={item} style={{ width: "200px", height: "0" }} />
        ))}
      </div>
    </div>
  );
};

const Template = ({ template }) => {
  const defaultImg =
    "https://static.wixstatic.com/media/e7b9f3_19bd0c4b32264ab69c9afabbe0d3b217~mv2.png/v1/fill/w_536,h_614,al_c,lg_1,q_85,usm_1.20_1.00_0.01,enc_auto/person2-512.png";
  const [imgSrc, handleImageErrored] = useState(template?.cover || defaultImg);
  return (
    <div className={`${styles.cardWrap} position-relative`}>
      <div className={`h-100 border ${styles.editCard}`}>
        {template?.publishTime ? (
          <span className={styles.draft}>Online</span>
        ) : (
          <span className={styles.draft}>Draft</span>
        )}
        <img
          src={imgSrc}
          onError={() => handleImageErrored(defaultImg)}
          style={{ width: 200, height: 200 }}
          alt="..."
        />
        <div className="flex-center justify-content-between py-2">
          <div className={styles.editTit}>{template?.name || "Test only"}</div>
          <Dropdown>
            <Dropdown.Toggle className={`${styles.threeDot} cursor-pointer`}>
              <ThreeDotsVertical style={{ width: 20, height: 20 }} />
            </Dropdown.Toggle>
            <Dropdown.Menu className="p-0">
              <Dropdown.Item className={`${styles.delete} py-2`}>
                <Trash3 style={{ marginRight: "10px" }} />
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          {/* <pre className="card-text text_wrap_pre_line">{template.intro}</pre> */}
        </div>
        <div className={styles.editOpt} />
        <Link
          to={"/editor/" + template.id}
          className={`text-dark flex-center justify-content-center ${styles.footer}`}
        >
          <PencilFill className="me-2" />
          <span className={styles.edit}>Edit</span>
        </Link>
      </div>
    </div>
  );
};

export default Templates;
