import React from "react";
import "./index.scss";

const Tabs = ({ datas, defaultValue, onSwitch }) => {
  const handleClick = (value) => {
    onSwitch && onSwitch(value);
  };
  return (
    <>
      <ul className="nav nav-pills  flex-nowrap">
        {datas.map(({ name, to }) => (
          <li
            key={to}
            className={`nav-item layer-tab-item cursor-pointer pe-xxl-4 pe-xl-3`}
            onClick={() => handleClick(to)}
          >
            <span className={`${defaultValue === to ? `active` : ""}`}>
              {name}
            </span>
            <div className="w-100 mt-1">
              {defaultValue === to && <div className="line-active" />}
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};

export default Tabs;
