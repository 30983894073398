import React from "react";
import { useParams } from "react-router-dom";
import { useUpdateLayerMutation } from "api/neku/layers";
import { useTonings } from "screens/Editor/hooks";
import "./index.scss";

const UpdateToning = ({ layer }) => {
  const { tid } = useParams();
  const { data: tonings } = useTonings();
  const [update] = useUpdateLayerMutation();

  const onChange = (value) =>
    update({
      id: layer.id,
      body: { tid, key: "toning", value },
    });

  if (!tonings) {
    return <>please load tonings...</>;
  }

  return (
    <select
      className={`rounded-3 me-1 py-2 select`}
      value={layer.toning}
      onChange={(e) => onChange(e.target.value)}
    >
      <option value="">Bind Toning</option>
      {tonings.map(({ id, name }) => (
        <option key={id} value={id}>
          {name}
        </option>
      ))}
    </select>
  );
};

export default UpdateToning;
