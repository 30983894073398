import React from "react";
import { Card } from "react-bootstrap";

export const CardWrap = ({ children, className = "" }) => {
  return (
    <div className={"col " + className}>
      <Card className="text-center h-100 border-0">{children}</Card>
    </div>
  );
};

export default CardWrap;
